<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" v-if="isAdmin" @change="agentChange" v-model="query.agentId" filterable
					clearable placeholder="代理商" class="handle-select mr10" style="width: 240px">
					<el-option v-for="item in agents" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-if="isNormal || isAgent" v-model="query.companyId" @change="companyChange"
					filterable clearable placeholder="企业" class="handle-select mr10" style="width: 240px">
					<el-option v-for="item in companys" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-if="isNormal || isAgent" v-model="query.packageMinutes"
					@change="packageChange" filterable clearable placeholder="号码套餐信息" class="handle-select mr10"
					style="width: 240px">
					<el-option v-for="item in packageList" :key="item" :label="item+'分钟'" :value="item"></el-option>
				</el-select>
				<el-input size="small" v-model="query.phone" clearable placeholder="请输入手机号码" class="handle-input mr10"
					style="width: 180px"></el-input>
				<el-button size="mini" type="primary" :class="[theme]" icon="el-icon-search"
					@click="handleSearch">搜索</el-button>
			</div>
			<el-tabs v-model="activeName" type="card" @tab-click="handleClick">
				<el-tab-pane :label="'全部'+'  '+'('+allVul+')'" name="all"></el-tab-pane>
				<el-tab-pane :label="'正常'+'  '+'('+susVul+')'" name="sus"></el-tab-pane>
				<el-tab-pane :label="'未购买套餐'+'  '+'('+noPackageVul+')'" name="noPackage"></el-tab-pane>
				<el-tab-pane :label="'未实名'+'  '+'('+noAuthVul+')'" name="noAuth"></el-tab-pane>
				<el-tab-pane :label="'已停用'+'  '+'('+stopVul+')'" name="stop" style="position: relative;"></el-tab-pane>
			</el-tabs>
			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				@selection-change="handleSelectionChange" :header-cell-style="{ 'text-align': 'center' }"
				:row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }" :row-class-name="tableRowClassName">
				<el-table-column type="selection" width="55" align="center"></el-table-column>
				<el-table-column prop="agentName" label="代理" align="center"
					v-if="isAdmin&&!query.agentId"></el-table-column>
				<el-table-column prop="companyName" label="企业" align="center"
					v-if="(isAdmin||isAgent)&&!query.companyId"></el-table-column>
				<el-table-column prop="userPhone" label="账号" align="center"></el-table-column>
				<el-table-column prop="packageName" label="普通包" align="center"></el-table-column>
				<el-table-column prop="outside" label="加量包" align="center">
					<template #default="scope">
						<el-tag v-for="item in scope.row.outside"
							style="display: flex;flex-direction: column;margin:6px 0;">{{item}}</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="totalMinutes" label="总时长" align="center" width="100px">
					<template #default="scope">
						<span>{{scope.row.totalMinutes||0}}分钟</span>
					</template>
				</el-table-column>
				<el-table-column prop="useMinutes" label="使用时长" align="center" width="100px">
					<template #default="scope">
						<span>{{scope.row.useMinutes||0}}分钟</span>
					</template>
				</el-table-column>
				<el-table-column prop="faceInfo" label="人脸信息" align="center" width="100px">
					<template #default="scope">
						<span v-if="scope.row.faceInfo==true" style="color: green;cursor:pointer"
							@click="bigImg(scope.row.id)">已录入</span>
						<el-button size="small" type="text" v-if="scope.row.faceInfo==false"
							@click="upFaceInfo(scope.row)">上传</el-button>
					</template>
				</el-table-column>
				<el-table-column prop="faceAuthentication" label="人脸认证" align="center" width="100px">
					<template #default="scope">
						<span v-if="scope.row.faceAuthentication==true" style="color: green;">已实名</span>
						<span v-if="scope.row.faceAuthentication==false" class="red">未实名</span>
					</template>
				</el-table-column>
				<el-table-column prop="expirationTime" label="过期时间" align="center"></el-table-column>
				<el-table-column label="操作" width="190" align="center">
					<template #default="scope">
						<el-button size="small" type="text" @click="plan(scope.row)">划拨套餐</el-button>
						<el-button size="small" type="text" icon="el-icon-delete" v-if="scope.row.faceInfo==true"
							style="color: #f56c6c;" @click="handleDelete(scope.$index, scope.row.id)">清除人脸</el-button>
						<el-button size="small" type="text" v-if="scope.row.faceAuthentication==true"
							@click="handleModifyFaceState(scope.$index, scope.row.id)">修改状态</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[30, 50, 100, 500]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>
			<el-dialog title="分配套餐" v-model="planVisible" width="550px" @close="closeDialog">
				<el-form label-width="120px" :model="planForm" ref="planForm">
					<el-form-item prop="packageName" label="行业">
						<el-radio-group v-model="planForm.type">
							<el-radio v-for="item in typeList" :key="item.value" :label="item.value" :value="item.value"
								style="margin: 12px 6px;">{{item.label}}</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="普通包" v-if="planForm.type=='content'">
						<el-radio-group v-model="planForm.minutes">
							<el-radio v-for="item in contentList" :key="item.value" :label="item.minutes"
								:value="item.minutes"
								style="margin: 12px 6px;">{{item.price + '元'+ item.minutes + '分钟'}}</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="加量包" v-if="planForm.type=='outside'">
						<el-radio-group v-model="planForm.minutes">
							<el-radio v-for="item in outsideList" :key="item.value" :label="item.minutes"
								:value="item.minutes"
								style="margin: 12px 6px;">{{item.price + '元'+ item.minutes + '分钟'}}</el-radio>

						</el-radio-group>
					</el-form-item>
					<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
						<el-button size="small" @click="closeDialog">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]"
							@click="getTransferSipSetMeal">确定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
			<el-dialog title="人脸信息" v-model="upFaceInfoVisible" width="550px" @close="closeDialog2">
				<el-form label-width="120px" :model="upFaceInfoForm" ref="upFaceInfoForm">
					<el-form-item prop="packageName" label="图片">
						<el-upload action="/api/faceAuthentication/uploadFace" :limit="1" list-type="picture-card"
							:file-list="fileList" :auto-upload="false" ref="upload" :on-success="uploadSuccess"
							accept="image/png,image/jpg,image/jpeg" :data="uploadData" :class="{ hide: hideUpload }"
							:before-remove="beformRemove" :on-change="getfile" :on-preview="handlePictureCardPreview"
							:on-progress="handleProgress" :http-request="uploadFile">
							<el-button size="small" type="primary" :class="[theme]">点击上传</el-button>
						</el-upload>
						<el-dialog v-model="uploadImgBig"><img :src="dialogImageUrl" style="width: 100%;" /></el-dialog>
					</el-form-item>
					<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
						<el-button size="small" @click="closeDialog2">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]" @click="getUploadFace">确定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
			<el-dialog v-model="imgVisible" width="600px">
				<el-image :src="srcimgurl" fit="contain"></el-image>
			</el-dialog>
			<el-dialog v-model="deleteVisible" width="450px" @close="closeDialog3">
				<el-form label-width="120px" :model="deleteForm" ref="upFaceInfoForm">
					<el-form-item prop="packageName" label="原因">
						<el-input type="textarea" v-model="deleteForm.remark" placeholder="请输入清除人脸识别的原因"></el-input>
					</el-form-item>
					<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
						<el-button size="small" @click="closeDialog3">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]" @click="getDelete">确定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
			<el-dialog v-model="ModifyFaceStateVisible" width="450px" @close="closeDialog3">
				<el-form label-width="120px" :model="ModifyFaceStateForm" ref="upFaceInfoForm">
					<el-form-item prop="packageName" label="原因">
						<el-input type="textarea" v-model="ModifyFaceStateForm.remark"
							placeholder="请输入修改人脸识别的原因"></el-input>
					</el-form-item>
					<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
						<el-button size="small" @click="closeDialog3">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]"
							@click="getModifyFaceState">确定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import { fetchDepartment } from '../api/index.js';
	import { fetchAgent, fetchInitAgent } from '../api/agentIndex.js';
	import { fetchCompanyByAgentId, fetchDefaultCompanies } from '../api/companyIndex';
	import {
		accountSetMeal,
		accountAssignable,
		transferSipSetMeal,
		uploadFace,
		lookTfSipFace,
		clearFaceInfo,
		ModifyFaceState,
		queryPackageList
	} from '../api/setMeal.js';
	export default {
		name: 'specialServiceNumber',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					companyId: '',
					agentId: '',
					phone: '',
					packageMinutes: '',
					type: 'all',
					pageIndex: 1,
					pageSize: 30,
				},
				activeName: 'all',
				allVul: 0,
				susVul: 0,
				noPackageVul: 0,
				noAuthVul: 0,
				stopVul: 0,
				agents: [],
				companys: [],
				tableData: [],
				multipleSelection: [],
				isAdmin: false,
				isAgent: false,
				isNormal: false,
				pageTotal: 0,
				loading: false,
				role: '',
				planVisible: false,
				planForm: {
					type: 'content'
				},
				typeList: [{
					value: 'content',
					label: '普通包',
				}, {
					value: 'outside',
					label: '加量包',
				}],
				upFaceInfoVisible: false,
				hideUpload: false,
				limitCount: 1,
				fileList: [],
				uploadData: {
					userId: '',
					currentUserId: ''
				},
				dialogImageUrl: null,
				uploadImgBig: false,
				imgVisible: false,
				srcimgurl: '',
				progress: 0,
				deleteForm: {
					remark: '',
					id: ''
				},
				deleteVisible: false,
				ModifyFaceStateForm: {
					remark: '',
					id: ''
				},
				ModifyFaceStateVisible: false,
				packageList: [],
				rowId: '',

			};
		},
		created() {
			this.role = localStorage.getItem('ms_role');
			this.init();
			if (this.role === 'admin') {
				this.isAdmin = true;
				this.isNormal = true;
			}
			if (this.role == 'agent') {
				this.isAgent = true;
				this.isNormal = true;
			}
			this.getqueryPackageList();
		},
		methods: {
			getfetchDefaultCompanies() {
				this.query.companyId = '';
				let data = {
					currentUserId: localStorage.getItem('user'),
					subAgentId: this.query.subAgentId
				};
				fetchDefaultCompanies(data).then(res => {
					this.companys = res.data;
					if (this.role == 'agent') {
						this.query.companyId = res.data[0].value;
						this.getData();
					}
				});
			},
			getquerySubAgent() {
				let data = {
					agentId: this.query.agentId,
					userId: localStorage.getItem('user')
				};
				querySubAgent(data).then(res => {
					if (res.code == 200) {
						this.subAgentList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			tableRowClassName({
				rowIndex
			}) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			init() {
				let data = {
					currentUserId: localStorage.getItem('user')
				};
				fetchInitAgent(data).then(res => {
					if (res.data) {
						this.invalidateDate = res.data.validCallTime;
					}
				});
				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
				});
				this.getfetchDefaultCompanies();
			},
			getData() {
				let data = {
					userId: localStorage.getItem('user'),
					agentId: this.query.agentId,
					companyId: this.query.companyId,
					phone: this.query.phone,
					type: this.query.type,
					packageMinutes: this.query.packageMinutes,
					pageIndex: this.query.pageIndex,
					pageSize: this.query.pageSize,
				};
				accountSetMeal(data).then(res => {
					if (res.code == 200) {
						this.stopVul = res.customData.stop;
						this.noAuthVul = res.customData.noAuth;
						this.noPackageVul = res.customData.noPackage;
						this.susVul = res.customData.sus;
						this.allVul = res.customData.all;
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			agentChange(agentId) {
				this.query.agentId = agentId;
				this.query.companyId = '';
				this.query.departmentId = '';
				this.query.userId = '';
				this.query.packageMinutes = '';
				this.packageList = [];
				if (agentId) {
					let data = {
						subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
						this.getqueryPackageList()
					});
				} else {
					this.departments = [];
					this.getqueryPackageList()
				}
			},
			companyChange(companyId) {
				this.query.companyId = companyId;
				this.query.departmentId = '';
				this.query.userId = '';
				this.query.packageMinutes = '';
				this.packageList = [];
				let data = {
					companyId: companyId,
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
					this.query.pageIndex = 1;
					this.getqueryPackageList();
				});
			},
			packageChange(packageMinutes) {
				this.query.packageMinutes = packageMinutes;
				this.getData()
			},
			getqueryPackageList() {
				let data = {
					agentId: this.query.agentId,
					companyId: this.query.companyId,
					userId: localStorage.getItem('user')
				};
				queryPackageList(data).then(res => {
					this.packageList = res.data;
					this.query.pageIndex = 1;
					this.getData();
				});
			},
			handleClick(tab, event) {
				this.query.type = tab.props.name;
				this.getData();
			},
			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.getData();
			},
			// 多选操作
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},

			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getData();
			},
			plan(row) {
				this.rowId = row.id;
				let data = {
					currentUserId: localStorage.getItem('user'),
					userId: row.id,
				};
				accountAssignable(data).then(res => {
					if (res.code == 200) {
						this.planVisible = true;
						this.contentList = res.data.content;
						this.outsideList = res.data.outside;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getTransferSipSetMeal() {
				let data = {
					userId: this.rowId,
					currentUserId: localStorage.getItem('user'),
					type: this.planForm.type,
					minutes: this.planForm.minutes,
				};
				transferSipSetMeal(data).then(res => {
					if (res.code == 200) {
						this.planVisible = false;
						this.$message.success(res.message);
						this.getData();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			upFaceInfo(row) {
				console.log(row, 'rowrowrowrow')
				this.upFaceInfoVisible = true;
				this.uploadData.userId = row.id;
				this.uploadData.currentUserId = localStorage.getItem('user');
			},
			uploadFile({ file, onProgress, onSuccess, onError }) {
				// 使用 axios 发送自定义的上传请求
				console.log(file, 'file')
				console.log(onProgress, 'onProgress')
				console.log(onSuccess, 'onSuccess')
				console.log(onError, 'onError')
				const formData = new FormData();
				formData.append('file', file);

				this.axios.post('/api/faceAuthentication/uploadFace', formData, {
						headers: {
							'Content-Type': 'multipart/form-data'
						},
						onUploadProgress: progressEvent => {
							// 更新上传进度
							const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent
								.total);
							onProgress({ percent: percentCompleted }, file);
						}
					})
					.then(response => {
						// 处理成功响应
						onSuccess(response.data);
					})
					.catch(error => {
						// 处理错误响应
						onError(error);
					});
			},
			beformRemove(fileList) {
				console.log(fileList, '到了大吉大利记录')
				this.hideUpload = fileList.length >= this.limitCount;
			},
			getUploadFace() {
				if (this.$refs.upload.uploadFiles[0]) {
					this.$refs.upload.submit();
				} else {
					this.$message.error('请选择上传');
				}
			},
			// 处理上传进度
			handleProgress(event, file, fileList) {
				console.log(event, 'event')
				console.log(file, 'file')
				console.log(fileList, 'fileList')
				this.progress = Math.round((event.percent * 100));
			},
			uploadSuccess(success) {
				if (success.code == 200) {
					this.progress = 0;
					this.upFaceInfoVisible = false;
					this.$message.success(success.data);
					this.getData();
				} else {
					this.$message.error(success.message);
				}
			},
			getfile(file, fileList) {
				this.hideUpload = fileList.length >= this.limitCount;
				this.file = file.url;
			},
			closeDialog2() {
				this.hideUpload = '';
				this.upFaceInfoVisible = false;
				this.$refs.upload.uploadFiles = []
			},
			closeDialog() {
				this.planVisible = false;
			},
			//查看大图
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.uploadImgBig = true;
			},
			//查看图片信息
			bigImg(id) {
				this.srcimgurl = '';
				this.imgVisible = true;
				this.getLookTfSipFace(id)
			},
			getLookTfSipFace(id) {
				let data = {
					userId: id
				};
				lookTfSipFace(data).then(res => {
					const blob = new Blob([res], {
						type: 'image/png'
					});
					const url = URL.createObjectURL(blob);
					this.srcimgurl = url;
				});
			},
			// 删除操作
			handleDelete(index, id) {
				this.deleteForm.remark = '';
				this.deleteVisible = true;
				this.deleteForm.id = id;
			},
			getDelete() {
				let data = {
					id: this.deleteForm.id,
					userId: localStorage.getItem('user'),
					remark: this.deleteForm.remark,
				};
				clearFaceInfo(data).then(res => {
					if (res.code == 200) {
						this.$message.success('清除成功');
						this.deleteVisible = false;
						this.getData();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			closeDialog3() {
				this.deleteVisible = false;
				this.ModifyFaceStateVisible = false;
				this.deleteForm.id = '';
				this.ModifyFaceStateForm.id = '';
				this.deleteForm.remark = '';
				this.ModifyFaceStateForm.remark = '';
			},
			handleModifyFaceState(index, id) {
				this.ModifyFaceStateForm.remark = '';
				this.ModifyFaceStateVisible = true;
				this.ModifyFaceStateForm.id = id;
			},
			getModifyFaceState() {
				let data = {
					id: this.ModifyFaceStateForm.id,
					userId: localStorage.getItem('user'),
					remark: this.ModifyFaceStateForm.remark,
				};
				ModifyFaceState(data).then(res => {
					if (res.code == 200) {
						this.$message.success('修改成功');
						this.ModifyFaceStateVisible = false;
						this.getData();
					} else {
						this.$message.error(res.message);
					}
				});
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.down {
		border: 1px solid #dcdfe6;
		background: #4f7afd;
		color: #ffffff;
		transition: 0.1s;
		font-weight: 500;
		padding: 8px 16px;
		font-size: 12px;
		border-radius: 4px;
		margin-left: 10px;

		display: inline-block;
	}

	.handle-select {
		width: 120px;
	}

	.handle-input {
		width: 300px;
		display: inline-block;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #ff0000;
	}

	.mr10 {
		margin-right: 10px;
	}

	.grid-con-icon {
		font-size: 50px;
		width: 100px;
		height: 100px;
		text-align: center;
		line-height: 100px;
		color: #fff;
	}

	.grid-cont-right {
		flex: 1;
		text-align: center;
		font-size: 20px;
		font-weight: bold;
		color: #999;
	}

	.grid-content {
		display: flex;
		align-items: center;
		height: 100px;
	}
</style>
<style>
	.import-visible .el-upload {
		width: 100% !important;
		display: flex !important;
		flex-direction: column !important;
		align-items: center !important;
		justify-content: center !important;
	}
</style>