<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" v-if="isAdmin" @change="agentChange" v-model="query.agentId" filterable
					clearable placeholder="代理商" class="handle-select mr10" style="width: 260px;">
					<el-option v-for="item in agents" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-if="isSubAgent" v-model="query.subAgentId" @change="getfetchDefaultCompanies"
					filterable clearable placeholder="子代理" class="handle-select mr10" style="width: 260px;">
					<el-option v-for="item in subAgentList" :key="item.id" :label="item.name"
						:value="item.id"></el-option>
				</el-select>
				<el-select size="small" style="width: 260px" v-if="isAgent" v-model="query.companyId"
					@change="companyChange" filterable clearable placeholder="企业" class="handle-select mr10">
					<el-option v-for="item in companys" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-model="query.departmentId" filterable clearable placeholder="部门"
					class="handle-select mr10" style="width: 180px;">
					<el-option v-for="item in departments" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-model="query.expired" filterable clearable placeholder="是否过期"
					class="handle-select mr10" style="width: 180px;">
					<el-option key="3" label="全部" value="0"></el-option>
					<el-option key="3" label="未过期" value="1"></el-option>
					<el-option key="3" label="已过期" value="2"></el-option>
				</el-select>
				<el-input size="small" v-model.trim="query.account" clearable placeholder="员工手机号或姓名" class="mr10"
					style="width: 180px;"></el-input>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
					@click="handleSearch">搜索</el-button>
				<el-button size="small" v-if="isCompany && !issubAgent" type="primary" :class="[theme]"
					icon="el-icon-plus" @click="showForm">添加员工</el-button>
				<el-button size="small" v-if="isCompany && !issubAgent" type="primary" :class="[theme]"
					icon="el-icon-upload" @click="uploadExcel">上传文件</el-button>
				<a :href="`${path}user.xlsx`" v-if="isCompany && !issubAgent" class="down mr10 " download="员工模板.xlsx"
					:class="[theme]">
					<i class="el-icon-download"></i>
					下载模板
				</a>
				<el-button size="small" v-if="isCompany && !issubAgent" type="primary" :class="[theme]"
					icon="el-icon-delete" @click="batchDel">批量删除</el-button>
				<el-button size="small" v-if="isAdmin || (isAgent && !issubAgent)" type="primary" :class="[theme]"
					icon="el-icon-upload2" class="m_t_10" style="margin-top: 10px;" @click="handleDownload">
					导出
				</el-button>
				<el-button size="small" v-if="isAdmin || (isAgent && !issubAgent)" type="primary" :class="[theme]"
					icon="el-icon-setting" style="margin-top: 10px;" @click="batchEdit">
					批量设置
				</el-button>
				<el-button size="small" v-if="isAdmin && !issubAgent" type="primary" :class="[theme]"
					icon="el-icon-s-promotion" style="margin-top: 10px;" @click="getmigrationVisible">
					迁移员工
				</el-button>
				<el-button size="small" v-if="batchAdd && CY2 && !issubAgent" type="primary" :class="[theme]"
					style="margin-top: 10px;" @click="batchEdit2">开通增值服务</el-button>
				<el-button size="small"
					v-if="ms_role == 'admin'|| queryAgent && multipleSelection.length > 0 && !issubAgent" type="primary"
					:class="[theme]" style="margin-top: 10px;" @click="getuserBatchQuery">
					迪加查询
				</el-button>
				<el-button size="small"
					v-if="ms_role == 'admin'|| queryAgent && multipleSelection.length > 0 && !issubAgent" type="primary"
					:class="[theme]" style="margin-top: 10px;" @click="getuserBatchQuery2">
					蜗牛查询
				</el-button>
			</div>

			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				:header-cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange"
				:row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }" :row-class-name="tableRowClassName">
				<el-table-column type="selection" width="55" align="center"></el-table-column>
				<el-table-column prop="companyName" width="250" label="企业" align="center"></el-table-column>
				<el-table-column prop="departmentName" width="120" label="部门" align="center"></el-table-column>
				<el-table-column prop="role" label="角色" width="110" align="center"></el-table-column>
				<el-table-column prop="account" width="130" label="账号" sortable align="center"></el-table-column>
				<el-table-column prop="name" width="130" label="姓名" align="center"></el-table-column>
				<el-table-column prop="cardName" label="品牌" align="center"></el-table-column>
				<el-table-column prop="createDate" label="创建时间" sortable align="center"></el-table-column>
				<el-table-column prop="invalidateDate" label="失效时间" sortable align="center">
					<template #default="scope">
						<div v-if="scope.row.isUse == '开启'">{{ scope.row.invalidateDate }}</div>
						<div v-else style="color: #f56c6c;">{{ scope.row.invalidateDate }}</div>
					</template>
				</el-table-column>
				<el-table-column prop="isUse" width="80" label="状态" align="center">
					<template #default="scope">
						<div v-if="scope.row.isUse == '开启'" class="el-button--text">{{ scope.row.isUse }}</div>
						<div v-else style="color: #f56c6c;">{{ scope.row.isUse }}</div>
					</template>
				</el-table-column>
				<el-table-column prop="vipService" label="增值服务" align="center">
					<template #default="scope">
						<el-button size="small" type="text" v-if="scope.row.vipService == '未开通'"
							style="color: #606266;">未开通</el-button>
						<el-tooltip class="item-abc" effect="dark" :content="scope.row.vipService" placement="top-start"
							v-else>
							<el-button size="small" style="color: #66b1ff;">查看</el-button>
						</el-tooltip>
						<!-- <div v-else-if="scope.row.vipService.status == '已开通'">{{scope.row.vipService.txt}} <br/> {{scope.row.vipService.computer}}</div> -->
					</template>
				</el-table-column>
				<el-table-column v-if="isNormal" label="操作" width="190" align="center">
					<template #default="scope">
						<el-button size="small" type="text" icon="el-icon-edit"
							@click="handleEdit(scope.$index, scope.row)">编辑</el-button>
						<el-button size="small" v-if="isAgent" type="text" icon="el-icon-delete" style="color: #f56c6c;"
							@click="handleDelete(scope.$index, scope.row.id)">
							删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[100, 200, 300, 400]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>
		</div>
		<div class="user-box">
			<el-dialog title="文件上传" v-model="upload" width="500px" @close="closeUpload()" :close-on-click-modal="false">
				<el-upload ref="upload" action="/api/user/upload" :headers="token" :limit="1"
					:on-preview="handlePreview" :on-remove="handleRemove" :on-success="uploadSuccess"
					:auto-upload="false" :data="form" :before-upload="beforeAvatarUpload">
					<el-button size="small" type="primary" :class="[theme]">选择文件</el-button>
					<div class="el-upload__tip">只能上传excel文件</div>
				</el-upload>

				<div class="upload-user-box">
					<el-form :model="form" :rules="rules" ref="form" label-width="100px">
						<div v-if="isAdmin" class="user-list">
							<span>代理：</span>
							<el-select size="small" v-model="form.agentName" filterable @change="agentChange" clearable
								:disabled="companyDis" placeholder="代理商" class="handle-select mr10"
								style="width: 100%;">
								<el-option v-for="item in agents" :key="item.value" :label="item.label"
									:value="item.value"></el-option>
							</el-select>
						</div>
						<div class="user-list">
							<span>企业：</span>
							<el-select size="small" v-model="form.companyId" filterable @change="companyChange"
								placeholder="请选择企业" :disabled="companyDis" style="width: 100%;">
								<el-option v-for="item in companys" :key="item.value" :label="item.label"
									:value="item.value"></el-option>
							</el-select>
						</div>
						<div class="user-list">
							<span>部门：</span>
							<el-select size="small" v-model="form.departmentId" filterable placeholder="请选择部门"
								style="width: 100%;">
								<el-option v-for="item in departments" :key="item.value" :label="item.label"
									:value="item.value"></el-option>
							</el-select>
						</div>
						<div class="user-list">
							<span>品牌：</span>
							<el-select size="small" v-model="form.cardsId" filterable placeholder="请选择品牌"
								style="width: 100%;">
								<el-option v-for="item in cardTypeList" :key="item.label" :label="item.value"
									:value="item.label"></el-option>
							</el-select>
						</div>

						<div class="user-list" v-if="form.cardsId == '62b52ab62ab7d226841d8ad1'">
							<div v-if="interior">
								<span>自动加白：</span>
								<el-switch v-model="form.whitening" active-color="#13ce66"
									inactive-color="#ff4949"></el-switch>
							</div>
						</div>
						<div class="user-list">
							<span>有效期：</span>
							<el-select size="small" v-model="form.invalidateDateIncrease" filterable clearable
								placeholder="请设置有效期">
								<el-option key="1" label="一个月" value="1"></el-option>
								<el-option key="2" label="两个月" value="2"></el-option>
								<el-option key="3" label="三个月" value="3"></el-option>
								<el-option key="6" label="六个月" value="6"></el-option>
								<el-option key="12" label="12个月" value="12"></el-option>
							</el-select>
							<el-input size="small" v-model.trim="form.invalidateDayIncrease" placeholder="请输入天数"
								style="width: 120px;margin-left: 10px;" type="number"></el-input>
						</div>
						<div class="user-list">
							<div style="font-size: 12px;color: #ff0000;">*天数为负数时，会扣除对应的天数</div>
						</div>
					</el-form>
				</div>
				<el-button size="small" type="primary" :class="[theme]" style="margin-left: 80%;"
					@click="submitUpload">确定上传</el-button>
			</el-dialog>
		</div>

		<el-dialog title="员工编辑" v-model="addVisible" width="500px" v-loading="dialogLoading"
			@close="closeDialog3('form')" :close-on-click-modal="false">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="添加类型" v-if="addButton">
					<el-radio-group v-model="form.addUser" @change="formtypeChange">
						<el-radio label="2">批量添加</el-radio>
						<el-radio label="1">单个添加</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="代理" prop="agentId" v-if="isAdmin">
					<el-select size="small" v-model="form.agentName" filterable @change="agentChange" clearable
						:disabled="companyDis" placeholder="代理商" class="handle-select mr10" style="width: 100%;">
						<el-option v-for="item in agents" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="子代理" prop="subAgentId" v-if="isSubAgent">
					<el-select size="small" v-model="form.subAgentId" @change="getfetchDefaultCompanies2" filterable
						clearable :disabled="companyDis" placeholder="请选择子代理" class="handle-select mr10"
						style="width: 100%;">
						<el-option v-for="item in subAgentList" :key="item.id" :label="item.name"
							:value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="企业" prop="companyId">
					<el-select size="small" v-model="form.companyId" filterable @change="companyChange"
						placeholder="请选择企业" :disabled="companyDis" style="width: 100%;">
						<el-option v-for="item in companys" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="部门" prop="departmentId">
					<el-select size="small" v-model="form.departmentId" filterable placeholder="请选择部门"
						style="width: 100%;">
						<el-option v-for="item in departments" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="品牌" prop="cardsId">
					<el-select size="small" v-model="form.cardsId" filterable placeholder="请选择品牌" style="width: 100%;"
						@change="cardsIdChange" :disabled="roleDis">
						<el-option v-for="item in cardTypeList" :key="item.label" :label="item.value"
							:value="item.label"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="姓名" prop="name" v-if="form.addUser == '1'"><el-input size="small"
						v-model="form.name" placeholder="请输入姓名"></el-input></el-form-item>
				<el-form-item label="账号" prop="account" v-if="form.addUser == '1'">
					<el-input size="small" v-model.trim="form.account" placeholder="请输入手机号"
						:disabled="updateDis"></el-input>
				</el-form-item>
				<el-form-item label="账号" prop="phones" v-if="form.addUser == '2'">
					<el-input size="small" :rows="5" type="textarea" v-model="form.phones" auto-complete="off"
						placeholder="一行一个手机号码,一次最多添加500个号码"></el-input>
					<div style="font-size: 12px;color: #ff0000;">*批量添加时，账号和姓名一样，请注意</div>
				</el-form-item>
				<el-form-item label="密码" prop="password" v-if="updateButton"><el-input size="small"
						v-model="form.password" :disabled="passwordDis"></el-input></el-form-item>
				<el-form-item label="SIP密码" prop="sipPwd" v-if="form.sip"><el-input size="small" v-model="form.sipPwd"
						disabled></el-input></el-form-item>
				<el-form-item label="角色" prop="role">
					<el-select size="small" v-model="form.role" filterable clearable placeholder="请选择角色"
						style="width: 100%;" :disabled="roleDis">
						<el-option key="1" label="普通员工" value="normal"></el-option>
						<el-option key="2" label="部门管理员" value="department"></el-option>
						<el-option key="3" label="企业管理员" value="company"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="身份证号" prop="cardId"><el-input size="small" v-model="form.cardId"
						:disabled="updateDis"></el-input></el-form-item>
				<el-form-item label="有效期" prop="invalidateDateIncrease" v-if="isAdmin || (isAgent && !issubAgent)">
					<el-select size="small" v-model="form.invalidateDateIncrease" filterable clearable
						placeholder="请设置有效期">
						<el-option key="1" label="一个月" value="1"></el-option>
						<el-option key="2" label="两个月" value="2"></el-option>
						<el-option key="3" label="三个月" value="3"></el-option>
						<el-option key="6" label="六个月" value="6"></el-option>
						<el-option key="12" label="12个月" value="12"></el-option>
					</el-select>
					<el-input size="small" v-model.trim="form.invalidateDayIncrease" placeholder="请输入天数"
						style="width: 120px;margin-left: 20px;" type="number"></el-input>
					<div style="font-size: 12px;color: #ff0000;">*天数为负数时，会扣除对应的天数</div>
				</el-form-item>
				<el-form-item label="启用" prop="enable">
					<el-switch :disabled="updateDis" v-model="form.enable" active-color="#13ce66"
						inactive-color="#ff4949"></el-switch>
				</el-form-item>
				<el-form-item label="服务端过滤" prop="serverFiltration" v-if="updateButton&&isAdmin">
					<el-switch v-model="form.serverFiltration" active-color="#13ce66"
						inactive-color="#ff4949"></el-switch>
				</el-form-item>
				<div v-if="addButton">
					<div v-if="form.cardsId == '62b52ab62ab7d226841d8ad1'">
						<el-form-item label="自动加白" prop="interior" v-if="interior == 'true'">
							<el-switch v-model="form.whitening" active-color="#13ce66"
								inactive-color="#ff4949"></el-switch>
						</el-form-item>
					</div>
				</div>
				<el-form-item>
					<el-button size="small" style="margin-left: 55%;" @click="addVisible = false">取消</el-button>
					<el-button size="small" v-if="addButton" type="primary" :class="[theme]"
						@click="addUser('form')">确定</el-button>
					<el-button size="small" v-if="updateButton" type="primary" :class="[theme]"
						@click="updateUser('form')">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<el-dialog title="开通增值服务" v-model="batchVisible2" width="650px" @close="closeDialog('form2')"
			:close-on-click-modal="false">
			<el-form :model="form2" :rules="rules" ref="form2" label-width="140px">
				<el-form-item label="设置类型"><el-cascader v-model="form2.value" :options="options"
						@change="handleChange"></el-cascader></el-form-item>
				<el-form-item label="支付类型">
					<el-radio-group v-model="form2.pay" @change="typeChange">
						<el-radio v-for="item in payList" :key="item.value" :label="item.value"
							:value="item.value">{{ item.label }}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="扫码支付" v-show="weixinUrl">
					<div id="qrcode" ref="qrcode" style="position: relative;"></div>
					<div><img src="../assets/img/wxpay.png" v-if="form2.pay == 'wxpay'" alt=""
							style="position: absolute;top: 46px;left:44px;width: 42px;height: 42px;" /></div>
					<div><img src="../assets/img/alipay.png" v-if="form2.pay == 'alipay'" alt=""
							style="position: absolute;top: 46px;left:44px;width: 42px;height: 42px;" /></div>
				</el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="closeDialog('form2')">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]"
						@click="openServices('form2')">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="支付" v-model="payVisible" width="550px" @close="closeDialog('form2')"
			:close-on-click-modal="false"></el-dialog>
		<el-dialog title="批量设置" v-model="batchVisible" width="510px" @close="closeDialog('form2')"
			:close-on-click-modal="false">
			<el-form :model="form2" :rules="rules" ref="form2" label-width="140px">
				<!-- <el-form-item label="设置类型" v-if="isAgent">
					<el-select size="small" v-model="form2.type" filterable clearable placeholder="请选择类型">
						<el-option key="1" label="会员有效期" value="1"></el-option>
						<el-option key="4" label="账号状态" value="4"></el-option>
					</el-select>
				</el-form-item> -->
				<el-form-item label="设置类型" v-if="isAdmin || isAgents">
					<el-select size="small" v-model="form2.type" filterable clearable placeholder="请选择类型">
						<el-option key="1" label="会员有效期" value="1"></el-option>
						<el-option key="2" label="文字识别" value="2"></el-option>
						<el-option key="3" label="电脑拨号" value="3"></el-option>
						<el-option key="4" label="账号状态" value="4"></el-option>
						<el-option key="4" label="品牌" value="5"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="品牌" prop="cardsId" v-if="form2.type == 5">
					<el-select size="small" v-model="form2.cardsId" filterable clearable placeholder="请选择品牌种类">
						<el-option v-for="item in cardTypeList" :key="item.label" :label="item.value"
							:value="item.label"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="会员有效期" prop="invalidateDateIncrease"
					v-if="form2.type == 1 || form2.type == '会员有效期'">
					<el-select size="small" v-model="form2.invalidateDateIncrease" filterable clearable
						placeholder="请设置有效期">
						<el-option key="1" label="一个月" value="1"></el-option>
						<el-option key="2" label="两个月" value="2"></el-option>
						<el-option key="3" label="三个月" value="3"></el-option>
						<el-option key="6" label="六个月" value="6"></el-option>
						<el-option key="12" label="12个月" value="12"></el-option>
					</el-select>
					<el-input size="small" v-model.trim="form2.invalidateDayIncrease" placeholder="请输入天数"
						style="width: 120px;margin-left: 10px;" type="number"></el-input>
					<div style="font-size: 12px;color: #ff0000;">*天数为负数时，会扣除对应的天数</div>
				</el-form-item>
				<el-form-item label="文字识别有效期" prop="invalidateDateIncrease" v-if="form2.type == 2">
					<el-select size="small" v-model="form2.invalidateDateIncrease" filterable clearable
						placeholder="请设置有效期">
						<el-option key="1" label="一个月" value="1"></el-option>
						<el-option key="2" label="两个月" value="2"></el-option>
						<el-option key="3" label="三个月" value="3"></el-option>
						<el-option key="6" label="六个月" value="6"></el-option>
						<el-option key="12" label="12个月" value="12"></el-option>
					</el-select>
					<el-input size="small" v-model.trim="form2.invalidateDayIncrease" placeholder="请输入天数"
						style="width: 120px;margin-left: 10px;" type="number"></el-input>
					<div style="font-size: 12px;color: #ff0000;">*天数为负数时，会扣除对应的天数</div>
				</el-form-item>
				<el-form-item label="电脑拨号有效期" prop="invalidateDayIncrease" v-if="form2.type == 3">
					<el-select size="small" v-model="form2.invalidateDateIncrease" filterable clearable
						placeholder="请设置有效期">
						<el-option key="1" label="一个月" value="1"></el-option>
						<el-option key="2" label="两个月" value="2"></el-option>
						<el-option key="3" label="三个月" value="3"></el-option>
						<el-option key="6" label="六个月" value="6"></el-option>
						<el-option key="12" label="12个月" value="12"></el-option>
					</el-select>
					<el-input size="small" v-model.trim="form2.invalidateDayIncrease" placeholder="请输入天数"
						style="width: 120px;margin-left: 10px;" type="number"></el-input>
					<div style="font-size: 12px;color: #ff0000;">*天数为负数时，会扣除对应的天数</div>
				</el-form-item>
				<el-form-item label="修改号码" prop="phones" v-if="editInput == true">
					<el-input size="small" type="textarea" :rows="5" placeholder="一行一个号码,一次最多添加100个号码"
						v-model="form2.phones"></el-input>
				</el-form-item>
				<el-form-item label="账号状态" prop="enable2" v-if="form2.type == 4">
					<el-switch v-model="form2.enable2" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
				</el-form-item>

				<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="batchVisible = false">取消</el-button>
					<el-button size="small" v-if="updateButton" type="primary" :class="[theme]"
						@click="batchUpdate('form2')">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="批量分配品牌" v-model="distributionVisible" width="500px" v-loading="dialogLoading"
			@close="closeDialog('form')" :close-on-click-modal="false">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="品牌" prop="cardsId">
					<el-select size="small" v-model="form.cardsId" filterable placeholder="请选择品牌" style="width: 100%;">
						<el-option v-for="item in cardTypeList" :key="item.label" :label="item.value"
							:value="item.label"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button size="small" @click="distributionVisible = false">取消</el-button>
					<el-button size="small" style="margin-left: 55%;" type="primary" :class="[theme]"
						@click="distributionbatchSet('form')">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="设置失败" v-model="customDataDialog" width="500px" v-loading="dialogLoading" @close="closeDialog2"
			:close-on-click-modal="false">
			<div v-for="(item, index) in customData" :key="index">{{ item }}</div>
		</el-dialog>

		<el-dialog title="批量删除" v-model="deleteVisible" width="800px" @close="closeDialog('form')"
			:close-on-click-modal="false">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="删除号码" prop="getbatchQueryStaffphoneNumber">
					<el-input size="small" type="textarea" :rows="5" placeholder="一行一个删除单号码,一次最多删除100个号码"
						v-model="getbatchQueryStaffphoneNumber" @input="getbatchQueryStaffphoneNumbers"></el-input>
				</el-form-item>
			</el-form>
			<el-table v-if="getbatchQueryStafftableData.length > 0" :data="getbatchQueryStafftableData" border
				class="table" ref="multipleTable" header-cell-class-name="table-header"
				:header-cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange"
				:row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }" :row-class-name="tableRowClassName">
				<el-table-column prop="agent" width="250" label="代理" align="center"></el-table-column>
				<el-table-column prop="company" label="企业" align="center"></el-table-column>
				<el-table-column prop="department" width="120" label="部门" align="center"></el-table-column>
				<el-table-column prop="userPhone" width="130" label="用户名" align="center"></el-table-column>
			</el-table>
			<span class="dialog-footer"
				style="display: flex;flex-direction: row;justify-content: flex-end;margin-top: 20px;">
				<el-button size="small" type="primary" :class="[theme]" @click="getbatchQueryStaff"
					:loading="loading">查询</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="getbatchDeleteByUserPhones"
					v-if="getbatchQueryStafftableData.length > 0" :loading="loading">
					确认删除
				</el-button>
			</span>
		</el-dialog>
		<el-dialog title="迁移编辑" v-model="migrationVisible" width="500px" @close="closeDialog('form')"
			:close-on-click-modal="false">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="迁移号码" prop="migrationphoneNumber">
					<el-input size="small" type="textarea" :rows="5" placeholder="一行一个迁移单号码,一次最多迁移100个号码"
						v-model="migrationphoneNumber"></el-input>
				</el-form-item>
				<el-form-item label="代理" prop="agentId" v-if="isAdmin">
					<el-select size="small" v-model="form.agentName" filterable @change="agentChange" clearable
						:disabled="companyDis" placeholder="代理商" class="handle-select mr10" style="width: 100%;">
						<el-option v-for="item in agents" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="企业" prop="companyId">
					<el-select size="small" v-model="form.companyId" filterable @change="companyChange"
						placeholder="请选择企业" :disabled="companyDis" style="width: 100%;">
						<el-option v-for="item in companys" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="部门" prop="departmentId">
					<el-select size="small" v-model="form.departmentId" filterable placeholder="请选择部门"
						style="width: 100%;">
						<el-option v-for="item in departments" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
			</el-form>

			<span class="dialog-footer"
				style="display: flex;flex-direction: row;justify-content: flex-end;margin-top: 20px;">
				<el-button size="small" type="primary" :class="[theme]" @click="gettransferStaff"
					:loading="loading">确认迁移</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		fetchData,
		addUser,
		delUser,
		fetchUserById,
		updateUser,
		batchUpdate,
		downloadUsers,
		batchQueryStaff,
		batchDeleteByUserPhones,
		transferStaff,
		openServices,
		userBatchQuery,
		snailUserBatchQuery,
		batchDelete
	} from '../api/userIndex';
	import {
		fetchDepartment
	} from '../api/index.js';
	import {
		fetchCompanyByAgentId,
		fetchDefaultCompanies,
	} from '../api/companyIndex';
	import {
		fetchAgent
	} from '../api/agentIndex';
	import {
		getCardType,
		batchSet
	} from '../api/cardType.js';
	import QRCode from 'qrcodejs2';
	import {
		querySubAgent
	} from '../api/serialIndex.js';
	export default {
		name: 'user',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				interior: localStorage.getItem('interior'),
				query: {
					agentId: '',
					companyId: '',
					departmentId: '',
					pageIndex: 1,
					pageSize: 100,
					expired: '0',
					subAgentId: ''
				},
				options: [{
						value: 'recognition',
						label: '文字识别会员',
						children: [{
								value: 'RECOGNITION_30',
								label: '1个月'
							},
							{
								value: 'RECOGNITION_90',
								label: '3个月'
							},
							{
								value: 'RECOGNITION_180',
								label: '6个月'
							},
							{
								value: 'RECOGNITION_360',
								label: '（12+1）个月'
							}
						]
					},
					{
						value: 'computer',
						label: '电脑拨号会员',
						children: [{
								value: 'COMPUTERDIAL_30',
								label: '1个月'
							},
							{
								value: 'RECOGNITION_90',
								label: '3个月'
							},
							{
								value: 'RECOGNITION_180',
								label: '6个月'
							},
							{
								value: 'RECOGNITION_360',
								label: '（12+1）个月'
							}
						]
					}
				],
				weixinUrl: false,
				payData: '',
				fileList: [],
				agents: [],
				companys: [],
				departments: [],
				users: [],
				tableData: [],
				multipleSelection: [],
				delList: [],
				editVisible: false,
				addVisible: false,
				distributionVisible: false,
				distributionIds: [],
				addButton: false,
				updateButton: false,
				isSubAgent: false,
				batchVisible: false,
				batchVisible2: false,
				isNormal: true,
				isCompany: true,
				batchAdd: false,
				isAdmin: false,
				isAgent: false,
				isAgents: false,
				queryAgent: false,
				migrationVisible: false, //迁移
				migrationphoneNumber: '', //迁移数据
				deleteVisible: false, //批量删除
				getbatchQueryStaffphoneNumber: '',
				getbatchQueryStafftableData: [],
				pageTotal: 0,
				cardTypeList: [],
				form: {
					agentId: '',
					companyId: '',
					departmentId: '',
					cardName: '',
					cardsId: '',
					account: '',
					password: '',
					name: '',
					invalidateDateIncrease: '',
					invalidateDayIncrease: '',
					expired: 0,
					role: 'normal',
					phones: '',
					type: '会员有效期',
					enable: true,
					enable2: true,
					whitening: true,
					interior: true,
					serverFiltration: true,
					addUser: '2',
				},
				form2: {
					type: '',
					invalidateDateIncrease: '',
					invalidateDayIncrease: '',
					phones: '',
					enable2: true,
					pay: 'wxpay',
					value: ''
				},
				payList: [{
						value: 'wxpay',
						label: '微信支付'
					},
					{
						value: 'alipay',
						label: '支付宝支付'
					}
				],
				rules: {
					companyId: [{
						required: true,
						message: '请选择公司',
						trigger: 'change'
					}],
					departmentId: [{
						required: true,
						message: '请选择部门',
						trigger: 'change'
					}],
					account: [{
						required: true,
						message: '请输入账号',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}],
					cardsId: [{
						required: true,
						message: '请选择品牌',
						trigger: 'blur'
					}],
					name: [{
						required: true,
						message: '请输入员工姓名',
						trigger: 'blur'
					}],
					role: [{
						required: true,
						message: '请选择角色',
						trigger: 'blur'
					}]
				},
				idx: -1,
				id: -1,
				upload: false,
				loading: false,
				dialogLoading: false,
				companyDis: false,
				roleDis: false,
				updateDis: false,
				token: {
					Authorization: localStorage.getItem('token'),
					UserRole: localStorage.getItem('ms_role')
				},
				path: process.env.BASE_URL,
				companyName: '',
				editInput: false,
				customDataDialog: false,
				CY2: true,
				issubAgent: false,
				ms_role: localStorage.getItem('ms_role')
			};
		},
		created() {
			let role = localStorage.getItem('ms_role');
			this.subAgent = localStorage.getItem('subAgent');
			if (role === 'normal') {
				this.isCompany = false;
				this.isNormal = false;
				this.updateDis = true;
			}
			if (role === 'company') {
				this.updateDis = true;
				this.isCompany = false;
				this.batchAdd = true;
				this.roleDis = true;
			}
			if (role === 'department') {
				this.updateDis = true;
				this.isCompany = false;
				this.roleDis = true;
			}

			if (role === 'agent') {
				this.isNotAdmin = false;
				this.isAgents = true;
				this.queryAgent = true;
				this.isAgent = true;
				if (this.subAgent == 'true') {
					this.isSubAgent = true;
					this.getquerySubAgent();
				} else {
					this.isSubAgent = false;
				}
			}
			if (role === 'admin') {
				this.isAdmin = true;
				this.isAgent = true;
			}
			if (role === 'subAgent') {
				this.isAgent = true;
				this.issubAgent = true;
			}
			this.init();
			if (window.location.href.indexOf('cytx88.cn') != -1) {
				console.log('我是橙逸，隐藏');
				this.CY2 = false;
			}
			console.log(this.interior, 'interior');
		},
		methods: {
			getfetchDefaultCompanies() {
				this.query.companyId = '';
				let data = {
					currentUserId: localStorage.getItem('user'),
					subAgentId: this.query.subAgentId
				};
				fetchDefaultCompanies(data).then(res => {
					this.companys = res.data;
				});
			},
			getfetchDefaultCompanies2() {
				let data = {
					currentUserId: localStorage.getItem('user'),
					subAgentId: this.form.subAgentId
				};
				fetchDefaultCompanies(data).then(res => {
					this.companys = res.data;
				});
			},
			getquerySubAgent() {
				let data = {
					agentId: this.query.agentId,
					userId: localStorage.getItem('user')
				};
				querySubAgent(data).then(res => {
					if (res.code == 200) {
						this.subAgentList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			tableRowClassName({
				rowIndex
			}) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			init() {
				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
				});

				if (this.subAgent == true) {
					this.isSubAgent = true;
					this.getquerySubAgent();
				}
				this.getfetchDefaultCompanies();
				let data = {
					companyId: '',
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
					if (localStorage.getItem('ms_role') == 'company') {
						this.getData();
					}
					if (localStorage.getItem('ms_role') == 'department') {
						this.query.departmentId = this.departments[0].value;
						this.getData();
					}
				});
				getCardType('').then(res => {
					this.cardTypeList = res.data;
				});
			},
			// 获取数据
			uploadExcel() {
				this.form.departmentId = '';
				this.form.expired = '';
				this.form.cardsId = '';
				this.form.invalidateDayIncrease = '';
				this.form.invalidateDateIncrease = '';
				this.upload = true;
			},
			getData() {
				this.query.currentUserId = localStorage.getItem('user');
				fetchData(this.query).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},

			agentChange(agentId) {
				this.query.agentId = agentId;
				this.query.companyId = '';
				this.query.departmentId = '';
				this.query.userId = '';
				this.query.account = '';
				this.form.agentId = agentId;
				this.form.companyId = '';
				this.form.departmentId = '';
				this.form.userId = '';
				this.form.account = '';
				if (agentId) {
					let data = {
						subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
					});
				}
			},
			companyChange(companyId) {
				this.query.companyId = companyId;
				this.query.departmentId = '';
				this.query.account = '';
				this.form.companyId = companyId;
				this.form.departmentId = '';
				this.form.account = '';
				this.companys.forEach(item => {
					if (companyId == item.value) {
						this.companyName = item.label;
					}
				});
				let data = {
					companyId: companyId,
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
					this.query.pageIndex = 1;
					this.getData();
				});

			},
			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.getData();
			},
			showForm() {
				this.addVisible = true;
				this.addButton = true;
				this.updateButton = false;
				this.form.whitening = true;
				this.form.interior = true;
				this.form.enable = true;
				// this.form.role = 'normal';
				this.form.cardsId = '';
				this.form.sipPwd = '';
				this.form.phones = '';
				this.form.addUser = '2';
			},
			addUser(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						this.loading = true;
						this.form.currentUserId = localStorage.getItem('user');
						this.form.agentId = this.query.agentId;
						this.form.companyId = this.query.companyId;
						if (!this.isAdmin && !this.isAgent) {
							this.form.whitening = false;
						}
						addUser(this.form).then(res => {
							if (res.code == 200) {
								this.$message.success('添加成功');
								this.getData();
								this.addVisible = false;
							} else {
								this.$message.error(res.message);
							}
							this.loading = false;
						});
					}
				});
			},
			updateUser(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						this.loading = true;
						this.form.currentUserId = localStorage.getItem('user');
						if (!this.isAdmin && !this.isAgent) {
							this.form.whitening = false;
						}
						updateUser(this.form).then(res => {
							if (res.code == 200) {
								this.addVisible = false;
								this.getData();
							} else {
								this.$message.error(res.message);
							}
							this.loading = false;
						});
					}
				});
			},
			// 删除操作
			handleDelete(index, id) {
				// 二次确认删除
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						let data = {
							id: id,
							currentUserId: localStorage.getItem('user')
						};
						delUser(data).then(res => {
							if (res.code == 200) {
								this.$message.success('删除成功');
								this.pageTotal = this.pageTotal - 1;
								this.tableData.splice(index, 1);
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
			},
			// 多选操作
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			getuserBatchQuery() {
				let str = '';
				this.delList = this.delList.concat(this.multipleSelection);
				for (let i = 0; i < this.multipleSelection.length; i++) {
					str += this.multipleSelection[i].id + ',';
				}
				if (str === '') {
					this.$message.error(`请选择员工`);
					return;
				} else {
					let data = {
						ids: str,
						userId: localStorage.getItem('user')
					};
					userBatchQuery(data).then(res => {
						if (res.code == 200) {
							this.$message.success(res.data);
							this.getData();
						} else {
							this.$message.error(res.message);
						}
					});
				}
			},
			getuserBatchQuery2() {
				let str = '';
				this.delList = this.delList.concat(this.multipleSelection);
				for (let i = 0; i < this.multipleSelection.length; i++) {
					str += this.multipleSelection[i].id + ',';
				}
				if (str === '') {
					this.$message.error(`请选择员工`);
					return;
				} else {
					let data = {
						ids: str,
						userId: localStorage.getItem('user')
					};
					snailUserBatchQuery(data).then(res => {
						if (res.code == 200) {
							this.$message.success(res.data);
							this.getData();
						} else {
							this.$message.error(res.message);
						}
					});
				}
			},
			batchEdit() {
				this.form2.invalidateDateIncrease = '';
				this.form2.invalidateDayIncrease = '';
				this.form2.type = '会员有效期';
				let str = '';
				this.delList = this.delList.concat(this.multipleSelection);
				for (let i = 0; i < this.multipleSelection.length; i++) {
					str += this.multipleSelection[i].id + ',';
				}
				if (str === '') {
					this.editInput = true;
				} else {
					this.editInput = false;
				}
				this.batchVisible = true;
				this.updateButton = true;
			},
			batchEdit2() {
				this.form2.invalidateDateIncrease = '';
				this.form2.invalidateDayIncrease = '';
				this.form2.type = '会员有效期';
				let str = '';
				this.delList = this.delList.concat(this.multipleSelection);
				for (let i = 0; i < this.multipleSelection.length; i++) {
					str += this.multipleSelection[i].id + ',';
				}
				if (str === '') {
					this.$message.success('请选择员工');
				} else {
					this.batchVisible2 = true;
				}
			},
			batchDel() {
				let str = '';
				this.delList = this.delList.concat(this.multipleSelection);
				for (let i = 0; i < this.multipleSelection.length; i++) {
					str += this.multipleSelection[i].id + ',';
				}
				if (str === '') {
					this.deleteVisible = true;
					this.getbatchQueryStafftableData = [];
				} else {
					let param = {
						userIds: str,
						currentUserId: localStorage.getItem('user')
					};
					// 二次确认删除
					this.$confirm('确定要删除吗？', '提示', {
							type: 'warning'
						})
						.then(() => {
							batchDelete(param).then(res => {
								if (res.code == 200) {
									this.$message.success('批量删除成功');
									this.getData();
								} else {
									this.$message.error(res.message);
								}
							});
						})
						.catch(() => {});
				}
				this.multipleSelection = [];
			},
			getbatchQueryStaffphoneNumbers() {
				this.getbatchQueryStafftableData = [];
			},
			getmigrationVisible() {
				this.migrationVisible = true;
				this.migrationphoneNumber = '';
			},
			gettransferStaff() {
				let data = {
					userId: localStorage.getItem('user'),
					userPhones: this.migrationphoneNumber,
					companyId: this.form.companyId,
					departmentId: this.form.departmentId
				};

				transferStaff(data).then(res => {
					if (res.code == 200) {
						this.migrationVisible = false;
						this.$message.success('迁移成功');
						this.getData();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getbatchQueryStaff() {
				let data = {
					userId: localStorage.getItem('user'),
					userPhones: this.getbatchQueryStaffphoneNumber
				};
				this.getbatchQueryStafftableData = [];
				batchQueryStaff(data).then(res => {
					if (res.code == 200) {
						if (res.data.length > 0) {
							this.getbatchQueryStafftableData = res.data;
						} else {
							this.$message.error('没有查询到账号信息');
						}
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getbatchDeleteByUserPhones() {
				let param = {
					userId: localStorage.getItem('user'),
					userPhones: this.getbatchQueryStaffphoneNumber
				};
				// 二次确认删除
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						batchDeleteByUserPhones(param).then(res => {
							if (res.code == 200) {
								this.$message.success('批量删除成功');
								this.getbatchQueryStaffphoneNumber = '';
								this.deleteVisible = false;
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
			},
			batchSet() {
				this.distributionVisible = true;
				this.delList = this.delList.concat(this.multipleSelection);
				for (let i = 0; i < this.multipleSelection.length; i++) {
					this.distributionIds += this.multipleSelection[i].id + ',';
				}
			},
			distributionbatchSet() {
				let param = {
					userIds: this.distributionIds,
					cardsId: this.form.cardsId
				};
				batchSet(param).then(res => {
					if (res.code == 200) {
						this.distributionVisible = false;
						this.getData();
					} else {
						this.$message.error(res.message);
					}
				});
				this.multipleSelection = [];
			},
			openServices() {
				let str = '';
				this.delList = this.delList.concat(this.multipleSelection);
				for (let i = 0; i < this.multipleSelection.length; i++) {
					str += this.multipleSelection[i].id + ',';
				}
				let param = {
					userId: localStorage.getItem('user'),
					userIds: str,
					type: this.form2.value[1],
					pay: this.form2.pay
				};
				this.$refs.qrcode.innerHTML = '';
				openServices(param).then(res => {
					if (res.code == 200) {
						if (this.form2.pay == 'wxpay') {
							this.weixinUrl = true;
							this.payData = res.data;
							this.qr = new QRCode('qrcode', {
								width: 130,
								height: 130, // 高度
								text: this.payData.url, // 二维码内容
								colorDark: '#000000',
								colorLight: '#ffffff'
							});
						} else if (this.form2.pay == 'alipay') {
							this.weixinUrl = true;
							this.payData = res.data;
							this.qr = new QRCode('qrcode', {
								width: 130,
								height: 130, // 高度
								text: this.payData.qrCode, // 二维码内容
								colorDark: '#000000',
								colorLight: '#ffffff'
							});
						}
					} else {
						this.$message.error(res.message);
					}
				});
			},
			// 编辑操作
			handleEdit(index, row) {

				let data = {
					companyId: row.companyId,
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
				});
				this.addVisible = true;
				this.idx = index;
				this.addButton = false;
				this.companyDis = true;
				this.updateButton = true;
				// 获取详情
				fetchUserById(row.id).then(res => {
					this.form = res.data;
					this.form.companyId = res.data.companyName;
					this.form.addUser = '1';
					this.form.subAgent = JSON.parse(localStorage.getItem('subAgent').toLowerCase());
					fetchCompanyByAgentId(this.form).then(res => {
						this.companys = res.data;
						this.dialogLoading = true;
					});
				});
			},

			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getData();
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			closeDialog(formName) {
				this.companyDis = false;
				this.weixinUrl = false;
				if (this.batchAdd) {
					this.$refs.qrcode.innerHTML = '';
				}
				this.batchVisible2 = false;
				(this.form = {
					account: '',
					name: ''
				}),
				this.resetForm(formName);
			},
			closeDialog3() {
				this.companyDis = false;
				this.weixinUrl = false;
				if (this.batchAdd) {
					if (this.$refs.qrcode) {
						this.$refs.qrcode.innerHTML = '';
					}
				}
				this.addVisible = false;
				this.form.account = '';
				this.form.name = '';
				this.form.cardId = '';
				this.form.departmentId = '';
				this.form.cardsId = '';
				this.form.invalidateDateIncrease = '';
				this.form.invalidateDayIncrease = '';
				this.form.addUser = '2';
			},
			closeDialog2() {
				this.customDataDialog = false;
			},
			closeUpload() {
				this.$refs.upload.clearFiles();
			},
			submitUpload() {
				this.form.type = '';
				if (this.$refs.upload.uploadFiles[0]) {
					if (this.form.invalidateDateIncrease == '' && this.form.invalidateDayIncrease == '') {
						this.$message.error(`请选择有效期`);
					}
					if (this.form.cardsId === '') {
						this.$message.error(`请选择品牌`);
					}
					this.loading = true;
					this.form.currentUserId = localStorage.getItem('user');
					if (!this.isAdmin) {
						this.form.whitening = false;
					}
					this.$refs.upload.submit();
				} else {
					this.$message.error('请选择上传文件');
				}
			},
			handleRemove(file, fileList) {
				console.log(file, fileList, 'handleRemove');
			},
			handlePreview(file) {
				console.log(file, 'handlePreview');
			},
			uploadSuccess(success) {
				setTimeout(() => {
					this.loading = false;
					this.$refs.upload.clearFiles();
					this.upload = false;
				}, 2000);
				if (success.code == 200) {
					this.$message.success('上传成功');
				} else {
					this.open(success.message);
					// this.$message.error(success.message)
				}

				this.handleSearch();
			},
			beforeAvatarUpload(file) {
				console.log(file, 'filefilefile');
				// this.$refs.upload.submit();
			},
			open(message) {
				this.$alert(message, '导入失败', {
					confirmButtonText: '确定'
				});
			},

			batchUpdate() {
				// if (this.form.invalidateDateIncrease === 0) {
				// 	this.$message.error(`请选择有效期`);
				// }
				if (this.form2.type == '会员有效期') {
					this.form2.type = 1;
				}
				let str = '';
				this.delList = this.delList.concat(this.multipleSelection);
				for (let i = 0; i < this.multipleSelection.length; i++) {
					str += this.multipleSelection[i].id + ',';
				}

				let param = {
					ids: str,
					invalidDate: this.form2.invalidateDateIncrease,
					invalidDay: this.form2.invalidateDayIncrease,
					currentUserId: localStorage.getItem('user'),
					type: this.form2.type,
					cardsId: this.form2.cardsId,
					enable: this.form2.enable2,
					phones: this.form2.phones
				};
				batchUpdate(param).then(res => {
					// this.$set(this.tableData, this.idx+1, this.form);
					if (res.code == 200) {
						if (res.customData.length > 0) {
							this.$message.error(`批量设置有效期失败`);
							this.customData = res.customData;
							this.customDataDialog = true;
						} else {
							this.batchVisible = false;
							this.$message.success(`批量设置有效期成功`);
							this.getData();
						}
					} else {
						this.$message.error(res.message);
					}

					this.loading = false;
				});
			},
			handleDownload() {
				if (this.isAgent === true && this.query.companyId === '') {
					this.$message.error('选择查询条件');
					return;
				}
				let data = {
					companyId: this.query.companyId,
					departmentId: this.query.departmentId,
					currentUserId: localStorage.getItem('user')
				};
				this.loading = true;
				downloadUsers(data).then(res => {
					console.log(res, 'ssss');
					const blob = new Blob([res], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
					});
					let downloadElement = document.createElement('a');
					let href = window.URL.createObjectURL(blob);
					downloadElement.href = href;
					let fileName = this.companyName + '员工' + '.xlsx';
					downloadElement.download = fileName;
					document.body.appendChild(downloadElement);
					downloadElement.click();
					document.body.removeChild(downloadElement);
					window.URL.revokeObjectURL(href);
					this.loading = false;
				});
			},
			toDateTimeStr(nS) {
				let date = new Date(parseInt(nS));
				let YY = date.getFullYear() + '-';
				let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
				let DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
				let hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
				let mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
				let ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
				return YY + MM + DD;
			},
			typeChange(e) {
				this.$refs.qrcode.innerHTML = '';
				this.form2.pay = e;
				this.weixinUrl = false;
			},
			formtypeChange(e) {
				this.form.addUser = e;
				this.$refs['form'].resetFields();
			},
			cardsIdChange(e) {
				this.form.cardsId = e;
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.down {
		border: 1px solid #dcdfe6;
		background: #4f7afd;
		color: #ffffff;
		transition: 0.1s;
		font-weight: 500;
		padding: 7px 16px;
		font-size: 12px;
		border-radius: 4px;
		margin-left: 10px;
		display: inline-block;
	}

	.handle-select {
		width: 120px;
	}

	.handle-input {
		width: 300px;
		display: inline-block;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #f56c6c;
	}

	.mr10 {
		margin-right: 10px;
	}

	.table-td-thumb {
		display: block;
		margin: auto;
		width: 40px;
		height: 40px;
	}
</style>
<style>
	.user-box .el-upload {
		display: flex !important;
		flex-direction: column !important;
		align-items: center !important;
		justify-content: center !important;
		width: 100% !important;
	}

	.user-box .btn {
		background-color: #4f7afd;
		margin-top: 20px;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		border: none;
		margin-left: 80%;
	}

	/* .el-select-dropdown__wrap {
	max-height: 350px;
} */

	.is-dark {
		max-width: 174px !important;
	}

	.upload-user-box .user-list {
		display: flex;
		flex-direction: row;
		margin-top: 20px;
	}

	.user-list span {
		display: inline-block;
		width: 60px;
		line-height: 40px;
	}
</style>