<template>
	<div class="contacts-box">
		<!-- <div class="header-box">
			<div class="nav">
				<el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane label="实体卡" name="physicalCard">实体卡</el-tab-pane>
					<el-tab-pane label="SIP小号" name="SIP">SIP小号</el-tab-pane>
					<el-tab-pane label="特服号" name="specialServiceNumber">特服号</el-tab-pane>
				</el-tabs>
			</div>
			<div class="title">
				<i class="el-icon-office-building"></i>
				销售数据在线看板
			</div>
			<div class="time">
				<el-date-picker v-model="value1" type="daterange" range-separator="——" start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
			</div>
		</div> -->
		<div class="inventory-box">
			<el-row>
				<el-col :span="16">
					<div class="content-left">
						<div>
							<div class="header-tit">
								<div>
									<span>.</span>
									<span>号码状态统计</span>
								</div>
								<div>
									<el-date-picker v-model="value1" type="daterange" range-separator="——"
										start-placeholder="开始日期" end-placeholder="结束日期">
									</el-date-picker>
									<el-button size="small" type="primary" :class="[theme]" @click="">导出表格</el-button>
								</div>
							</div>
							<div class="list-box">
								<ul>
									<li>
										<div>在网号码数量</div>
										<span>5698</span>
									</li>
									<li>
										<div>销售号码数量</div>
										<span>5698</span>
									</li>
									<li>
										<div>已激活号码数量</div>
										<span>5698</span>
									</li>
									<li>
										<div>品牌分类</div>
										<span>5698</span>
									</li>
								</ul>
							</div>
						</div>
						<div>
							<div class="header-tit">
								<div>
									<span>.</span>
									<span>客户数据统计</span>
								</div>
								<div>
									<el-date-picker v-model="value1" type="daterange" range-separator="——"
										start-placeholder="开始日期" end-placeholder="结束日期">
									</el-date-picker>
									<el-button size="small" type="primary" :class="[theme]" @click="">导出表格</el-button>
								</div>
							</div>
							<div class="list-box">
								<ul>
									<li>
										<div>投诉数量</div>
										<span>5698</span>
									</li>
									<li>
										<div>新客户数量</div>
										<span>5698</span>
									</li>
									<li>
										<div>数量</div>
										<span>5698</span>
									</li>
									<li>
										<div>品牌分类</div>
										<span>5698</span>
									</li>
									<li>
										<div>投诉数量</div>
										<span>5698</span>
									</li>
									<li>
										<div>新客户数量</div>
										<span>5698</span>
									</li>
									<li>
										<div>数量</div>
										<span>5698</span>
									</li>
									<li>
										<div>品牌分类</div>
										<span>5698</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="content-right">
						<div class="header-tit">
							<div>
								<span>.</span>
								<span>销售进度统计</span>
							</div>
							<div>
								<i class="el-icon-refresh-righ"></i>
								<span>截止当前时间</span>
							</div>
						</div>
						<div class="sales-statistics-box">
							<div>
								<span>4111111.17</span>
								<span>元</span>
							</div>
							<div>
								<div>
									<p>同比</p><span>200%</span>
								</div>
								<div>
									<p>同期</p><span>200000元</span>
								</div>
							</div>
							<div class="sales-statistics-tit">
								<span>销售回款进度</span>
								<span>>></span>
							</div>
							<div>
								<div>
									<span>小明</span>
									<el-progress :percentage="20" :color="gradientColor"></el-progress>
								</div>
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="16">
					<div class="content-left">
						<div class="header-tit">
							<div>
								<span>.</span>
								<span>公司产品销售趋势</span>
							</div>
							<div>
								<el-date-picker v-model="value1" type="daterange" range-separator="——"
									start-placeholder="开始日期" end-placeholder="结束日期">
								</el-date-picker>
								<el-button size="small" type="primary" :class="[theme]" @click="">导出表格</el-button>
							</div>
						</div>
						<el-card shadow="hover">
							<div id="columnMain" ref="columnMain" style="height: 450px;width: 100%;"></div>
						</el-card>

					</div>
				</el-col>
				<el-col :span="6">
					<div class="content-right">
						<div class="header-tit">
							<div>
								<span>.</span>
								<span>公司产品销售趋势</span>
							</div>
							<div>
								<el-date-picker v-model="value1" type="daterange" range-separator="——"
									start-placeholder="开始日期" end-placeholder="结束日期">
								</el-date-picker>
								<el-button size="small" type="primary" :class="[theme]" @click="">导出表格</el-button>
							</div>
						</div>
						<el-card shadow="hover">
							<div id="ring" ref="ring" style="height: 250px;width: 100%;"></div>
						</el-card>
					</div>
				</el-col>
			</el-row>

		</div>
	</div>
</template>

<script>
	import { fetchAgent, updateAfterSale } from '../api/inventory';
	import {
		getMonthInfo,
	} from '../api/sumIndex.js';
	export default {
		name: 'SJTXinventory',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				theme3: localStorage.getItem('theme3'),
				activeName: 'physicalCard',
				gradientColor: 'linear-gradient(to right, #ff7f50, #ffa500)',
				myChart: null,
				myChart2: null
			};
		},
		beforeUnmount() {
			if (!this.myChart) {
				return;
			} else {
				this.myChart.dispose();
				this.myChart = null;
			}
			if (!this.myChart2) {
				return;
			} else {
				this.myChart2.dispose();
				this.myChart2 = null;
			}
		},
		created() {
			this.role = localStorage.getItem('ms_role');
			this.getCanvas();
			this.getCanvas2();
		},
		methods: {
			getCanvas() {
				let echarts = require('echarts');
				let data = {
					currentUserId: localStorage.getItem('user')
				};
				getMonthInfo(data).then(res => {
					localStorage.setItem('echartsData', JSON.stringify(res.data));
					let data = res.data;
					let option = {
						legend: {
							tooltip: {
								show: true,
								color: 'red'
							},
							data: [{
									name: '拨打次数',
									icon: 'circle'
								},
								{
									name: '接通次数',
									icon: 'circle'
								},
								{
									name: '通话分钟数',
									icon: 'circle'
								}
							]
						},
						tooltip: {
							padding: 5,
							textStyle: {
								color: '#000' //---提示框内容样式
							}
						},
						grid: {
							top: 50, //---相对位置，top\bottom\left\right
							bottom: 20,
							left: 20,
							right: 20,
							containLabel: true //---grid 区域是否包含坐标轴的刻度标签
						},
						xAxis: {
							data: data.date //内容
						},
						yAxis: {
							axisLine: {
								show: true //---是否显示
							}
						},
						series: [{
								name: data.datasets[0].label,
								type: 'bar', //---类型
								legendHoverLink: true, //---是否启用图例 hover 时的联动高亮

								itemStyle: {
									//---图形形状
									color: '#5ea4ec',
									borderRadius: [10, 10, 0, 0]
								},
								barWidth: '10', //---柱形宽度
								barCategoryGap: '10',
								barGap: this.winWidth, //---柱形间距
								data: data.datasets[0].data
							},
							{
								name: data.datasets[1].label,
								type: 'bar',
								legendHoverLink: true,

								itemStyle: {
									color: '#22d422',
									borderRadius: [10, 10, 0, 0]
								},
								barWidth: '10',
								barCategoryGap: '10',
								barGap: this.winWidth,
								data: data.datasets[1].data
							},
							{
								name: data.datasets[2].label,
								type: 'bar',
								legendHoverLink: true,
								itemStyle: {
									color: '#E6A23C',
									borderRadius: [10, 10, 0, 0]
								},
								barWidth: '10',
								barCategoryGap: '10',
								barGap: this.winWidth,
								data: data.datasets[2].data
							}
						]
					};
					this.myChart = echarts.init(this.$refs.columnMain);
					this.myChart.setOption(option);
				});
			},
			getCanvas2() {
				let data = {
					currentUserId: localStorage.getItem('user')
				};
				getMonthInfo(data).then(res => {
					localStorage.setItem('echartsData2', JSON.stringify(res.data));
					let echarts = require('echarts');
					let aa = {
						0: 10,
						1: 20
					}
					let oper_y = Object.values(aa);
					let oper_x = ['客户分类', '号码数量'];
					let chartdata = [];
					oper_x.forEach((one, index) => {
						let item = {};
						item.name = one;
						item.value = oper_y[index];
						chartdata.push(item);
					});
					console.log(this.$refs, 'this.$refs')
					this.myChart2 = echarts.init(this.$refs.ring);
					let option = {
						legend: {
							orient: 'horizontal',
							x: 'center', //可设定图例在左、右、居中
							y: 'bottom',
							data: ['意向强', '意向一般']
						},
						tooltip: {
							trigger: 'item',
							show: true,
							formatter: '{c}%'
						},
						series: [{
							type: 'pie',
							center: ['50%', '40%'],
							radius: ['35%', '55%'], //环形
							data: chartdata,
							minAngle: 10
						}]
					};
					this.myChart2.setOption(option);
				});
			},
		}
	};
</script>

<style scoped>
	.contacts-box {
		.header-box {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		.inventory-box {

			.content-left {
				background-color: #e0eafe;
				border-radius: 10px;
				padding: 11px 22px;

				.header-tit {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					color: #353535;
					font-size: 24px;
				}
			}

			.content-right {}


		}
	}
</style>