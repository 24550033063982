<template>
	<div>
		<div class="container">
			<div>
				<el-select size="small" v-if="isAdmin" v-model="query.agentId" filterable clearable placeholder="代理商"
					class="handle-select mr10" style="width: 240px" @change="getpackageTemplateList">
					<el-option v-for="item in agents" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-button size="small" type="primary" :class="[theme]" class="m_l_10" @click="addTemplate"
					v-if="isAdmin">添加套餐</el-button>
				<el-button size="small" type="primary" :class="[theme]" class="m_l_10" @click="getagentPackageList"
					v-if="query.agentId||isAgent">导入套餐</el-button>
			</div>
			<el-table v-loading="loading" :data="tableData" border class="table m_t_20"
				header-cell-class-name="table-header" :row-style="{ height: '20px' }"
				:header-cell-style="{ 'text-align': 'center' }" :cell-style="{ padding: '6px' }"
				:row-class-name="tableRowClassName" :key="Math.random()">
				>
				<el-table-column prop="packageName" label="套餐类型" align="center"></el-table-column>
				<el-table-column prop="content" label="普通包" align="center">
					<template #default="scope">
						<el-tag v-for="item in scope.row.content" :key="item"
							style="display: flex;flex-direction: column;margin:6px 0;">{{item.minutes}}分钟{{item.price}}元</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="outside" label="加量包" align="center">
					<template #default="scope">
						<el-tag v-for="item in scope.row.outside" :key="item"
							style="display: flex;flex-direction: column;margin: 6px 0;">{{item.minutes}}分钟{{item.price}}元</el-tag>
					</template>
				</el-table-column>
			</el-table>
			<el-dialog title="添加套餐" v-model="sipTemplateVisible" width="550px" @close="closeDialog">
				<el-form label-width="120px" :model="sipTemplateForm" ref="sipTemplateForm">
					<el-form-item prop="packageName" label="套餐类型">
						<el-input size="small" v-model="sipTemplateForm.packageName" placeholder="示例:教育"
							@change="upPackageName"></el-input>
					</el-form-item>
					<el-form-item v-for="(item, index) in sipTemplateForm.menuList" label="套餐" :key="item.key"
						:prop="'menuList.' + index + '.value'">
						<el-input size="small" v-model="item.value1" style="width: 125px;margin-right: 10px;"
							placeholder="请输入套餐时长"></el-input>
						<el-input size="small" v-model="item.value2" style="width: 125px;margin-right: 10px;"
							placeholder="请输入套餐价格"></el-input>
						<el-button size="small" type="danger" @click.prevent="removeDomain(item)">删除</el-button>
						<el-button size="small" type="primary" :class="[theme]"
							@click.prevent="addDomain">添加</el-button>
					</el-form-item>
					<el-form-item v-for="(item, index) in sipTemplateForm.outsideList" label="加量包" :key="item.key"
						:prop="'outsideList.' + index + '.value'">
						<el-input size="small" v-model="item.value1" style="width: 125px;margin-right: 10px;"
							placeholder="请输入套餐时长"></el-input>
						<el-input size="small" v-model="item.value2" style="width: 125px;margin-right: 10px;"
							placeholder="请输入套餐价格">
						</el-input>
						<el-button size="small" type="danger" @click.prevent="removeDomain2(item)">删除</el-button>
						<el-button size="small" type="primary" :class="[theme]"
							@click.prevent="addDomain2">添加</el-button>
					</el-form-item>
					<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
						<el-button size="small" @click="closeDialog">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]"
							@click="getPackageTemplate">确定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>


			<el-dialog title="导入套餐" v-model="sipTemplateUVisible" width="550px" @close="closeDialog2">
				<el-form label-width="120px" :model="sipTemplateUForm" ref="sipTemplateUForm">
					<el-form-item prop="packageName" label="套餐类型">
						<el-select size="small" v-model="sipTemplateUForm.packageName" filterable clearable
							placeholder="请选择套餐类型" class="handle-select mr10" style="width: 240px"
							@change="changPackageName">
							<el-option v-for="item in packageNameList" :key="item" :label="item"
								:value="item"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-for="(item, index) in sipTemplateUForm.menuList" label="套餐" :key="item.key"
						:prop="'menuList.' + index + '.value'">
						<el-input size="small" v-model="item.minutes" style="width: 125px;margin-right: 10px;"
							placeholder="请输入套餐时长" disabled></el-input>
						<el-input size="small" v-model="item.price" style="width: 125px;margin-right: 10px;"
							placeholder="请输入套餐价格" @change="upMenuListPrice"></el-input>
					</el-form-item>
					<el-form-item v-for="(item, index) in sipTemplateUForm.outsideList" label="加量包" :key="item.key"
						:prop="'outsideList.' + index + '.value'">
						<el-input size="small" v-model="item.minutes" style="width: 125px;margin-right: 10px;"
							placeholder="请输入套餐时长" disabled></el-input>
						<el-input size="small" v-model="item.price" style="width: 125px;margin-right: 10px;"
							placeholder="请输入套餐价格" @change="upOutsideListPrice"></el-input>
					</el-form-item>
					<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
						<el-button size="small" @click="closeDialog2">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]"
							@click="getagentAddPackage">确定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import { packageTemplate, packageTemplateList, agentPackageList, agentAddPackage } from '../api/setMeal.js';
	import { fetchAgent } from '../api/agentIndex';
	export default {
		name: 'sipTemplate',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				tableData: [],
				sipTemplateVisible: false,
				sipTemplateForm: {
					packageName: '',
					menuList: [{
						value1: '',
						value2: '',
					}],
					content: [],
					outside: [],
					outsideList: [{
						value1: '',
						value2: '',
					}],
				},
				isAdmin: false,
				isAgent: false,
				role: '',
				agents: [],
				query: {
					agentId: ''
				},
				sipTemplateUVisible: false,
				sipTemplateUForm: {
					menuList: [],
					content: [],
					outside: [],
					outsideList: [],
				},
				packageNameList: [],
				sipTemplateUData: []
			};
		},
		created() {
			this.role = localStorage.getItem('ms_role');
			if (this.role == 'admin') {
				this.isAdmin = true;
			}
			if (this.role == 'agent') {
				this.isAgent = true;
			}
			this.getfetchAgent();

		},
		methods: {
			getfetchAgent() {
				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
					this.getpackageTemplateList();
				});
			},
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			getpackageTemplateList() {
				let data = {
					userId: localStorage.getItem('user'),
					agentId: this.query.agentId
				};
				packageTemplateList(data).then(res => {
					this.loading = false;
					if (res.code == 200) {
						this.tableData = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			addTemplate() {
				this.sipTemplateVisible = true;
				this.sipTemplateForm.packageName = '';
				this.sipTemplateForm.content = [];
				this.sipTemplateForm.outside = [];
				this.sipTemplateForm.menuList = [{
					value1: '',
					value2: '',
				}];
				this.sipTemplateForm.outsideList = [{
					value1: '',
					value2: '',
				}];
			},
			addDomain() {
				this.sipTemplateForm.menuList.push({
					value1: '',
					value2: '',
				});

			},
			addDomain2() {
				this.sipTemplateForm.outsideList.push({
					value1: '',
					value2: '',
				});
			},
			removeDomain(item) {
				if (this.sipTemplateForm.menuList.length > 1) {
					var index = this.sipTemplateForm.menuList.indexOf(item)
					if (index !== -1) {
						this.sipTemplateForm.menuList.splice(index, 1)
						this.sipTemplateForm.content.splice(index, 1)
					}
				}
			},
			removeDomain2(item) {
				if (this.sipTemplateForm.outsideList.length > 1) {
					var index = this.sipTemplateForm.outsideList.indexOf(item)
					if (index !== -1) {
						this.sipTemplateForm.outsideList.splice(index, 1)
						this.sipTemplateForm.outside.splice(index, 1)
					}
				}
			},
			upPackageName() {
				console.log(this.sipTemplateForm, 'ddlklsipTemplateForm')
				// this.sipTemplateForm.menu = '';
				// this.sipTemplateForm.outside = '';
			},
			getPackageTemplate() {
				this.sipTemplateForm.outside = [];
				this.sipTemplateForm.content = [];
				this.sipTemplateForm.menuList.forEach(i => {
					this.sipTemplateForm.content.push(i.value1 + '-' + i.value2)
				})
				this.sipTemplateForm.outsideList.forEach(i => {
					this.sipTemplateForm.outside.push(i.value1 + '-' + i.value2)
				})
				let data = {
					userId: localStorage.getItem('user'),
					packageName: this.sipTemplateForm.packageName,
					content: this.sipTemplateForm.content.join(),
					outside: this.sipTemplateForm.outside.join(),
				};
				console.log(data, 'dksjk')
				packageTemplate(data).then(res => {
					if (res.code == 200) {
						this.$message.success(res.message);
						this.getpackageTemplateList();
						this.sipTemplateVisible = false;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			closeDialog() {
				this.sipTemplateVisible = false;
				this.sipTemplateForm.packageName = '';
				this.sipTemplateForm.content = [];
				this.sipTemplateForm.outside = [];
				this.sipTemplateForm.menuList = [{
					value1: '',
					value2: '',
				}];
				this.sipTemplateForm.outsideList.push({
					value1: '',
					value2: '',
				});
			},
			getagentPackageList() {
				this.sipTemplateUVisible = true;
				this.packageNameList = []
				agentPackageList().then(res => {
					if (res.code == 200) {
						res.data.forEach(i => {
							this.packageNameList.push(i.packageName)
						})
						this.sipTemplateUData = res.data
					} else {
						this.$message.error(res.message);
					}
				});
			},
			changPackageName(e) {
				this.sipTemplateUData.forEach(i => {
					if (i.packageName == e) {
						this.sipTemplateUForm.menuList = i.content;
						this.sipTemplateUForm.outsideList = i.outside;
						this.sipTemplateUForm.id = i.id;
					}
				})
			},
			getagentAddPackage() {
				this.sipTemplateUForm.content = [];
				this.sipTemplateUForm.outside = [];
				this.sipTemplateUForm.menuList.forEach(i => {
					this.sipTemplateUForm.content.push(i.minutes + '-' + i.price)
				})
				this.sipTemplateUForm.outsideList.forEach(i => {
					this.sipTemplateUForm.outside.push(i.minutes + '-' + i.price)
				})
				let data = {
					userId: localStorage.getItem('user'),
					id: this.sipTemplateUForm.id,
					agentId: this.query.agentId,
					content: this.sipTemplateUForm.content.join(),
					outside: this.sipTemplateUForm.outside.join(),
				}
				agentAddPackage(data).then(res => {
					if (res.code == 200) {
						this.sipTemplateUVisible = false;
					} else {
						this.$message.error(res.message);

					}
				});
			},
			upMenuListPrice(e) {
				console.log(e, 'sslksljml')

			},
			upOutsideListPrice(e) {

			},
			closeDialog2() {
				this.sipTemplateUVisible = false;
				this.sipTemplateUForm.menuList = [];
				this.sipTemplateUForm.packageName = '';
				this.sipTemplateUForm.content = [];
				this.sipTemplateUForm.outside = [];
				this.sipTemplateUForm.outsideList = [];
				this.packageNameList = [];
				this.sipTemplateUData = [];
			}
		}
	};
</script>

<style scoped>
	.handle-select {
		width: 120px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.mr10 {
		width: 200px;
		margin-right: 10px;
	}

	.grid-content {
		display: flex;
		align-items: center;
		height: 100px;
	}

	/deep/ .el-col-16 {
		flex: 0 0 59.9%;
	}

	.grid-cont-right {
		flex: 1;
		text-align: center;
		font-size: 20px;
		font-weight: bold;
		color: #999;
	}

	.grid-num {
		font-size: 30px;
		font-weight: bold;
	}

	.grid-con-icon {
		font-size: 50px;
		width: 100px;
		height: 100px;
		text-align: center;
		line-height: 100px;
		color: #fff;
	}

	.grid-con-1 .grid-con-icon {
		background: linear-gradient(#ffb199, #ff0844);
	}

	.new {
		color: #b5b4b4;
		font-size: 12px;
	}

	.grid-con-1 .grid-num {
		color: #4f7afd;
	}

	.grid-con-2 .grid-con-icon {
		background: linear-gradient(#48c6ef, #6f86d6);
	}

	.grid-con-2 .grid-num {
		color: #4f7afd;
	}

	.grid-con-3 .grid-con-icon {
		background: linear-gradient(#e5b2ca, #7028e4);
	}

	.grid-con-3 .grid-num {
		color: #4f7afd;
	}

	.grid-con-4 .grid-con-icon {
		background: linear-gradient(#00c6fb, #005bea);
	}

	.grid-con-4 .grid-num {
		color: #4f7afd;
	}

	.grid-con-5 .grid-con-icon {
		background: linear-gradient(#89f7fe, #66a6ff);
	}

	.grid-con-5 .grid-num {
		color: #4f7afd;
	}

	.down {
		border: 1px solid #dcdfe6;
		background: #4f7afd;
		color: #ffffff;
		transition: 0.1s;
		font-weight: 500;
		padding: 8px 16px;
		font-size: 12px;
		border-radius: 4px;
		margin-left: 10px;

		display: inline-block;
	}
</style>
<style>
	.validate .el-upload {
		width: 100% !important;
		display: flex !important;
		flex-direction: column !important;
		align-items: center !important;
		justify-content: center !important;
	}

	.validate .btn {
		background-color: #4f7afd;
		margin-top: 20px;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		border: none;
		margin-left: 80%;
	}
</style>