import request from '../utils/request';
import qs from "qs";

export const getReportNumberList = param => {
	return request.post('/api/blackList/ReportNumberList', qs.stringify(param))
};


export const deleteReportNumber = param => {
	return request.post('/api/blackList/deleteReportNumber', qs.stringify(param))
};

export const getSnailsRecord = param => {
	return request.post('/api//numberPool/snailsRecord', qs.stringify(param))
};

export const findSnailsRecord = param => {
	return request.post('/api/numberPool/findSnailsRecord', qs.stringify(param))
};


export const getData = path => {
	return request.get('/api/invoke/getData?path=' + path, {
		responseType: 'blob'
	})
};
export const downloadReportNumber = param => {
	return request.post('/api/blackList/downloadReportNumber', qs.stringify(param), {
		responseType: 'blob'
	})
};
export const downloadSnails = param => {
	return request.post('/api/numberPool/downloadSnails', qs.stringify(param), {
		responseType: 'blob'
	})
};
export const downloadDownRecord = param => {
	return request.post('/api/numberPool/downloadDownRecord', qs.stringify(param), {
		responseType: 'blob'
	})
};


export const findDownRecord = param => {
	return request.post('/api/numberPool/findDownRecord', qs.stringify(param))
};
export const downRecordDelete = param => {
	return request.post('/api/numberPool/downRecordDelete', qs.stringify(param))
};
export const getupdateDownRecord = param => {
	return request.post('/api/numberPool/updateDownRecord', qs.stringify(param))
};
export const numberPoolGmRecords = param => {
	return request.post('/api/numberPool/getGMRecordCheck', qs.stringify(param))
};
export const getGMAbnormalNumber = param => {
	return request.post('/api/numberPool/getGMAbnormalNumber', qs.stringify(param))
};

export const GMStop = param => {
	return request.post('/api/numberPool/GMStop', qs.stringify(param))
};
export const getWorkOrderList = param => {
	return request.post('/api/blackList/workOrderList', qs.stringify(param))
};
export const getVestingPerson = param => {
	return request.post('/api/blackList/vestingPerson', qs.stringify(param))
};
export const updateWorkOrder = param => {
	return request.post('/api/blackList/updateWorkOrder', qs.stringify(param))
};
export const verifyPassWord = param => {
	return request.post('/api/blackList/verifyPassWord', qs.stringify(param))
};
export const downloadWorkOrder = param => {
	return request.post('/api/blackList/downloadWorkOrder', qs.stringify(param), {
		responseType: 'blob'
	})
};
export const uploadWorkOrder = param => {
	return request.post('/api/blackList/uploadWorkOrder', qs.stringify(param), {
		responseType: 'blob'
	})
};

export const batchDelete = param => {
	return request.post('/api/blackList/deleteWorkOrder', qs.stringify(param))
};
export const getverifyList = param => {
	return request.post('/api/blackList/verifyList', qs.stringify(param))
};
export const getGMRecordList = param => {
	return request.post('/api/GMRecord/list', qs.stringify(param))
};
export const vestingCompany = param => {
	return request.post('/api/blackList/vestingCompany', qs.stringify(param))
};
export const getGMRecordAll = data => {
	return request.get('/api/GMRecord/all')
};
export const snailList = param => {
	return request.post('/api/numberPool/snailList', qs.stringify(param))
};

export const snailManualAddition = param => {
	return request.post('/api/numberPool/snailManualAddition', qs.stringify(param))
};
export const snailUpdate = param => {
	return request.post('/api/numberPool/snailUpdate', qs.stringify(param))
};

export const snailDelete = param => {
	return request.post('/api/numberPool/snailDelete', qs.stringify(param))
};
export const downloadSnailsprestore = param => {
	return request.post('/api/numberPool/downloadSnail', qs.stringify(param), {
		responseType: 'blob'
	})
};

export const getReportAgent = data => {
	return request.get('/api/blackList/ReportAgent')
};
export const getReportCompany = data => {
	return request.get('/api/blackList/ReportCompany?agent=' + data.agent)
};