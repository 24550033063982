<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" v-if="isAdmin" @change="agentChange" v-model="query.agentId" filterable
					clearable placeholder="代理商" class="handle-select mr10" style="width: 240px;">
					<el-option v-for="item in agents" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" style="width: 240px" v-if="isAgent" v-model="query.companyId"
					@change="companyChange" filterable clearable placeholder="企业" class="handle-select mr10">
					<el-option v-for="item in companys" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-button size="mini" type="primary" :class="[theme]" icon="el-icon-search"
					@click="handleSearch">搜索</el-button>
				<el-button size="mini" type="primary" :class="[theme]" icon="el-icon-search" @click="enterpriseSetMenu"
					v-if="pageTotal==0&&query.companyId&&(isAdmin||isAgent)">分配套餐</el-button>
			</div>

			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				@selection-change="handleSelectionChange" :header-cell-style="{ 'text-align': 'center' }"
				:row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }" :row-class-name="tableRowClassName">
				<el-table-column type="selection" width="55" align="center"></el-table-column>
				<el-table-column prop="companyName" width="340" label="公司" align="center"></el-table-column>
				<el-table-column prop="packageName" label="套餐类型" align="center"></el-table-column>
				<el-table-column prop="content" label="普通包" align="center">
					<template #default="scope">
						<el-tag v-for="item in scope.row.content"
							style="display: flex;flex-direction: column;margin:6px 0;">{{item.minutes}}分钟{{item.price}}元</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="outside" label="加量包" align="center">
					<template #default="scope">
						<el-tag v-for="item in scope.row.outside"
							style="display: flex;flex-direction: column;margin: 6px 0;">{{item.minutes}}分钟{{item.price}}元</el-tag>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[30, 50, 100, 500]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>
			<el-dialog title="分配套餐" v-model="sipAllocationVisible" width="550px" @close="closeDialog">
				<el-form label-width="120px" :model="form" ref="form">
					<el-form-item prop="packageName" label="套餐类型">
						<el-select size="small" v-model="form.packageName" filterable clearable placeholder="请选择套餐类型"
							class="handle-select mr10" style="width: 260px" @change="changPackageName">
							<el-option v-for="item in packageNameList" :key="item" :label="item"
								:value="item"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-for="(item, index) in form.menuList" label="套餐" :key="item.key"
						:prop="'menuList.' + index + '.value'">
						<el-input size="small" v-model="item.minutes" style="width: 125px;margin-right: 10px;"
							placeholder="请输入套餐时长" disabled></el-input>
						<el-input size="small" v-model="item.price" style="width: 125px;margin-right: 10px;"
							placeholder="请输入套餐价格" disabled></el-input>
					</el-form-item>
					<el-form-item v-for="(item, index) in form.outsideList" label="加量包" :key="item.key"
						:prop="'outsideList.' + index + '.value'">
						<el-input size="small" v-model="item.minutes" style="width: 125px;margin-right: 10px;"
							placeholder="请输入套餐时长" disabled></el-input>
						<el-input size="small" v-model="item.price" style="width: 125px;margin-right: 10px;"
							placeholder="请输入套餐价格" disabled></el-input>
					</el-form-item>
					<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
						<el-button size="small" @click="closeDialog">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]"
							@click="getCompanyPackageAllocation">确定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>

		</div>
	</div>
</template>

<script>
	import { fetchUserById, } from '../api/userIndex';
	import { fetchDepartment } from '../api/index.js';
	import { fetchAgent, fetchInitAgent } from '../api/agentIndex.js';
	import { fetchCompanyByAgentId, fetchDefaultCompanies } from '../api/companyIndex';
	import { agentCompanyPackageList, agentTemplate, companyPackageAllocation } from '../api/setMeal';
	export default {
		name: 'enterpriseAllocation',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					companyId: '',
					agentId: '',
					userId: localStorage.getItem('user'),
					pageIndex: 1,
					pageSize: 30,
				},
				agents: [],
				companys: [],
				departments: [],
				tableData: [],
				multipleSelection: [],
				isAdmin: false,
				isAgent: false,
				pageTotal: 0,
				loading: false,
				role: '',
				form: {},
				form2: {},
				sipAllocationVisible: false,
				packageNameList: []
			};
		},
		created() {
			this.role = localStorage.getItem('ms_role');
			this.init();
			if (this.role === 'admin') {
				this.isAdmin = true;
				this.isAgent = true;
			}
			if (this.role == 'agent') {
				this.isAgent = true;
			}
		},
		methods: {
			tableRowClassName({
				rowIndex
			}) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			init() {
				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
				});
				let data = {
					companyId: '',
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
				});
				this.getfetchDefaultCompanies();


			},
			getfetchDefaultCompanies() {
				this.query.companyId = '';
				let data = {
					currentUserId: localStorage.getItem('user'),
					subAgentId: this.query.subAgentId
				};
				fetchDefaultCompanies(data).then(res => {
					this.companys = res.data;
				});
			},
			getData() {
				let data = {
					userId: localStorage.getItem('user'),
					agentId: this.query.agentId,
					companyId: this.query.companyId,
					pageIndex: this.query.pageIndex,
					pageSize: this.query.pageSize,
				};
				agentCompanyPackageList(data).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			agentChange(agentId) {
				this.query.agentId = agentId;
				this.query.userId = '';
				this.query.companyId = '';
				if (agentId) {
					let data = {
						subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
					});
				}
			},
			companyChange(companyId) {
				this.query.companyId = companyId;
				this.query.userId = '';
				this.query.companyId = companyId;
				let data = {
					companyId: companyId,
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
					this.query.pageIndex = 1;
					this.getData();
				});
			},

			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.getData();
			},


			// 多选操作
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},

			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getData();
			},
			enterpriseSetMenu() {
				this.sipAllocationVisible = true;
				this.packageNameList = [];
				let data = {
					agentId: this.query.agentId,
					userId: localStorage.getItem('user')
				}
				agentTemplate(data).then(res => {
					if (res.code == 200) {
						res.data.forEach(i => {
							this.packageNameList.push(i.packageName)
						})
						this.form2 = res.data
					} else {
						this.$message.error(res.message);
					}
				});
			},
			changPackageName(e) {
				this.form2.forEach(i => {
					if (i.packageName == e) {
						this.form.menuList = i.content;
						this.form.outsideList = i.outside;
						this.form.id = i.id;
					}
				})
			},
			getCompanyPackageAllocation() {
				let data = {
					agentId: this.query.agentId,
					userId: localStorage.getItem('user'),
					id: this.form.id,
					companyId: this.query.companyId,
				}
				companyPackageAllocation(data).then(res => {
					if (res.code == 200) {
						this.sipAllocationVisible = false;
						this.form.packageName = '';
						this.$message.success(res.message);
					} else {
						this.$message.error(res.message);
					}
				});
			},
			closeDialog() {
				this.sipAllocationVisible = false;
				this.form.packageName = '';
			}

		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.down {
		border: 1px solid #dcdfe6;
		background: #4f7afd;
		color: #ffffff;
		transition: 0.1s;
		font-weight: 500;
		padding: 8px 16px;
		font-size: 12px;
		border-radius: 4px;
		margin-left: 10px;

		display: inline-block;
	}

	.handle-select {
		width: 120px;
	}

	.handle-input {
		width: 300px;
		display: inline-block;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #ff0000;
	}

	.mr10 {
		margin-right: 10px;
	}

	.grid-con-icon {
		font-size: 50px;
		width: 100px;
		height: 100px;
		text-align: center;
		line-height: 100px;
		color: #fff;
	}

	.grid-cont-right {
		flex: 1;
		text-align: center;
		font-size: 20px;
		font-weight: bold;
		color: #999;
	}

	.grid-content {
		display: flex;
		align-items: center;
		height: 100px;
	}
</style>
<style>
	.import-visible .el-upload {
		width: 100% !important;
		display: flex !important;
		flex-direction: column !important;
		align-items: center !important;
		justify-content: center !important;
	}
</style>