<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" v-model="query.agentId" filterable clearable placeholder="请选择代理"
					class="handle-select mr10">
					<el-option v-for="item in agents" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-model="query.enable" filterable clearable placeholder="请选择状态" class="mr10"
					style="width: 130px;">
					<el-option key="1" label="全部" :value="null"></el-option>
					<el-option key="2" label="启用" :value="true"></el-option>
					<el-option key="3" label="停用" :value="false"></el-option>
				</el-select>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
					@click="handleSearch">搜索</el-button>
				<el-button size="small" type="primary" :class="[theme]" class="handle-add mr100"
					@click="showForm">添加代理</el-button>
			</div>
			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				:row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }"
				:header-cell-style="{ 'text-align': 'center' }" :row-class-name="tableRowClassName">
				<el-table-column prop="id" width="240" label="代理id" align="center"></el-table-column>
				<el-table-column prop="name" width="280" label="企业名称" align="center"></el-table-column>
				<el-table-column prop="code" width="220" label="企业代码" align="center"></el-table-column>
				<el-table-column prop="ownerName" label="负责人" align="center"></el-table-column>
				<el-table-column prop="ownerPhone" label="负责人联系电话" align="center"></el-table-column>
				<el-table-column prop="validCallTime" label="可分配时长(月)" align="center"></el-table-column>
				<el-table-column prop="subAgent" label="子代理开启" align="center">
					<template #default="scope">
						<el-tag
							:type="scope.row.subAgent === true ? 'success' : scope.row.subAgent === false ? 'danger' : ''">
							{{ scope.row.subAgent === true ? '开启' : scope.row.subAgent === false ? '关闭' : '' }}
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="open" label="是否可用" align="center">
					<template #default="scope">
						<el-tag
							:type="scope.row.open === '是' ? 'success' : scope.row.open === '否' ? 'danger' : ''">{{ scope.row.open }}</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="interior" label="内部代理" align="center">
					<template #default="scope">
						<el-tag type="success" v-if="scope.row.interior == true">是</el-tag>
						<el-tag type="danger" v-if="scope.row.interior == false">否</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="340" align="center">
					<template #default="scope">
						<el-button size="small" :disabled="canEditCompany" type="text" icon="el-icon-view"
							@click="handleDetails(scope.row.id)">查看详情</el-button>
						<el-button size="small" type="text" icon="el-icon-edit"
							@click="handleEdit(scope.$index, scope.row.id)">编辑</el-button>
						<el-button size="small" type="text" icon="el-icon-delete" style="color: #f56c6c;"
							@click="handleDelete(scope.$index, scope.row.id)">删除</el-button>
						<el-button size="small" type="text" @click="handleAgentEdit(scope.row.id)">代理账号</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, prev, pager, next" :current-page="query.pageIndex"
					:page-size="query.pageSize" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
			</div>
		</div>

		<el-dialog title="代理编辑" v-model="addVisible" width="500px" @close="closeDialog('form')"
			:close-on-click-modal="false">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="企业名称" prop="name"><el-input size="small"
						v-model="form.name"></el-input></el-form-item>
				<el-form-item label="企业代码" prop="code"><el-input size="small"
						v-model="form.code"></el-input></el-form-item>
				<el-form-item label="负责人" prop="ownerName"><el-input size="small"
						v-model="form.ownerName"></el-input></el-form-item>
				<el-form-item label="联系方式" prop="ownerPhone"><el-input size="small"
						v-model="form.ownerPhone"></el-input></el-form-item>
				<el-form-item label="通话时长">
					<el-input-number size="small" style="width: 200px" v-model="form.validCallTime">月</el-input-number>
					(月)
				</el-form-item>
				<el-form-item label="开启子代理" prop="subAgent"><el-switch v-model="form.subAgent" active-color="#13ce66"
						inactive-color="#ff4949"></el-switch></el-form-item>
				<el-form-item label="内部代理" prop="interior"><el-switch v-model="form.interior" active-color="#13ce66"
						inactive-color="#ff4949"></el-switch></el-form-item>
				<el-form-item label="启用" prop="enable"><el-switch v-model="form.enable" active-color="#13ce66"
						inactive-color="#ff4949"></el-switch></el-form-item>
			</el-form>
			<span class="dialog-footer myRight">
				<el-button size="small" @click="closeDialog('form')">取消</el-button>
				<el-button size="small" v-if="addButton" type="primary" :class="[theme]"
					@click="addAgent('form')">确定</el-button>
				<el-button size="small" v-if="updateButton" type="primary" :class="[theme]"
					@click="updateAgent('form')">确定</el-button>
			</span>
		</el-dialog>
		<el-dialog v-model="detailsVisible" width="650px" :close-on-click-modal="false">
			<el-button size="small" type="primary" :class="[theme]" style="margin-bottom: 20px;"
				@click="getExportAgentSeats">导出</el-button>
			<el-table :data="tableData2" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				:header-cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange"
				:row-style="{ height: '20px', position: 'relative' }" :cell-style="{ padding: '6px' }"
				:row-class-name="tableRowClassName" :tree-props="{ children: 'children' }" @row-click="showData"
				style="width: 100%;">
				<el-table-column prop="label" label="品牌" align="center"></el-table-column>
				<el-table-column prop="value" label="数量" align="center"></el-table-column>
			</el-table>
		</el-dialog>
		<el-dialog title="代理账号" v-model="agentVisible" width="720px" @close="closeDialog2"
			:close-on-click-modal="false">
			<el-button size="small" type="primary" style="margin: 0 0 10px 0;" @click="handleAddAccount">添加</el-button>
			<el-table :data="tableData2" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				:row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }"
				:header-cell-style="{ 'text-align': 'center' }" :row-class-name="tableRowClassName">
				<el-table-column prop="account" label="账号" align="center"></el-table-column>
				<el-table-column prop="invalidateDate" label="到期时间" align="center"></el-table-column>
				<el-table-column prop="enable" label="是否启用状态" align="center" width="120">
					<template #default="scope">
						<el-switch v-model="scope.row.enable" active-color="#13ce66" inactive-color="#ff4949"
							@change="agentEditEnable(scope.row)"></el-switch>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="180" align="center">
					<template #default="scope">
						<el-button size="small" type="text" icon="el-icon-edit"
							@click="handleUpdateAccount(scope.row)">编辑</el-button>
						<el-button size="small" type="text" icon="el-icon-delete" class="red"
							@click="handleDeleteAccount(scope.row.accountId)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-dialog>

		<el-dialog title="编辑代理账号" v-model="updateAccountVisible" width="500px" @close="closeDialog3"
			:close-on-click-modal="false">
			<el-form :model="updateAccountForm" ref="updateAccountForm" label-width="100px">
				<el-form-item label="代理商账号" prop="account">
					<el-input size="small" v-model="updateAccountForm.account"
						style="width: 220px;margin-right: 10px;"></el-input><span>密码为账号后6位</span>
				</el-form-item>
				<el-form-item label="到期时间" prop="invalidateDate">
					<el-input size="small" v-model="updateAccountForm.invalidateDate"
						style="width: 220px;margin-right: 10px;"></el-input>
				</el-form-item>
				<el-form-item label="账号有效期" prop="month">
					<el-select size="small" v-model="updateAccountForm.month" filterable clearable placeholder="请设置有效期"
						style="width: 100%;">
						<el-option key="1" label="一个月" value="1"></el-option>
						<el-option key="2" label="两个月" value="2"></el-option>
						<el-option key="3" label="三个月" value="3"></el-option>
						<el-option key="6" label="六个月" value="6"></el-option>
						<el-option key="12" label="十二个月" value="12"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="closeDialog3">取消</el-button>
					<el-button size="small" type="primary" @click="getupdateAccount">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="添加代理账号" v-model="addAccountVisible" width="500px" @close="closeDialog4"
			:close-on-click-modal="false">
			<el-form :model="addAccountForm" ref="addAccountForm" label-width="100px">
				<el-form-item label="代理商账号" prop="addAccount">
					<el-input size="small" v-model="addAccountForm.ownerPhone"
						style="width: 220px;margin-right: 10px;"></el-input><span>密码为账号后6位</span>
				</el-form-item>
				<el-form-item label="联系人" prop="name">
					<el-input size="small" v-model="addAccountForm.name"
						style="width: 220px;margin-right: 10px;"></el-input>
				</el-form-item>

				<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="closeDialog4">取消</el-button>
					<el-button size="small" type="primary" @click="getAddAccount">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import {
		list,
		delAgent,
		addAgent,
		updateAgent,
		fetchAgentById,
		fetchAgent,
		agentAccount,
		stopAccount,
		updateAccount,
		addAccount,
		deleteAccount
	} from '../api/agentIndex.js';
	import { getExportAgentSeat, agentSeatCardType } from '../api/companyIndex.js';

	export default {
		name: 'agent',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					agentId: '',
					subAgent: false,
					enable: true,
					pageIndex: 1,
					pageSize: 30
				},
				tableData: [],
				agents: [],
				multipleSelection: [],
				delList: [],
				editVisible: false,
				addVisible: false,
				addButton: false,
				updateButton: false,
				pageTotal: 0,
				selectForm: {
					region: 0
				},
				detailsVisible: false,
				tableData2: [],
				form: {
					name: '',
					code: '',
					ownerName: '',
					ownerPhone: '',
					validCallTime: 0,
					enable: false,
					subAgent: false,
					interior: false
				},
				idx: -1,
				id: -1,
				loading: false,
				token: { Authorization: localStorage.getItem('token'), UserRole: localStorage.getItem('ms_role') },
				rules: {
					name: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
					code: [{ required: true, message: '请输入企业代码', trigger: 'blur' }],
					ownerName: [{ required: true, message: '请输入负责人', trigger: 'blur' }],
					ownerPhone: [{ required: true, message: '请输入联系方式', trigger: 'blur' }]
				},
				agentVisible: false,
				tableData2: [],
				updateAccountVisible: false,
				updateAccountForm: {
					mous: ''
				},
				agentVisibleId: '',
				addAccountVisible: false,
				addAccountForm: {
					ownerPhone: "",
					name: ""
				}
			};
		},
		created() {
			this.init();
			this.getData();
		},
		methods: {
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			init() {
				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
				});
			},
			getData() {
				this.query.currentUserId = localStorage.getItem('user');
				list(this.query).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			showForm() {
				this.addVisible = true;
				this.addButton = true;
				this.updateButton = false;
			},
			addAgent(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						this.loading = true;
						this.form.userId = localStorage.getItem('user');
						addAgent(this.form).then(res => {
							if (res.code == 200) {
								this.$message.success('添加成功');
								this.getData();
							} else {
								this.$message.error(res.message);
							}
							this.addVisible = false;
						});
					}
				});
			},
			// 编辑操作
			handleEdit(index, id) {
				this.addVisible = true;
				this.idx = index;
				this.addButton = false;
				this.updateButton = true;
				// 获取详情
				fetchAgentById(id).then(res => {
					this.form = res.data;
				});
			},
			updateAgent(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						this.loading = true;
						this.form.userId = localStorage.getItem('user');
						updateAgent(this.form).then(res => {
							if (res.code == 200) {
								this.$message.success('修改成功');
								this.getData();
							} else {
								this.$message.error(res.message);
							}
							this.addVisible = false;
						});
					}
				});
			},
			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.getData();
			},
			// 删除操作
			handleDelete(index, id) {
				// 二次确认删除
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						delAgent(id).then(res => {
							if (res.code == 200) {
								this.$message.success('删除成功');
								this.pageTotal = this.pageTotal - 1;
								this.tableData.splice(index, 1);
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
			},
			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			closeDialog(formName) {
				this.addVisible = false;
				(this.form = {
					name: '',
					code: '',
					ownerName: '',
					ownerPhone: ''
				}),
				this.resetForm(formName);
			},
			getExportAgentSeats() {
				let data = {
					agentId: this.query.agentId
				};
				getExportAgentSeat(data).then(res => {
					const blob = new Blob([res], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
					});
					let downloadElement = document.createElement('a');
					let href = window.URL.createObjectURL(blob);
					downloadElement.href = href;
					let fileName = '坐席详情' + '.xlsx';
					downloadElement.download = fileName;
					document.body.appendChild(downloadElement);
					downloadElement.click();
					document.body.removeChild(downloadElement);
					window.URL.revokeObjectURL(href);
				});
			},
			handleDetails(agentId) {
				this.detailsVisible = true;
				let data = {
					agentId: agentId
				};
				agentSeatCardType(data).then(res => {
					if (res.code == 200) {
						this.tableData2 = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			closeDialog2() {
				this.agentVisible = false;
				this.tableData2 = [];
			},
			//获取代理账号列表
			handleAgentEdit(id) {
				this.agentVisible = true;
				this.agentVisibleId = id;
				agentAccount(id).then(res => {
					if (res.code == 200) {
						this.tableData2 = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			//修改代理账号状态
			agentEditEnable(row) {
				let data = {
					accountId: row.accountId,
					enable: row.enable
				};
				stopAccount(data).then(res => {
					if (res.code == 200) {
						this.$message.success(res.data);
					}
				});
			},
			handleUpdateAccount(row) {
				this.updateAccountVisible = true;
				this.updateAccountForm = row;
			},
			getupdateAccount() {
				let data = {
					accountId: this.updateAccountForm.accountId,
					phone: this.updateAccountForm.account,
					month: this.updateAccountForm.month,
				};
				updateAccount(data).then(res => {
					if (res.code == 200) {
						this.updateAccountVisible = false;
						agentAccount(this.agentVisibleId).then(res => {
							if (res.code == 200) {
								this.tableData2 = res.data;
							} else {
								this.$message.error(res.message);
							}
						});
						this.$message.success(res.data);
					}
				});
			},
			closeDialog3() {
				this.updateAccountVisible = false;
			},
			closeDialog4() {
				this.addAccountVisible = false;
			},
			handleAddAccount() {
				this.addAccountVisible = true;
				this.addAccountForm.name = '';
				this.addAccountForm.ownerPhone = '';
			},
			getAddAccount() {
				this.addAccountForm.id = this.agentVisibleId;
				addAccount(this.addAccountForm).then(res => {
					if (res.code == 200) {
						this.addAccountVisible = false;
						agentAccount(this.agentVisibleId).then(res => {
							if (res.code == 200) {
								this.tableData2 = res.data;
							} else {
								this.$message.error(res.message);
							}
						});
						this.$message.success(res.data);
					} else {
						this.$message.error(res.message);
					}
				});
			},
			handleDeleteAccount(accountId) {
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						let data = {
							accountId: accountId
						}
						deleteAccount(data).then(res => {
							if (res.code == 200) {
								this.$message.success(res.data);
								agentAccount(this.agentVisibleId).then(res => {
									if (res.code == 200) {
										this.tableData2 = res.data;
									} else {
										this.$message.error(res.message);
									}
								});
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});

			},
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	/* a:hover{background: #66b1ff} */
	.handle-select {
		width: 240px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #f56c6c;
	}

	.mr10 {
		margin-right: 10px;
	}

	.mr100 {
		position: absolute;
		right: 100px;
	}

	.myRight {
		position: absolute;
		right: 10px;
		bottom: 10px;
	}

	.handle-input {
		width: 200px;
		display: inline-block;
	}
</style>