<template>
	<div>
		<el-table :data="tableData" border class="table el-table" ref="multipleTable"
			header-cell-class-name="table-header" :header-cell-style="{ 'text-align': 'center' }"
			@selection-change="handleSelectionChange" :row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }"
			:row-class-name="tableRowClassName">
			<el-table-column prop="agentName" label="代理商" align="center"></el-table-column>
			<el-table-column prop="title" label="标题" align="center"></el-table-column>
			<el-table-column prop="url" label="图片" align="center">
				<template #default="scope">
					<el-image v-if="scope.row.url" @click="bigImg(scope.row.url)"
						style="width: 50px; height: 50px; margin-bottom:-4px" :src="scope.row.url"
						:preview-src-list="srcList"></el-image>

					<span v-else></span>
				</template>
			</el-table-column>
			<el-table-column prop="createdAt" width="200" label="发布时间" align="center"></el-table-column>

			<el-table-column label="操作" width="190" align="center">
				<template #default="scope">
					<el-button size="small" type="text" icon="el-icon-delete" class="red"
						@click="handleDelete(scope.$index, scope.row.id)">删除</el-button>
					<el-button size="small" type="text" @click="handleEdit(scope.row)">编辑</el-button>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
	import { add, notice, delNotice } from '../api/notice';
	export default {
		data() {
			return {
				theme: localStorage.getItem('theme'),
				tableData: [],
				limitCount: 1,
				form: {
					title: '',
					content: '',
					url: ''
				},
				domain: '',
				srcList: []
			};
		},

		created() {
			this.getNoticeList();
		},
		methods: {
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			getNoticeList() {
				notice(localStorage.getItem('user')).then(res => {
					this.tableData = res.data;
				});
			},
			// 删除操作
			handleDelete(index, id) {
				// 二次确认删除
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						delNotice(id).then(res => {
							if (res.code == 200) {
								this.$message.success('删除成功');
								this.pageTotal = this.pageTotal - 1;
								this.tableData.splice(index, 1);
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
			},
			bigImg(url) {
				this.srcList.push(url);
			},
			handleEdit(row) {
				this.$router.push('addNotice');
				localStorage.setItem('rowNotice', JSON.stringify(row));
			},
		}
	};
</script>
<style>
	.red {
		color: #f56c6c;
	}

	.myRight {
		position: absolute;
		right: 10px;
		bottom: 10px;
	}
</style>