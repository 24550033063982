import request from '../utils/request';
import qs from "qs";

export const list = param => {
	return request.post('/api/company/list', qs.stringify(param))
};

export const addCompany = param => {
	return request.post('/api/company/add', qs.stringify(param))
};

export const updateCompany = param => {
	return request.post('/api/company/update', qs.stringify(param))
};
export const batchLocked = param => {
	return request.post('/api/company/batchLocked', qs.stringify(param))
};

export const delCompany = data => {
	return request.get('/api/company/delete?userId=' + data.userId + '&&id=' + data.id)
};

export const fetchCompanyById = companyId => {
	return request.get('/api/company/fetchCompanyById?companyId=' + companyId)
};

export const fetchCompanyByAgentId = data => {
	return request.get('/api/company/fetchCompanyByAgentId?agentId=' + data.agentId + '&&subAgent=' + data
		.subAgent)
};

// eslint-disable-next-line no-unused-vars
export const fetchDefaultCompanies = data => {
	return request.get('/api/company/fetchDefaultCompanies?currentUserId=' + data.currentUserId + '&&subAgentId=' +
		data.subAgentId)
};
// 企业类型
export const fetchCompanyType = res => {
	return request.get('/api/company/fetchCompanyType')
};


export const getQiNiuYunToken = agentId => {
	return request.get('/api/company/uploadToken')
};
//号码池列表
export const numberList = param => {
	return request.post('/api/numberPool/list', qs.stringify(param))
};

//号码池列表
export const companyLvLog = param => {
	return request.post('/api/company/companyLvLog', qs.stringify(param))
};

//号码池导入
export const numberPoolUpload = param => {
	return request.post('/api/numberPool/upload', qs.stringify(param))
};
//号码池删除
export const numberPoolDelete = param => {
	return request.post('/api/numberPool/delete', qs.stringify(param))
};
//查询省份
export const getProvince = param => {
	return request.post('/api/numberPool/getProvince', qs.stringify(param))
};
//查询市
export const getCity = param => {
	return request.post('/api/numberPool/getCity', qs.stringify(param))
};
//编辑
export const numberPoolEdit = param => {
	return request.post('/api/numberPool/update', qs.stringify(param))
};
//添加
export const addReportNumber = param => {
	return request.post('/api/blackList/addReportNumber', qs.stringify(param))
};
//手动添加
export const addReport = param => {
	return request.post('/api/blackList/addReport', qs.stringify(param))
};
//文件删除
export const numberPooldeleteFile = param => {
	return request.post('/api/numberPool/deleteUpload', qs.stringify(param))
};


//单个或者批量删除
export const snailsDeletes = param => {
	return request.post('/api/numberPool/snailsDelete', qs.stringify(param))
};

//单个或者批量删除
export const getSnailsList = param => {
	return request.post('/api/numberPool/snailsList', qs.stringify(param))
};

//修改是否合作
export const updateEnable = param => {
	return request.post('/api/company/updateEnable', qs.stringify(param))
};

//获取每个品牌坐席数
export const agentSeatCardType = param => {
	return request.post('/api/agent/agentSeatCardType', qs.stringify(param))
};

//获取领取记录
export const claimRecord = param => {
	return request.post('/api/intentionCustomer/claimRecord', qs.stringify(param))
};
//设置领取数量
export const claimSetting = param => {
	return request.post('/api/intentionCustomer/claimSetting', qs.stringify(param))
};
//查询设置
export const setClaimSetting = companyId => {
	return request.get('/api/intentionCustomer/getClaimSetting?companyId=' + companyId)
};
//导出坐席数
export const getExportAgentSeat = param => {
	return request.post('/api/agent/ExportAgentSeat', qs.stringify(param), {
		responseType: 'blob'
	})
}; //导出
export const downloadCompany = param => {
	return request.post('/api/company/downloadCompany', qs.stringify(param), {
		responseType: 'blob'
	})
};
//列表
export const adminRoleList = data => {
	return request.get('/api/user/adminRoleList?userId=' + data.userId)
};
//启用停用账号
export const adminRoleStop = param => {
	return request.post('/api/user/adminRoleStop', qs.stringify(param))
};
//重置密码
export const adminRoleResetPwd = param => {
	return request.post('/api/user/adminRoleResetPwd', qs.stringify(param))
};
//添加
export const addAdminRole = param => {
	return request.post('/api/user/addAdminRole', qs.stringify(param))
};
//删除
export const adminRoleDelete = param => {
	return request.post('/api/user/adminRoleDelete', qs.stringify(param))
};