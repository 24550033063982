<template>
	<div>
		<div class="container">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="预存款" name="preDeposit">
					<div class="handle-box">
						<el-form label-width="120px" :model="addForm" ref="addForm" style="width: 450px;">
							<el-form-item label="类型">
								<el-radio-group v-model="addForm.radio">
									<el-radio label="单个预存">单个预存</el-radio>
									<el-radio label="批量预存">批量预存</el-radio>
								</el-radio-group>
							</el-form-item>
							<el-form-item label="号码" v-if="addForm.radio=='单个预存'">
								<el-input size="small" v-model="addForm.phone" clearable maxlength="12" placeholder="号码"
									style="width: 180px;" class="handle-input mr10"></el-input>
								<span v-if="preDepositAmount">含有预存款:{{preDepositAmount}}元</span>
							</el-form-item>
							<el-form-item label="批量上传预存款" v-if="addForm.radio=='批量预存'">
								<el-input size="small" type="textarea" :rows="5" placeholder="一行一个号码"
									v-model="addForm.phones"></el-input>
							</el-form-item>
							<el-form-item label="充值金额">
								<el-input size="small" v-model="addForm.fee" clearable maxlength="12" placeholder="充值金额"
									style="width: 180px;" class="handle-input mr10" :value="formattedFee"
									@input="handleInput"></el-input>
							</el-form-item>
							<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
								<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
									@click="getPrepayQuery" v-if="addForm.radio=='单个预存'">查询</el-button>
								<el-button size="small" type="primary" :class="[theme]" @click="getPrepayAdd"
									v-if="addForm.radio=='单个预存'">充值</el-button>
								<el-button size="small" type="primary" :class="[theme]" @click="getPrepayAddbatch"
									v-if="addForm.radio=='批量预存'">充值</el-button>
							</el-form-item>
						</el-form>
					</div>
				</el-tab-pane>
				<el-tab-pane label="预存款记录" name="record">
					<div class="handle-box">
						<el-input size="small" v-model="query.phone" clearable maxlength="12" placeholder="号码"
							style="width: 180px;" class="handle-input mr10"></el-input>
						<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
							@click="getFindPageByOperNo">搜索</el-button>
					</div>
					<el-table :data="tableData" border class="table" ref="multipleTable"
						header-cell-class-name="table-header" :header-cell-style="{ 'text-align': 'center' }"
						@selection-change="handleSelectionChange" :row-style="{ height: '20px' }"
						:cell-style="{ padding: '6px' }" :row-class-name="tableRowClassName">
						<el-table-column prop="sessionIdStr" label="SessionlD" align="center"></el-table-column>
						<el-table-column prop="prestoreNum" label="电话号码" align="center"></el-table-column>
						<el-table-column prop="fee" label="预存金额" align="center"></el-table-column>
						<el-table-column prop="statusName" label="充值状态" align="center"></el-table-column>
						<el-table-column prop="date" label="导入时间" align="center"></el-table-column>
						<el-table-column label="操作" width="190" align="center">
							<template #default="scope">
								<el-button size="small" type="danger" icon="el-icon-delete" v-if="scope.row.status ==1"
									@click="handleedelete(scope.row)">退款</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div class="pagination">
						<el-pagination background layout="total, sizes, prev, pager, next, jumper"
							:current-page="query.pageIndex" :page-sizes="[100, 1000, 2000, 5000]"
							:page-size="query.pageSize" :total="pageTotal" @size-change="handleSizeChange"
							@current-change="handlePageChange"></el-pagination>
					</div>
				</el-tab-pane>
			</el-tabs>


		</div>
	</div>
</template>

<script>
	import {
		findPageByOperNo,
		prepayQuery,
		prepayAdd,
		prepayRecoil,
		prepayAddbatch
	} from '../api/wn.js';
	export default {
		name: 'preDeposit',
		data() {
			return {
				activeName: 'preDeposit',
				theme: localStorage.getItem('theme'),
				query: {
					phone: '',
					pageIndex: 1,
					pageSize: 30,
					userId: localStorage.getItem('user')
				},
				addVisible: false,
				tableData: [],
				pageTotal: 0,
				addForm: {
					userId: localStorage.getItem('user'),
					phone: '',
					fee: '',
					radio: '单个预存'
				},
				preDepositAmount: '',
				formattedFee: ''

			};
		},

		created() {

		},
		watch: {
			// 监听 addForm.fee 的变化，更新 formattedFee
			'addForm.fee'(newVal) {
				this.formattedFee = this.formatFee(newVal);
			}
		},
		methods: {
			handleInput(value) {
				// 移除非数字字符
				let cleanedValue = value.replace(/\D/g, '');

				// 更新表单值
				this.addForm.fee = cleanedValue;
				// 触发 watch 更新 formattedFee
			},
			formatFee(value) {
				// 这里可以添加额外的格式化逻辑，比如添加逗号作为千位分隔符等
				// 但对于基本正整数验证和长度限制，上面的 handleInput 已经足够
				return value;
			},
			tableRowClassName({
				rowIndex
			}) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			handleClick(tab, event) {
				this.activeName = tab.props.name;
				if (tab.props.name == 'preDeposit') {

				} else {
					this.getFindPageByOperNo();
				}
			},
			getPrepayQuery() {
				let data = {
					userId: localStorage.getItem('user'),
					phone: this.addForm.phone,
				}
				prepayQuery(data).then(res => {
					if (res.code == 200) {
						this.preDepositAmount = res.data;

					} else {
						this.$message.error(res.message);
					}
				});
			},
			//充值
			getPrepayAdd() {
				let data = {
					userId: localStorage.getItem('user'),
					phone: this.addForm.phone,
					fee: this.addForm.fee
				}
				prepayAdd(data).then(res => {
					if (res.code == 200) {
						this.$message.success(res.data);
						this.addForm.fee = '';
						this.addForm.preDepositAmount = '';
						this.getPrepayQuery()
					} else {
						this.$message.error(res.message);
					}
				});
			},
			//批量充值
			getPrepayAddbatch() {
				let data = {
					userId: localStorage.getItem('user'),
					phones: this.addForm.phones,
					fee: this.addForm.fee
				}
				prepayAddbatch(data).then(res => {
					if (res.code == 200) {
						this.$message.success(res.data);
						this.addForm.phones = '';
						this.addForm.fee = '';
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getFindPageByOperNo() {
				findPageByOperNo(this.query).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},



			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getFindPageByOperNo();
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getFindPageByOperNo();
			},
			handleedelete(row) {
				this.$confirm('是否要退款此预存？', '提示', {
						type: 'warning',
						confirmButtonText: '退款',
						cancelButtonText: '不退款',
						closeOnClickModal: false
					})
					.then(() => {
						let data = {
							userId: localStorage.getItem('user'),
							id: row.sessionIdStr,
							phone: row.prestoreNum,
						}
						prepayRecoil(data).then(res => {
							if (res.code == 200) {
								this.$message.success(res.data);
								this.getFindPageByOperNo();
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {

					});
			}

		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	/* a:hover {
	background: #66b1ff;
} */
	.handle-select {
		width: 240px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #f56c6c;
	}

	.mr10 {
		margin-right: 10px;
	}

	.mr100 {
		position: absolute;
		right: 100px;
	}

	.myRight {
		position: absolute;
		right: 10px;
		bottom: 10px;
	}

	.handle-input {
		width: 200px;
		display: inline-block;
	}

	.recharge-box .el-radio__input {
		display: none;
	}

	.recharge-box .el-radio.is-bordered {
		padding: 8px 14px 0 5px;
		border-radius: 4px;
		border: 1px solid #dcdfe6;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		height: 30px;
	}

	.table-box {
		/* width: 1700px; */
		/* border: 1px solid #ebeef5; */
		display: flex;
		flex-direction: row;
		margin-bottom: 40px;
	}

	.user-info-box {
		/* border: 1px solid #ebeef5; */

		width: 600px;
	}

	.user-info-tit {
		background: #fafafa;
		color: #000;
		font-weight: 600;
		text-align: center;
		height: 34px;
		line-height: 34px;
		border-right: 1px solid #ebeef5;
		border-bottom: 1px solid #ebeef5;
	}

	.info-box {
		display: flex;
		flex-direction: row;
		height: 34px;
		line-height: 34px;
		border-bottom: 1px solid #ebeef5;
		border-right: 1px solid #ebeef5;
	}

	.info-name {
		background: #fafafa;
		border-right: 1px solid #ebeef5;
		border-left: 1px solid #ebeef5;
		width: 120px;
		padding-left: 10px;
		font-weight: 600;
	}

	.user-info-box .info-box .info {
		padding-left: 10px;
		color: #226003;
	}

	.down {
		border: 1px solid #dcdfe6;
		background: #4f7afd;
		color: #ffffff;
		transition: 0.1s;
		font-weight: 500;
		padding: 6px 12px;
		font-size: 12px;
		border-radius: 4px;
		display: inline-block;
	}
</style>