import {
	createRouter,
	createWebHistory
} from "vue-router";
import Home from "../views/Home.vue";

const routes = [{
	path: '/',
	redirect: '/dashboard'
}, {
	path: "/",
	name: "Home",
	component: Home,
	children: [{
			path: "/dashboard",
			name: "dashboard",
			meta: {
				title: '系统首页'
			},
			component: () => import(
				/* webpackChunkName: "dashboard" */
				"../views/Dashboard.vue")
		}, {
			path: "/addReturns",
			name: "addReturns",
			meta: {
				title: '充值'
			},
			component: () => import(
				/* webpackChunkName: "dashboard" */
				"../views/addReturns.vue")
		},
		{
			path: "/customer",
			name: "customer",
			meta: {
				title: '我的客户'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/customer.vue")
		},
		{
			path: "/CRMHigh",
			name: "CRMHigh",
			meta: {
				title: '公海客户'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/CRMHigh.vue")
		},

		{
			path: "/clue",
			name: "baseTable",
			meta: {
				title: '线索列表'
				// title: '客户列表'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/clue.vue")
		},
		{
			path: "/cluePool",
			name: "cluePool",
			meta: {
				title: '线索池'
				// title: '客户列表'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/cluePool.vue")
		},
		{
			path: "/assignLeads",
			name: "assignLeads",
			meta: {
				title: '线索回收'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/assignLeads.vue")
		},
		{
			path: "/qualityInspection",
			name: "qualityInspection",
			meta: {
				title: '录音质检'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/qualityInspection.vue")
		}, {
			path: "/inspectionRecords",
			name: "inspectionRecords",
			meta: {
				title: '质检记录'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/inspectionRecords.vue")
		},
		{
			path: "/riskList",
			name: "riskList",
			meta: {
				title: '风险通话列表'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/riskList.vue")
		}, {
			path: "/AIinspection",
			name: "AIinspection",
			meta: {
				title: 'AI质检'
			},
			component: () => import(
				"../views/AIinspection.vue")
		},
		{
			path: "/record",
			name: "record",
			meta: {
				title: '通话记录'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/record.vue")
		},
		{
			path: "/recording",
			name: "recording",
			meta: {
				title: '录音检测'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/recording.vue")
		},
		{
			path: "/tag",
			name: "tag",
			meta: {
				title: '标签管理',
			},
			component: () => import(
				"../views/tag.vue")
		},
		{
			path: "/sip",
			name: "sip",
			meta: {
				title: '语音通话',
			},
			component: () => import(
				"../views/sip.vue")
		},
		{
			path: "/customTag",
			name: "customTag",
			meta: {
				title: '字段配置',
			},
			component: () => import(
				"../views/customTag.vue")
		},
		{
			path: "/clueCollection",
			name: "clueCollection",
			meta: {
				title: '领取记录',
			},
			component: () => import(
				"../views/clueCollection.vue")
		},
		{
			path: "/source",
			name: "source",
			meta: {
				title: '来源配置',
			},
			component: () => import(
				"../views/source.vue")
		}, {
			path: "/release",
			name: "release",
			meta: {
				title: '释放管理',
			},
			component: () => import(
				"../views/release.vue")
		}, {
			path: "/modou",
			name: "modou",
			meta: {
				title: '模板',
			},
			component: () => import(
				"../views/modou.vue")
		},
		{
			path: "/automaticRecycling",
			name: "automaticRecycling",
			meta: {
				title: '自动回收',
			},
			component: () => import(
				"../views/automaticRecycling.vue")
		},

		{
			path: "/followProgress",
			name: "followProgress",
			meta: {
				title: '跟进阶段配置',
			},
			component: () => import(
				"../views/followProgress.vue")
		},
		{
			path: "/company",
			name: "company",
			meta: {
				title: '企业列表',
				role: ['admin', 'agent', 'subAgent']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/company.vue")
		},
		{
			path: "/deleteRecords",
			name: "deleteRecords",
			meta: {
				title: '删除记录',
				role: ['admin']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/deleteRecords.vue")
		},
		{
			path: "/department",
			name: "department",
			meta: {
				title: '部门管理'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/department.vue")
		}, {
			path: "/user",
			name: "user",
			meta: {
				title: '员工管理'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/user.vue")
		}, {
			path: "/report",
			name: "report",
			meta: {
				title: '员工统计报表',
				permission: true,
				role: ['admin', 'agent', 'company', 'department', 'subAgent']
			},
			component: () => import(
				/* webpackChunkName: "form" */
				"../views/report.vue")
		}, {
			path: "/companyStatistics",
			name: "companyStatistics",
			meta: {
				title: '公司统计报表',
				permission: true,
				role: ['admin', 'agent', 'company', 'department', 'subAgent']
			},
			component: () => import(
				/* webpackChunkName: "form" */
				"../views/companyStatistics.vue")
		}, {
			path: "/GSAxb",
			name: "GSAxb",
			meta: {
				title: '光速AXB',
				permission: true,
				role: ['admin', 'agent', 'company', 'department', 'subAgent']
			},
			component: () => import(
				/* webpackChunkName: "form" */
				"../views/GSAxb.vue")
		}, {
			path: "/cjabnormalCall",
			name: "cjabnormalCall",
			meta: {
				title: '长江异常话单',
				permission: true,
				role: ['admin', 'agent', ]
			},
			component: () => import(
				/* webpackChunkName: "form" */
				"../views/cjabnormalCall.vue")
		}, {
			path: "/agent",
			name: "agent",
			meta: {
				title: '代理商管理',
				permission: true,
				role: ['admin', 'oem']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/agent.vue")
		}, {
			path: "/administratorAccount",
			name: "administratorAccount",
			meta: {
				title: '管理员账号',
				permission: true,
				role: ['admin']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/administratorAccount.vue")
		},
		// {
		// 	path: "/blackListDatabase",
		// 	name: "blackListDatabase",
		// 	meta: {
		// 		title: '黑名单库',
		// 		permission: true,
		// 		role: ['admin', 'agent', 'subAgent']
		// 	},
		// 	component: () => import(
		// 		/* webpackChunkName: "table" */
		// 		"../views/blackListDatabase.vue")
		// },
		{
			path: "/cardType",
			name: "cardType",
			meta: {
				title: '品牌管理',
				permission: true,
				role: ['admin', 'agent', 'subAgent'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/cardType.vue")
		}, {
			path: "/businessRecords",
			name: "businessRecords",
			meta: {
				title: '业务记录',
				permission: true,
				role: ['admin'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/businessRecords.vue")
		},
		{
			path: "/numberPool",
			name: "numberPool",
			meta: {
				title: '号码池',
				permission: true,
				role: ['admin'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/numberPool.vue")
		}, {
			path: "/woniunumberPool",
			name: "woniunumberPool",
			meta: {
				title: '蜗牛号码池',
				permission: true,
				role: ['admin', 'oem'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/woniunumberPool.vue")
		}, {
			path: "/numberControl",
			name: "numberControl",
			meta: {
				title: '漫游管控',
				permission: true,
				role: ['admin', 'agent'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/numberControl.vue")
		}, {
			path: "/accountOpeningProgress",
			name: "accountOpeningProgress",
			meta: {
				title: '蜗牛开户进度',
				permission: true,
				role: ['admin'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/accountOpeningProgress.vue")
		}, {
			path: "/batchAccountClosure",
			name: "batchAccountClosure",
			meta: {
				title: '批量销户',
				permission: true,
				role: ['admin'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/batchAccountClosure.vue")
		},
		{
			path: "/woniuRecharge",
			name: "woniuRecharge",
			meta: {
				title: '充值列表',
				permission: true,
				role: ['admin'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/woniuRecharge.vue")
		},
		{
			path: "/blackNameList",
			name: "blackNameList",
			meta: {
				title: '风险黑名单',
				permission: true,
				role: ['admin'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/blackNameList.vue")
		},
		{
			path: "/subagent",
			name: "subagent",
			meta: {
				title: '子代理列表',
				permission: true,
				role: ['admin', 'agent', 'subAgent'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/subagent.vue")
		},
		{
			path: "/woniuBill",
			name: "woniuBill",
			meta: {
				title: '蜗牛话单',
				permission: true,
				role: ['admin', 'oem'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/woniuBill.vue")
		},
		{
			path: "/outboundCallRecords",
			name: "outboundCallRecords",
			meta: {
				title: '外呼记录',
				permission: true,
				role: ['admin', 'agent', 'subAgent'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/outboundCallRecords.vue")
		}, {
			path: "/shutdownRecord",
			name: "shutdownRecord",
			meta: {
				title: '蜗牛停机记录',
				permission: true,
				role: ['admin', 'oem'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/shutdownRecord.vue")
		}, {
			path: "/hySA",
			name: "hySA",
			meta: {
				title: '华云SA查询',
				permission: true,
				role: ['admin', 'agent', 'subAgent'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/hySA.vue")
		}, {
			path: "/tagQuery",
			name: "tagQuery",
			meta: {
				title: '标记查询',
				permission: true,
				role: ['admin', 'agent', 'subAgent'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/tagQuery.vue")
		}, {
			path: "/programmeManagement",
			name: "programmeManagement",
			meta: {
				title: '方案管理',
				permission: true,
				role: ['admin'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/programmeManagement.vue")
		},
		{
			path: "/webClue",
			name: "webClue",
			meta: {
				title: '网站线索',
				permission: true,
				role: ['admin'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/webClue.vue")
		}, {
			path: "/statistics",
			name: "statistics",
			meta: {
				title: '话单校验',
				permission: true,
				role: ['admin'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/statistics.vue")
		}, {
			path: "/restartRecordList",
			name: "restartRecordList",
			meta: {
				title: '迪加停复机',
				permission: true,
				role: ['admin', 'oem'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/restartRecordList.vue")
		}, {
			path: "/DJfreeze",
			name: "DJfreeze",
			meta: {
				title: '自选套餐冻结',
				permission: true,
				role: ['admin', 'agent'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/DJfreeze.vue")
		}, {
			path: "/WNrestartRecordList",
			name: "WNrestartRecordList",
			meta: {
				title: '蜗牛停复机',
				permission: true,
				role: ['admin', 'agent', 'subAgent'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/WNrestartRecordList.vue")
		}, {
			path: "/NoShutdownMaintenanceNumber",
			name: "NoShutdownMaintenanceNumber",
			meta: {
				title: '取消停机保号',
				permission: true,
				role: ['admin'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/NoShutdownMaintenanceNumber.vue")
		}, {
			path: "/userInfo",
			name: "userInfo",
			meta: {
				title: '迪加查询',
				permission: true,
				role: ['admin', 'agent', 'subAgent'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/userInfo.vue")
		}, {
			path: "/wnQuery",
			name: "wnQuery",
			meta: {
				title: '蜗牛查询',
				permission: true,
				role: ['admin', 'agent', 'subAgent'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/wnQuery.vue")
		}, {
			path: "/WNWhitelist",
			name: "WNWhitelist",
			meta: {
				title: '蜗牛批量加白',
				permission: true,
				role: ['admin'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/WNWhitelist.vue")
		}, {
			path: "/djRecharge",
			name: "djRecharge",
			meta: {
				title: '迪加充值',
				permission: true,
				role: ['admin'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/djRecharge.vue")
		}, {
			path: "/wnRecharge",
			name: "wnRecharge",
			meta: {
				title: '蜗牛充值',
				permission: true,
				role: ['admin'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/wnRecharge.vue")
		}, {
			path: "/preDeposit",
			name: "preDeposit",
			meta: {
				title: '蜗牛预存款',
				permission: true,
				role: ['admin'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/preDeposit.vue")
		}, {
			path: "/frequencyLimit",
			name: "frequencyLimit",
			meta: {
				title: '呼叫限频',
				permission: true,
				role: ['admin'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/frequencyLimit.vue")
		}, {
			path: "/djRebateList",
			name: "djRebateList",
			meta: {
				title: '返销记录',
				permission: true,
				role: ['admin'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/djRebateList.vue")
		}, {
			path: "/activationList",
			name: "activationList",
			meta: {
				title: '限制返档',
				permission: true,
				role: ['admin', 'agent', 'subAgent'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/activationList.vue")
		}, {
			path: "/DJAbnormalCallList",
			name: "DJAbnormalCallList",
			meta: {
				title: '迪加异常话单',
				permission: true,
				role: ['admin', 'agent', 'subAgent'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/DJAbnormalCallList.vue")
		}, {
			path: "/WNAbnormalCallList",
			name: "WNAbnormalCallList",
			meta: {
				title: '蜗牛异常话单',
				permission: true,
				role: ['admin', 'agent', 'subAgent'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/WNAbnormalCallList.vue")
		},
		{
			path: "/accountReview",
			name: "accountReview",
			meta: {
				title: '开户审核查询'
			},
			component: () => import(
				/* webpackChunkName: "dashboard" */
				"../views/accountReview.vue")
		},
		{
			path: "/workOrder",
			name: "workOrder",
			meta: {
				title: '工单管理',
				permission: true,
				role: ['admin', 'oem'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/workOrder.vue")
		}, {
			path: "/agentSerial",
			name: "agentSerial",
			meta: {
				title: '代理商分配记录',
				permission: true,
				role: ['admin', 'oem']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/agentSerial.vue")
		},
		// {
		// 	path: "/userSerial",
		// 	name: "userSerial",
		// 	meta: {
		// 		title: '员工分配记录',
		// 		permission: true,
		// 		role: ['admin', 'agent', 'subAgent',

		// 	},
		// 	component: () => import(
		// 		/* webpackChunkName: "table" */
		// 		"../views/userSerial.vue")
		// }, 
		{
			path: "/flowManagement",
			name: "flowManagement",
			meta: {
				title: '流水管理',
				permission: true,
				role: ['admin', 'oem', 'agent']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/flowManagement.vue")
		},
		{
			path: "/refundList",
			name: "refundList",
			meta: {
				title: '退款列表',
				permission: true,
				role: ['admin', 'oem']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/refundList.vue")
		},
		{
			path: "/stop",
			name: "stop",
			meta: {
				title: '号码停机',
				permission: true,
				role: ['admin', 'agent', 'subAgent']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/stopPhone.vue")
		}, {
			path: "/validate",
			name: "validate",
			meta: {
				title: '号码验证'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/validate.vue")
		},
		{
			path: "/blackList",
			name: "blackList",
			meta: {
				title: '黑名单'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/blackList.vue")
		},
		// {
		// 	path: "/userManual",
		// 	name: "userManual",
		// 	meta: {
		// 		title: '用户手册'
		// 	},
		// 	component: () => import(
		// 		/* webpackChunkName: "table" */
		// 		"../views/userManual.vue")
		// },
		{
			path: "/swipes",
			name: "swipes",
			meta: {
				title: 'APP轮播图'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/swipes.vue")
		},
		{
			path: "/afterSales",
			name: "afterSales",
			meta: {
				title: '售后信息',
				role: ['admin', 'oem']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/afterSales.vue")
		},
		{
			path: "/role",
			name: "role",
			meta: {
				title: '角色管理',
				permission: true,
				role: ['admin', 'agent', 'subAgent']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/role.vue")
		}, {
			path: "/notice",
			name: "notice",
			meta: {
				title: '公告',
				permission: true,
				role: ['admin', 'agent', 'subAgent']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/notice.vue")
		},
		{
			path: "/addNotice",
			name: "addNotice",
			meta: {
				title: '发布公告',
				permission: true,
				role: ['admin', 'agent', 'subAgent']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/addNotice.vue")
		},
		{
			path: '/404',
			name: '404',
			meta: {
				title: '找不到页面'
			},
			component: () => import( /* webpackChunkName: "404" */
				'../views/404.vue')
		}, {
			path: '/403',
			name: '403',
			meta: {
				title: '没有权限'
			},
			component: () => import( /* webpackChunkName: "403" */
				'../views/403.vue')
		},
		{
			path: "/operationManual",
			name: "operationManual",
			meta: {
				title: '使用手册'
			},
			component: () => import(
				/* webpackChunkName: "dashboard" */
				"../views/operationManual.vue")
		}, {
			path: "/operationManual2",
			name: "operationManual2",
			meta: {
				title: '使用手册'
			},
			component: () => import(
				/* webpackChunkName: "dashboard" */
				"../views/operationManual2.vue")
		},
		{
			path: "/reportNumber",
			name: "reportNumber",
			meta: {
				title: '报备绿名单号码',
				permission: true,
				role: ['admin', 'oem']
			},
			component: () => import(
				/* webpackChunkName: "dashboard" */
				"../views/reportNumber.vue")
		},
		{
			path: "/operationLog",
			name: "operationLog",
			meta: {
				title: '员工操作日志',
				permission: true,
				role: ['admin', 'agent', 'subAgent'],

			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/operationLog.vue")
		},
		{
			path: "/termOfValidity",
			name: "termOfValidity",
			meta: {
				title: '有效期管理',
				permission: true,
				role: ['admin', 'agent', 'subAgent'],

			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/termOfValidity.vue")
		},
		{
			path: "/setColor",
			name: "setColor",
			meta: {
				title: '设置主题',
				permission: true,
				role: ['admin', 'agent', 'company', 'department', 'normal', 'subAgent']

			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/setColor.vue")
		},
		{
			path: "/packageList",
			name: "packageList",
			meta: {
				title: '套餐列表',
				permission: true,
				role: ['admin', 'agent', 'company', 'department', 'normal', 'subAgent']

			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/packageList.vue")
		},
		{
			path: "/middleNumber",
			name: "middleNumber",
			meta: {
				title: '回拨中间号',
				permission: true,
				role: ['admin', 'agent', 'company', 'department', 'normal', 'subAgent']

			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/middleNumber.vue")
		},
		{
			path: "/consumptionDetails",
			name: "consumptionDetails",
			meta: {
				title: '消费明细',
				permission: true,
				role: ['admin', 'agent', 'company', 'department', 'normal', 'subAgent']

			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/consumptionDetails.vue")
		},
		{
			path: "/privateNumber",
			name: "privateNumber",
			meta: {
				title: '私人号码',
				permission: true,
				role: ['admin', 'agent', 'company', 'department', 'normal', 'subAgent']

			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/privateNumber.vue")
		},
		{
			path: "/packageManagement",
			name: "packageManagement",
			meta: {
				title: '套餐管理',
				permission: true,
				role: ['admin', 'agent', 'company', 'subAgent']

			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/packageManagement.vue")
		},
		{
			path: "/packageManagementX",
			name: "packageManagementX",
			meta: {
				title: '套餐管理',
				permission: true,
				role: ['admin', 'agent', 'company', 'subAgent']

			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/packageManagementX.vue")
		}, {
			path: "/packageManagementXuser",
			name: "packageManagementXuser",
			meta: {
				title: '用户管理',
				permission: true,
				role: ['admin', 'agent', 'company', 'subAgent']

			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/packageManagementXuser.vue")
		}, {
			path: "/AXBBinding",
			name: "AXBBinding",
			meta: {
				title: '绑定查询',
				permission: true,
				role: ['admin', 'agent']

			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/AXBBinding.vue")
		},
		{
			path: "/record2",
			name: "record2",
			meta: {
				title: '虚商通话记录',
				permission: true,
				role: ['admin']

			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/record2.vue")
		},
		{
			path: "/AXBinventory",
			name: "AXBinventory",
			meta: {
				title: 'AXB库存',
				permission: true,
				role: ['admin', 'agent', 'subAgent']

			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/AXBinventory.vue")
		},

		{
			path: "/SJTXinventory",
			name: "SJTXinventory",
			meta: {
				title: '世纪通信库存',
				permission: true,
				role: ['admin']

			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/SJTXinventory.vue")
		},
		{
			path: "/SJinventory",
			name: "SJinventory",
			meta: {
				title: '世纪通信库存',
				permission: true,
				role: ['admin']

			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/SJinventory.vue")
		},
		{
			path: "/SJDeliveryRecord",
			name: "SJDeliveryRecord",
			meta: {
				title: '世纪通信库存记录',
				permission: true,
				role: ['admin']

			},
			component: () => import(
				"../views/SJDeliveryRecord.vue")
		}, {
			path: "/purchaseList",
			name: "purchaseList",
			meta: {
				title: '已购号码',
				permission: true,
				role: ['admin', 'agent', 'company', 'subAgent']

			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/purchaseList.vue")
		}, {
			path: "/consultationDetailed",
			name: "consultationDetailed",
			meta: {
				title: '消费明细',
				permission: true,
				role: ['admin', 'agent', 'company', 'subAgent']

			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/consultationDetailed.vue")
		},
		{
			path: "/seating",
			name: "seating",
			meta: {
				title: '坐席数据',
				permission: true,
				role: ['admin', 'agent', 'subAgent']
			},
			component: () => import(
				"../views/seating.vue")
		},
		{
			path: "/userPhone",
			name: "userPhone",
			meta: {
				title: '账号登录记录',
				permission: true,
				role: ['admin']
			},
			component: () => import(
				"../views/userPhone.vue")
		},
		{
			path: "/remoteLogin",
			name: "remoteLogin",
			meta: {
				title: '位置异常',
				permission: true,
				role: ['admin']
			},
			component: () => import(
				"../views/remoteLogin.vue")
		},
		{
			path: "/callsNumber",
			name: "callsNumber",
			meta: {
				title: '电话邦放行次数',
				permission: true,
				role: ['admin']
			},
			component: () => import(
				"../views/callsNumber.vue")
		},
		{
			path: "/gmAbnormalCallList",
			name: "gmAbnormalCallList",
			meta: {
				title: '国美异常话单',
				permission: true,
				role: ['admin']
			},
			component: () => import(
				"../views/gmAbnormalCallList.vue")
		},
		{
			path: "/gmAbnormalNumber",
			name: "gmAbnormalNumber",
			meta: {
				title: '国美异常号码',
				permission: true,
				role: ['admin']
			},
			component: () => import(
				"../views/gmAbnormalNumber.vue")
		},
		{
			path: "/highSeas",
			name: "highSeas",
			meta: {
				title: '公海数据',
				permission: true,
				role: ['admin', 'agent', 'company', 'department', 'normal', 'subAgent']
			},
			component: () => import(
				"../views/highSeas.vue")
		},
		{
			path: "/advancedSearch",
			name: "advancedSearch",
			meta: {
				title: '找线索',
				permission: true,
				// role: ['admin'],
				role: ['admin', 'agent', 'company', 'department', 'normal', 'subAgent']
			},
			component: () => import(
				"../views/advancedSearch.vue")
		}, {
			path: "/companySearch",
			name: "companySearch",
			meta: {
				title: '找企业',
				permission: true,
				role: ['admin', 'agent', 'company', 'department', 'normal', 'subAgent']
			},
			component: () => import(
				"../views/companySearch.vue")
		}, {
			path: "/shopSearch",
			name: "shopSearch",
			meta: {
				title: '找店铺',
				permission: true,
				role: ['admin', 'agent', 'company', 'department', 'normal', 'subAgent']
			},
			component: () => import(
				"../views/shopSearch.vue")
		}, {
			path: "/industryTemplate",
			name: "industryTemplate",
			meta: {
				title: '找行业',
				permission: true,
				role: ['admin', 'agent', 'company', 'department', 'normal', 'subAgent']
			},
			component: () => import(
				"../views/industryTemplate.vue")
		}, {
			path: "/mapSearch",
			name: "mapSearch",
			meta: {
				title: '地图找客',
				permission: true,
				role: ['admin', 'agent', 'company', 'department', 'normal', 'subAgent']
			},
			component: () => import(
				"../views/mapSearch.vue")
		}, {
			path: "/batchCheckCompany",
			name: "batchCheckCompany",
			meta: {
				title: '批量查询',
				permission: true,
				role: ['admin', 'agent', 'company', 'department', 'normal', 'subAgent']
			},
			component: () => import(
				"../views/batchCheckCompany.vue")
		}, {
			path: "/poolAccount",
			name: "poolAccount",
			meta: {
				title: '寻迹通账号管理',
				permission: true,
				role: ['admin', 'agent', 'company', 'department', 'normal', 'subAgent']
			},
			component: () => import(
				"../views/poolAccount.vue")
		},
		{
			path: "/guide",
			name: "guide",
			meta: {
				title: '指引',
				permission: true,
				role: ['admin', 'agent', 'company', 'department', 'normal', 'subAgent']
			},
			component: () => import(
				"../views/guide.vue")
		},
		{
			path: "/GM",
			name: "GM",
			meta: {
				title: '国美录音',
				permission: true,
				role: ['gm']
			},
			component: () => import(
				"../views/GM.vue")
		},
		{
			path: "/OEMset",
			name: "OEMset",
			meta: {
				title: 'OEM设置',
				permission: true,
				role: ['admin', 'agent', 'subAgent']
			},
			component: () => import(
				/* webpackChunkName: "form" */
				"../views/OEMset.vue")
		},
		{
			path: "/OEMswiper",
			name: "OEMswiper",
			meta: {
				title: '登录轮播图',
				permission: true,
				role: ['admin', 'agent', 'subAgent']
			},
			component: () => import(
				/* webpackChunkName: "form" */
				"../views/OEMswiper.vue")
		},
		{
			path: "/prestore",
			name: "prestore",
			meta: {
				title: '蜗牛预存款',
				permission: true,
				role: ['admin']

			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/prestore.vue")
		},
		{
			path: "/panelData",
			name: "panelData",
			meta: {
				title: '数据面板',
				permission: true,
				role: ['admin']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/panelData.vue")
		},
		{
			path: "/sipTemplate",
			name: "sipTemplate",
			meta: {
				title: '套餐列表',
				permission: true,
				role: ['admin']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/sipTemplate.vue")
		},
		{
			path: "/enterpriseAllocation",
			name: "enterpriseAllocation",
			meta: {
				title: '套餐分配',
				permission: true,
				role: ['admin']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/enterpriseAllocation.vue")
		},
		{
			path: "/specialServiceNumber",
			name: "specialServiceNumber",
			meta: {
				title: '号码列表',
				permission: true,
				role: ['admin']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/specialServiceNumber.vue")
		},
		{
			path: "/FTConsumptionDetails",
			name: "FTConsumptionDetails",
			meta: {
				title: '账单明细',
				permission: true,
				role: ['admin']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/FTConsumptionDetails.vue")
		},
		{
			path: "/JobData",
			name: "JobData",
			meta: {
				title: '数据面板',
				permission: true,
				role: ['admin']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/JobData.vue")
		},
		{
			path: "/JobTemplate",
			name: "JobTemplate",
			meta: {
				title: '套餐列表',
				permission: true,
				role: ['admin']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/JobTemplate.vue")
		},
		{
			path: "/JobenterpriseAllocation",
			name: "JobenterpriseAllocation",
			meta: {
				title: '套餐分配',
				permission: true,
				role: ['admin']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/JobenterpriseAllocation.vue")
		},
		{
			path: "/JobspecialServiceNumber",
			name: "JobspecialServiceNumber",
			meta: {
				title: '号码列表',
				permission: true,
				role: ['admin']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/JobspecialServiceNumber.vue")
		},
		{
			path: "/JobFTConsumptionDetails",
			name: "JobFTConsumptionDetails",
			meta: {
				title: '账单明细',
				permission: true,
				role: ['admin']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/JobFTConsumptionDetails.vue")
		},
	]
}, {
	path: "/login",
	name: "Login",
	meta: {
		title: '登录',
		loginRequest: true
	},
	component: () => import(
		/* webpackChunkName: "login" */
		"../views/Login.vue")
}];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

router.beforeEach((to, from, next) => {

	document.title = `${to.meta.title} | ${localStorage.getItem('webName')}`;
	const role = localStorage.getItem('ms_role');
	if (!role && to.path !== '/login') {
		next('/login');
	} else if (to.meta.permission) {
		// 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已

		to.meta.role.indexOf(role) >= 0 ? next() : next('/403');
	} else {
		next();
	}
});
// router.afterEach(function(to,form){
//
// })

export default router;