<template>
	<div>
		<div class="container">
			<el-row :gutter="24" type="flex" class="row-bg" justify="space-between">
				<el-col :span="24">
					<div class="card-body">
						<div class="tit">
							<div><i class="el-icon-s-data"></i>号码使用情况</div>
							<div class="tit-btn">
								<el-button size="mini" plain
									:class="[statisticsShow == 'day' ? 'active' : '', statisticsShow == 'day' ? theme : '']"
									@click="getTime('day')">
									日
								</el-button>
								<el-button size="mini" plain
									:class="[statisticsShow == 'week' ? 'active' : '', statisticsShow == 'week'? theme : '']"
									@click="getTime('week')">
									周
								</el-button>
								<el-button size="mini" plain
									:class="[statisticsShow == 'month' ? 'active' : '', statisticsShow =='month' ? theme : '']"
									@click="getTime('month')">
									月
								</el-button>
							</div>
						</div>
						<div class="card-list">
							<div class="bg_f0f2f5">
								<span>总号码数量</span>
								<span>0</span>
							</div>
							<div class="bg_eff5f7">
								<span>未激活号码数量</span>
								<span>0</span>
							</div>
							<div class="bg_dfeef3">
								<span>已激活号码数量</span>
								<span>0</span>
							</div>
							<div class="bg_f8f0f9">
								<span>管控停号码数量</span>
								<span>0</span>
							</div>

						</div>
					</div>
				</el-col>
			</el-row>
			<el-row :gutter="24" type="flex" class="row-bg" justify="space-between">
				<el-col :span="24">
					<div class="card-body">
						<div class="tit">
							<div>
								<i class="el-icon-postcard"></i> 消费数据
							</div>
						</div>

						<div class="card-list">
							<div class="bg_eff5f7">
								<span>本月订购套餐金额</span>
								<span>0</span>
							</div>
							<div class="bg_dfeef3">
								<span>本月订购分钟数</span>
								<span>0</span>
							</div>
							<div class="bg_f8f0f9">
								<span>本月消费分钟数</span>
								<span>0</span>
							</div>
							<div class="bg_f0f2f5">
								<span>套外消费分钟数</span>
								<span>0</span>
							</div>
						</div>
					</div>

				</el-col>
			</el-row>
			<el-row :gutter="24" type="flex" class="row-bg" justify="space-between">
				<el-col :span="24">
					<div class="card-body">
						<div class="tit">
							<div><i class="el-icon-finished"></i>套餐分布数据</div>
						</div>

						<div class="card-list">
							<div class="bg_eff5f7">
								<span>1000分钟</span>
								<span>0</span>
							</div>
							<div class="bg_dfeef3">
								<span>2000分钟</span>
								<span>1</span>
							</div>
							<div class="bg_f8f0f9">
								<span>3000分钟</span>
								<span>1</span>
							</div>
							<div class="bg_f0f2f5">
								<span>4000分钟</span>
								<span>4</span>
							</div>
						</div>
					</div>
				</el-col>
			</el-row>

		</div>
	</div>
</template>

<script>
	import { DataBoard } from '../api/setMeal.js';
	export default {
		name: 'panelData',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					userId: localStorage.getItem('user'),
				},
				isAdmin: false,
				isAgent: false,
				isNormal: false,
				role: '',
				statisticsShow: 'day',
				multipleSelection: []
			};
		},
		created() {
			this.role = localStorage.getItem('ms_role');
			if (this.role === 'admin') {
				this.isAdmin = true;
			}
			if (this.role == 'agent') {
				this.isAgent = true;
			}
			if (this.role == 'normal') {
				this.isNormal = true;
			}
			this.getData();
		},
		methods: {
			getData() {
				let data = {
					userId: localStorage.getItem('user'),
					type: this.statisticsShow
				};
				DataBoard(data).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getTime(e) {
				this.statisticsShow = e;
				this.getData();

			},
		}
	};
</script>

<style scoped>
	.row-bg {
		padding-right: 12px;
		margin-bottom: 12px;
	}

	.card-body {
		color: rgba(0, 0, 0, 0.65);
		background: #fff;
		border-radius: 2px;
		box-sizing: border-box;
		padding: 24px;

	}

	.card-body .tit {
		font-weight: 700;
		color: rgba(0, 0, 0, 0.85);
		margin-bottom: 1em;
		font-size: 14px;
		font-variant: tabular-nums;
		line-height: 1.5;
		list-style: none;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.el-icon-s-data,
	.el-icon-postcard,
	.el-icon-finished {
		font-size: 20px;
		margin-right: 5px;
		color: rgb(24, 144, 255);
	}

	.card-body .card-list {
		display: flex;
		flex-direction: row;
	}

	.card-body .card-list div {
		padding-left: 12px;
		padding-right: 12px;
		padding: 24px;
		zoom: 1;
		width: 23%;
		display: flex;
		flex-direction: column;
		margin-right: 2%;

	}

	.card-body .tit .tit-btn .active {
		color: #fff;
	}

	.card-body .card-list div>span:first-child {
		font-size: 14px;
		color: rgba(0, 0, 0, 0.65);
	}

	.card-body .card-list div>span:last-child {
		font-size: 20px;
		margin-top: 10px;
		font-weight: 700;
	}

	.bg_eff5f7 {
		background: #eff5f7;
	}

	.bg_dfeef3 {
		background: #dfeef3;
	}

	.bg_f8f0f9 {
		background: #f8f0f9;
	}

	.bg_f0f2f5 {
		background: #f0f2f5;
	}
</style>