<template>
	<div>
		<div class="handle-box">

			<el-button size="small" type="primary" :class="[theme]" class="" @click="showForm"
				v-if="isAdmin">添加模版</el-button>
		</div>
		<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
			:row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }"
			:header-cell-style="{ 'text-align': 'center' }" height="600">
			<el-table-column prop="name" label="模版名称" align="center" width="120" fixed></el-table-column>
			<el-table-column prop="recycle" label="自动回收" align="center" width="80">
				<template #default="scope">
					<span v-if="scope.row.recycle==false" style="color: #f56c6c;">关闭</span>
					<span v-else>开启</span>
				</template>
			</el-table-column>
			<el-table-column prop="customTag" label="字段配置" align="center" width="800">
				<template #default="scope">
					<el-table :data="scope.row.customizeList" border class="table" ref="multipleTable"
						header-cell-class-name="table-header" :row-style="{ height: '20px' }"
						:cell-style="{ padding: '6px' }" :header-cell-style="{ 'text-align': 'center' }">
						<el-table-column prop="type" label="类型" align="center" width="120">
							<template #default="scope">
								<span v-if="scope.row.type == 'multiline-text'">文本</span>
								<span v-if="scope.row.type == 'date'">日期</span>
								<span v-if="scope.row.type == 'time'">时间</span>
								<span v-if="scope.row.type == 'radio-drop-down-box'">单选下拉</span>
								<span v-if="scope.row.type == 'multi-select-drop-down-box'">多选框</span>
							</template>
						</el-table-column>
						<el-table-column prop="label" label="标签名" align="center" width="120"></el-table-column>
						<el-table-column prop="options" label="选项" align="center">
							<template #default="scope">
								<span class="tag-style" v-for="(item, index) in scope.row.options">{{ item.str }}</span>
							</template>
						</el-table-column>
					</el-table>
				</template>
			</el-table-column>
			<el-table-column prop="source" label="来源" align="center">
				<template #default="scope">
					<span class="tag-style" v-for="(item, index) in scope.row.source">{{ item }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="schedule" label="跟进阶段配置" align="center">
				<template #default="scope">
					<span class="tag-style" v-for="(item, index) in scope.row.schedule">{{ item }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="release" label="释放原因" align="center">
				<template #default="scope">
					<span class="tag-style" v-for="(item, index) in scope.row.release">{{ item }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="" label="操作" align="center" fixed="right" width="220">
				<template #default="scope">
					<el-button size="small" type="primary" v-if="isAgent||isAdmin"
						@click="toApplication(scope.row)">应用</el-button>
					<el-button size="small" type="primary" v-if="isCompany"
						@click="toApplication1(scope.row)">应用</el-button>
					<el-button size="small" type="text" icon="el-icon-edit" v-if="isAdmin"
						@click="handleEdit(scope.$index, scope.row)">编辑</el-button>
					<el-button size="small" type="text" icon="el-icon-delete" style="color: #f56c6c;" v-if="isAdmin"
						@click="handleDelete(scope.$index, scope.row)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="pagination">
			<el-pagination background layout="total, prev, pager, next" :current-page="query.pageIndex"
				:page-size="query.pageSize" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
		</div>
		<el-dialog v-model="addVisible" title="添加模板" width="1000px" :close-on-click-modal="false">
			<el-form :model="query" ref="query" label-width="100px">
				<el-form-item label="模版名称:" prop="templateName">
					<el-input size="small" v-model="form.templateName"></el-input>
				</el-form-item>
				<el-form-item label="字段配置:" prop="customTag">
					<el-table :data="customTagData" border class="table" ref="multipleTable"
						header-cell-class-name="table-header" :row-style="{ height: '20px' }"
						:cell-style="{ padding: '6px' }" :header-cell-style="{ 'text-align': 'center' }"
						:row-class-name="tableRowClassName" @selection-change="handleSelectionChange">
						<el-table-column prop="type" label="类型" align="center">
							<template #default="scope">
								<span v-if="scope.row.type == 'multiline-text'">文本</span>
								<span v-if="scope.row.type == 'date'">日期</span>
								<span v-if="scope.row.type == 'time'">时间</span>
								<span v-if="scope.row.type == 'radio-drop-down-box'">单选下拉</span>
								<span v-if="scope.row.type == 'multi-select-drop-down-box'">多选框</span>
							</template>
						</el-table-column>
						<el-table-column prop="label" label="标签名" align="center"></el-table-column>
						<el-table-column prop="options" label="选项" align="center">
							<template #default="scope">
								<el-tag style="margin: 4px;"
									v-for="(item, index) in scope.row.options">{{ item }}</el-tag>
							</template>
						</el-table-column>
						<el-table-column prop="" label="操作" align="center">
							<template #default="scope">
								<el-button size="small" @click="editCustomTagData(scope.row)">修改</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-button size="small" @click="addDataToTable">+标签名</el-button>
				</el-form-item>
				<el-form-item label="跟进阶段配置:" prop="followProgress">
					<el-tag class="m_r_10" v-for="tag in followProgressList" :key="tag" closable
						:disable-transitions="false" @close="handleClose1(tag)">{{ tag }}</el-tag>
					<el-input size="small" v-if="followProgressinput" v-model="form.followProgressinputValue"
						ref="saveTagInput" @keyup.enter.native="followProgressConfirm"
						@blur="followProgressConfirm"></el-input>
					<el-button size="small" @click="showfollowProgressinput">+标签名</el-button>
				</el-form-item>
				<el-form-item label="来源配置:" prop="source">
					<el-tag class="m_r_10" v-for="tag in sourceList" :key="tag" closable :disable-transitions="false"
						@close="handleClose2(tag)">{{ tag }}</el-tag>
					<el-input size="small" v-if="sourceinput" v-model="form.sourceinputValue" ref="sourceInput"
						@keyup.enter.native="sourceConfirm" @blur="sourceConfirm"></el-input>
					<el-button size="small" @click="showsourceinput">+标签名</el-button>
				</el-form-item>
				<el-form-item label="释放原因:" prop="release">
					<el-tag class="m_r_10" :key="tag" v-for="tag in releaseList" closable :disable-transitions="false"
						@close="handleClose3(tag)">{{ tag }}</el-tag>
					<el-input size="small" v-if="releaseinput" v-model="form.releaseinputValue" ref="releaseInput"
						@keyup.enter.native="releaseConfirm" @blur="releaseConfirm"></el-input>
					<el-button size="small" @click="showreleaseinput">+标签名</el-button>
				</el-form-item>

				<el-form-item label="自动回收:" prop="automaticRecycling">
					<el-switch v-model="automaticRecycling" active-color="#13ce66" inactive-color="#ff4949"
						class="m_r_10"></el-switch>
				</el-form-item>
				<el-form-item label="成交客户回收" prop="complete" v-if="automaticRecycling">
					<el-switch v-model="form.complete" active-color="#13ce66" inactive-color="#ff4949"
						class="m_r_10"></el-switch>
				</el-form-item>
				<div style="margin-left: 42px;" v-if="automaticRecycling">回收超出<el-select size="small" v-model="form.day"
						style="width: 120px;margin:0 6px;" filterable allow-create clearable>
						<el-option v-for="item in dayList" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>天未跟进客户自动回收</div>
				<el-form-item style="display: flex;flex-direction: row;justify-content:end;">
					<el-button size="small" @click="closeDialog">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="getaddCrmTemplate">确认</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="添加字段配置" v-model="tagVisible" width="500px" @close="closetagDialog"
			:close-on-click-modal="false">
			<el-form :model="addTagform" ref="addTagform" label-width="100px">
				<el-form-item label="标签类型">
					<el-select size="small" v-model="addTagform.type" placeholder="请选择标签类型" style="width:100%">
						<el-option v-for="item in protectionLevel" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="标签名称" prop="label"><el-input size="small"
						v-model="addTagform.label"></el-input></el-form-item>
				<el-form-item label="标签选项" prop="options"
					v-if="addTagform.type === 'radio-drop-down-box' || addTagform.type === 'multi-select-drop-down-box'">
					<el-tag style="margin-right: 10px;" :key="tag" v-for="tag in addTagform.options" closable
						:disable-transitions="false" @close="handleClose4(tag)">{{ tag }}</el-tag>
					<el-input size="small" class="input-new-tag" v-if="customTagVisible"
						v-model="addTagform.customTaginputValue" ref="customTagInput"
						@keyup.enter.native="customTagInputConfirm" @blur="customTagInputConfirm"></el-input>
					<el-button size="small" class="button-new-tag" @click="showTagInput">+标签名</el-button>
				</el-form-item>
				<el-form-item class="dialog-footer" style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="closetagDialog">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="addCustomize">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="应用模版" v-model="applicationVisible" width="500px" @close="closeApplicationDialog"
			:close-on-click-modal="false">
			<el-form :model="form" ref="form" label-width="120px">
				<el-form-item label="代理商:" v-if="isAdmin">
					<el-select size="small" @change="agentChange" v-model="form.agentId" filterable clearable
						placeholder="代理商" style="width: 100%;">
						<el-option v-for="item in agents" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="子代理:" v-if="isSubAgent">
					<el-select size="small" v-model="form.subAgentId" @change="getfetchDefaultCompanies" filterable
						clearable placeholder="子代理" style="width: 100%;">
						<el-option v-for="item in subAgentList" :key="item.id" :label="item.name"
							:value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="企业:" v-if="isAgent">
					<el-select size="small" v-model="form.companyId" @change="companyChange" filterable clearable
						placeholder="企业" style="width: 100%;">
						<el-option v-for="item in companys" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="dialog-footer" style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" type="primary" :class="[theme]" @click="getuseCrmTemplates">应用</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>


		<el-dialog title="修改字段配置" v-model="editCustomVisible" width="500px" @close="closeeditDialog"
			:close-on-click-modal="false">
			<el-form :model="editCustomform" ref="editCustomform" label-width="100px">
				<el-form-item label="标签类型">
					<el-select size="small" v-model="editCustomform.type" placeholder="请选择标签类型" style="width:100%">
						<el-option v-for="item in protectionLevel" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="标签名称" prop="label">
					<el-input size="small" v-model="editCustomform.label"></el-input>
				</el-form-item>
				<el-form-item label="标签选项" prop="options"
					v-if="editCustomform.type === 'radio-drop-down-box' || editCustomform.type === 'multi-select-drop-down-box'">
					<el-tag style="margin-right: 10px;" :key="tag" v-for="tag in editCustomform.options" closable
						:disable-transitions="false" @close="handleClose6(tag)">{{ tag }}</el-tag>
					<el-input size="small" class="input-new-tag" v-if="customTagEditVisible"
						v-model="editCustomform.customTaginputValue" ref="customTagEditInput"
						@keyup.enter.native="customTagEditInputConfirm" @blur="customTagEditInputConfirm"></el-input>
					<el-button size="small" class="button-new-tag" @click="showEditTagInput">+标签名</el-button>
				</el-form-item>
				<el-form-item class="dialog-footer" style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="closeeditDialog">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="closeeditDialog">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import {
		findCrmTemplate,
		addCrmTemplate,
		deleteCrmTemplate,
		useCrmTemplates
	} from '../api/dijia.js';
	fetchCompanyByAgentId
	fetchDefaultCompanies
	fetchDepartment

	import {
		fetchDepartment
	} from '../api/index.js';
	import {
		fetchCompanyByAgentId,
		fetchDefaultCompanies
	} from '../api/companyIndex';
	import {
		fetchAgent
	} from '../api/agentIndex';

	import {
		querySubAgent
	} from '../api/serialIndex.js';
	export default {
		name: 'modou',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				tableData: [],
				query: {
					pageIndex: 1,
					pageSize: 10,
					companyId: ''
				},
				pageTotal: 0,
				addVisible: false,
				tagVisible: false,
				customTagVisible: false,

				customTagList: [], //字段列表
				customTagData: [], //字段列表


				followProgressList: [], //跟进阶段列表
				followProgressinput: false,

				sourceList: [], //来源列表
				sourceinput: false,

				releaseList: [], //释放列表
				releaseinput: false,
				form: {
					templateName: '',
					releaseinputValue: '',
					followProgressinputValue: '',
					sourceinputValue: '',
					customTaginputValue: '',
					complete: false,
					day: '',
				},
				automaticRecycling: false,
				dayList: [{
					value: "3",
					label: '3天',
				}, {
					value: "7",
					label: '7天',
				}, {
					value: "15",
					label: '15天',
				}, {
					value: "30",
					label: '30天',
				}],
				addTagform: {
					type: "", //类型
					label: "", //名称
					options: [],
					customTaginputValue: ''
				},
				protectionLevel: [{
						value: 'multiline-text',
						label: '文本'
					},
					{
						value: 'date',
						label: '日期'
					},
					{
						value: 'time',
						label: '时间'
					},
					{
						value: 'radio-drop-down-box',
						label: '单选下拉'
					},
					{
						value: 'multi-select-drop-down-box',
						label: '多选'
					}
				],
				uid: '',
				role: "",
				isAdmin: false,
				isCompany: false,
				isAgent: false,
				isSubAgent: false,
				subAgent: '',
				applicationVisible: false,
				applicationData: '',

				customTagEditVisible: false,
				editCustomVisible: false,
				editCustomform: {
					type: "",
					label: "",
					type: "",
					customTaginputValue: "",
					options: []
				}

			}
		},
		created() {
			this.role = localStorage.getItem('ms_role');
			this.subAgent = localStorage.getItem('subAgent');

			if (this.role === 'company') {
				this.isCompany = true;
			}
			if (this.role === 'agent') {
				this.isAgent = true;
				if (this.subAgent == 'true') {
					this.isSubAgent = true;
					this.getquerySubAgent();
				} else {
					this.isSubAgent = false;
				}
			}
			if (this.role === 'admin') {
				this.isAdmin = true;
				this.isAgent = true;
			}
			if (this.role === 'subAgent') {
				this.isAgent = true;
				this.issubAgent = true;
			}
			this.init();
			this.getfindCrmTemplate()
		},
		methods: {
			init() {
				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
				});

				if (this.subAgent == true) {
					this.isSubAgent = true;
					this.getquerySubAgent();
				}
				this.getfetchDefaultCompanies();
				let data = {
					companyId: '',
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
					if (localStorage.getItem('ms_role') == 'company') {
						this.getfindCrmTemplate();
					}
				});
			},
			addDataToTable() {
				this.addTagform.type = '';
				this.addTagform.label = '';
				this.addTagform.options = [];
				this.tagVisible = true;
			},

			// tableRowClassName({
			// 	rowIndex
			// }) {
			// 	if ((rowIndex + 1) % 2 === 0) {
			// 		return 'oddRow';
			// 	}
			// 	return 'evenRow';
			// },
			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getfindCrmTemplate();
			},
			getfindCrmTemplate() {
				findCrmTemplate(this.query).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			transformList(customizeList) {
				let data = [];
				for (let item of customizeList) {
					let transformedItem = {
						type: item.type,
						label: item.label,
						options: []
					};
					if (item.options && item.options.length > 0) {
						transformedItem.options = item.options.map(option => option.str);
					}
					data.push(transformedItem);
				}
				return data;
			},
			handleEdit(index, row) {
				this.customTagData = this.transformList(row.customizeList);
				this.followProgressList = row.schedule;
				this.releaseList = row.release;
				this.sourceList = row.source;
				this.automaticRecycling = row.recycle;
				this.form.complete = row.complete;
				this.form.day = row.day;
				this.uid = row.id;
				this.form.templateName = row.name;
				this.addVisible = true;

			},
			showForm() {
				this.customTagData = [];
				this.followProgressList = [];
				this.releaseList = [];
				this.sourceList = [];
				this.automaticRecycling = false;
				this.form.complete = false;
				this.form.day = '';
				this.form.templateName = '';
				this.uid = '';
				this.addVisible = true;
			},
			closeDialog() {
				this.addVisible = false;
			},
			closetagDialog() {
				this.tagVisible = false;
			},

			showTagInput() {
				this.customTagVisible = true;
				this.$nextTick(_ => {
					this.$refs.customTagInput.$refs.input.focus();
				});
			},

			customTagInputConfirm() {
				const customTaginputValue = this.addTagform.customTaginputValue;
				if (typeof customTaginputValue === 'string' && customTaginputValue.trim() !== '') {
					this.addTagform.options.push(customTaginputValue);
				}
				this.customTagVisible = false;
				this.addTagform.customTaginputValue = '';
			},
			addCustomize() {
				this.customTagData.push({
					...this.addTagform
				});
				this.tagVisible = false;
			},

			showfollowProgressinput() {
				this.followProgressinput = true;
				this.$nextTick(_ => {
					this.$refs.saveTagInput.$refs.input.focus();
				});
			},
			followProgressConfirm() {
				const followProgressinputValue = this.form.followProgressinputValue;
				if (typeof followProgressinputValue === 'string' && followProgressinputValue.trim() !== '') {
					this.followProgressList.push(followProgressinputValue);
				}
				this.followProgressinput = false;
				this.form.followProgressinputValue = '';
			},


			showsourceinput() {
				this.sourceinput = true;
				this.$nextTick(_ => {
					this.$refs.sourceInput.$refs.input.focus();
				});
			},
			sourceConfirm() {
				const sourceinputValue = this.form.sourceinputValue;
				if (typeof sourceinputValue === 'string' && sourceinputValue.trim() !== '') {
					this.sourceList.push(sourceinputValue);
				}
				this.sourceinput = false;
				this.form.sourceinputValue = '';
			},


			showreleaseinput() {
				this.releaseinput = true;
				this.$nextTick(_ => {
					this.$refs.releaseInput.$refs.input.focus();
				});
			},
			releaseConfirm() {
				const releaseinputValue = this.form.releaseinputValue;
				if (typeof releaseinputValue === 'string' && releaseinputValue.trim() !== '') {
					this.releaseList.push(releaseinputValue);
				}
				this.releaseinput = false;
				this.form.releaseinputValue = '';
			},
			getaddCrmTemplate() {
				let data = {
					userId: localStorage.getItem('user'),
					templateName: this.form.templateName,
					schedule: this.followProgressList.join(','),
					source: this.sourceList.join(','),
					release: this.releaseList.join(','),
					recycle: this.automaticRecycling,
					complete: this.form.complete,
					day: this.form.day,
					customizeList: this.customTagData,
					id: this.uid,
				}
				addCrmTemplate(data).then(res => {
					if (res.code == 200) {
						this.$message.success(res.data);
						this.addVisible = false;
						this.getfindCrmTemplate();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			//删除
			handleDelete(index, row) {
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						let data = {
							id: row.id,
							userId: localStorage.getItem('user')
						};
						deleteCrmTemplate(data).then(res => {
							if (res.code == 200) {
								this.$message.success('删除成功');
								this.pageTotal = this.pageTotal - 1;
								this.getfindCrmTemplate();
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
			},

			handleClose1(tag) {
				this.followProgressList.splice(this.followProgressList.indexOf(tag), 1);
			},
			handleClose2(tag) {
				this.sourceList.splice(this.sourceList.indexOf(tag), 1);
			},
			handleClose3(tag) {
				this.releaseList.splice(this.releaseList.indexOf(tag), 1);
			},
			handleClose4(tag) {
				this.addTagform.options.splice(this.addTagform.options.indexOf(tag), 1);
			},
			handleClose5(item, row) {
				this.addTagform.options.splice(this.addTagform.options.indexOf(item), 1);
			},
			closeApplicationDialog() {
				this.applicationVisible = false;
				this.applicationData = '';
			},
			toApplication(row) {
				this.applicationVisible = true;
				this.applicationData = row;
			},
			toApplication1(row) {
				this.applicationData = row;
				this.getuseCrmTemplates();
			},
			getuseCrmTemplates() {
				this.$confirm('应用模板后,已录入系统数据自定义信息将会重置,无法继承，客户跟进阶段也会重置，请谨慎操作！', '提示', {
						type: 'warning'
					})
					.then(() => {
						if (this.role == 'company') {
							this.form.companyId = localStorage.getItem('company')
						}
						let data = {
							id: this.applicationData.id,
							companyId: this.form.companyId
						};
						useCrmTemplates(data).then(res => {
							if (res.code == 200) {
								this.$message.success(res.data);
								this.applicationVisible = false;
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
			},
			agentChange(agentId) {
				this.query.agentId = agentId;
				this.query.companyId = '';
				if (agentId) {
					let data = {
						subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
					});
				}
			},
			getfetchDefaultCompanies() {
				this.query.companyId = '';
				let data = {
					currentUserId: localStorage.getItem('user'),
					subAgentId: this.query.subAgentId
				};
				fetchDefaultCompanies(data).then(res => {
					this.companys = res.data;
				});
			},
			companyChange(companyId) {
				this.query.companyId = companyId;
				this.companys.forEach(item => {
					if (companyId == item.value) {
						this.companyName = item.label;
					}
				});
				let data = {
					companyId: companyId,
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
					this.query.pageIndex = 1;
					this.getfindCrmTemplate();
				});
			},
			handleClose6(tag) {
				this.editCustomform.options.splice(this.editCustomform.options.indexOf(tag), 1);
			},
			editCustomTagData(row) {
				// this.editCustomform.label = '';
				// this.editCustomform.type = '';
				this.editCustomform = row;
				this.editCustomVisible = true;
			},
			closeeditDialog() {
				this.editCustomVisible = false;
			},
			showEditTagInput() {
				this.customTagEditVisible = true;
				this.$nextTick(_ => {
					this.$refs.customTagEditInput.$refs.input.focus();
				});
			},
			customTagEditInputConfirm() {
				const customTaginputValue = this.editCustomform.customTaginputValue;
				if (typeof customTaginputValue === 'string' && customTaginputValue.trim() !== '') {
					this.editCustomform.options.push(customTaginputValue);
				}
				this.customTagEditVisible = false;
				this.editCustomform.customTaginputValue = '';
			},

		}
	}
</script>


<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.el-table .el-table__body tr:hover>td {
		background-color: red !important;
	}

	.tag-style {
		margin: 2px;
		display: inline-block;
		background-color: #ecf5ff;
		border-color: #d9ecff;
		padding: 0 6px;
		font-size: 12px;
		color: #409EFF;
		border-width: 1px;
		border-style: solid;
		border-radius: 4px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}
</style>