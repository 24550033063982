<template>
	<div>
		<div class="container">
			<div class="handle-box">

				<el-button size="small" type="primary" :class="[theme]" class="handle-add"
					@click="showForm">添加</el-button>
			</div>
			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				:row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }"
				:header-cell-style="{ 'text-align': 'center' }" :row-class-name="tableRowClassName">
				<el-table-column prop="account" width="240" label="账户" align="center"></el-table-column>
				<el-table-column prop="name" width="280" label="权限" align="center"></el-table-column>
				<el-table-column prop="password" width="220" label="密码" align="center">
					<template #default="scope">
						<span>********</span>
						<i class="el-icon-document-copy" style="margin-left: 6px;cursor: pointer;"
							@click="pasteText(scope.row.password)"></i>
					</template>
				</el-table-column>
				<el-table-column prop="enable" label="是否开启" align="center">
					<template #default="scope">
						<el-switch v-model="scope.row.enable" active-color="#13ce66" inactive-color="#ff4949"
							@change="getAdminRoleStop(scope.row)"></el-switch>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="340" align="center">
					<template #default="scope">
						<el-button size="small" type="text" @click="getAdminRoleResetPwd(scope.row.id)">重置密码</el-button>
						<el-button size="small" type="text" icon="el-icon-delete" style="color: #f56c6c;"
							@click="handleDelete(scope.row.id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<el-dialog title="添加" v-model="addVisible" width="500px" @close="closeDialog" :close-on-click-modal="false">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="账号" prop="phone">
					<el-input size="small" v-model="form.phone" placeholder="请输入手机号"></el-input>
				</el-form-item>
				<el-form-item label="姓名" prop="name">
					<el-input size="small" v-model="form.name" placeholder="请输入管理员姓名"></el-input>
				</el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: flex-end;">
					<el-button size="small" @click="closeDialog">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="getAddAdminRole">确定</el-button>
				</el-form-item>
			</el-form>

		</el-dialog>

	</div>
</template>

<script>
	import {
		adminRoleList,
		adminRoleStop,
		adminRoleResetPwd,
		addAdminRole,
		adminRoleDelete
	} from '../api/companyIndex.js';

	export default {
		name: 'administratorAccount',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				tableData: [],
				addVisible: false,
				form: {
					userId: localStorage.getItem('user'),
					phone: '',
					name: ''
				},
				pastedText: ''
			};
		},
		created() {
			this.getData();
		},
		methods: {
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			//列表
			getData() {
				let data = {
					userId: localStorage.getItem('user')
				}
				adminRoleList(data).then(res => {
					if (res.code == 200) {
						this.tableData = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},

			//显示添加
			showForm() {
				this.addVisible = true;
				this.form.phone = '';
				this.form.name = '';
			},
			//复制
			pasteText(item) {
				let cInput = document.createElement('input');
				cInput.value = item;
				document.body.appendChild(cInput);
				cInput.select();
				document.execCommand('copy');
				this.$message({
					message: '复制成功'
				});
				document.body.removeChild(cInput);
			},
			//开启关闭
			getAdminRoleStop(row) {
				let data = {
					userId: localStorage.getItem('user'),
					id: row.id,
					enable: row.enable
				};
				adminRoleStop(data).then(res => {
					if (res.code == 200) {
						this.$message.success(res.data);
					} else {
						this.$message.error(res.message);
					}
					this.getData();
				});
			},
			//重置密码
			getAdminRoleResetPwd(item) {
				let data = {
					userId: localStorage.getItem('user'),
					id: item,
				};
				adminRoleResetPwd(data).then(res => {
					if (res.code == 200) {
						this.$message.success(res.message);
					} else {
						this.$message.error(res.message);
					}
					this.getData();
				});
			},

			//添加
			getAddAdminRole() {
				let data = {
					userId: localStorage.getItem('user'),
					phone: this.form.phone,
					name: this.form.name
				};
				addAdminRole(data).then(res => {
					if (res.code == 200) {
						this.$message.success(res.message);
						this.addVisible = false;
						this.getData();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			//删除
			handleDelete(id) {
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						let data = {
							id: id,
							userId: localStorage.getItem('user'),
						}
						adminRoleDelete(data).then(res => {
							if (res.code == 200) {
								this.$message.success(res.data);
								this.getData();
							} else {
								this.$message.error(res.message);
								this.getData();
							}
						});
					})
					.catch(() => {});
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	/* a:hover{background: #66b1ff} */
	.handle-select {
		width: 240px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #f56c6c;
	}

	.mr10 {
		margin-right: 10px;
	}

	.mr100 {
		position: absolute;
		right: 100px;
	}

	.myRight {
		position: absolute;
		right: 10px;
		bottom: 10px;
	}

	.handle-input {
		width: 200px;
		display: inline-block;
	}
</style>