import request from '../utils/request';
import qs from "qs";

//获取标签
export const recordLabel = param => {
	return request.get('/api/company/recordLabel?userId=' + param.userId + '&&companyId=' + param.companyId)
};
//获取表头设置
export const getColumnSetting = userId => {
	return request.get('/api/intentionCustomer/getColumnSetting?userId=' + userId)
};
//增加标签
export const addRecordLabel = param => {
	return request.post('/api/company/addRecordLabel', qs.stringify(param))
};
//删除标签
export const deleteRecordLabel = param => {
	return request.post('/api/company/deleteRecordLabel', qs.stringify(param))
};
//修改标签

//坐席数据列表
export const getfindStatus = param => {
	return request.post('/api/user/findStatus', qs.stringify(param))
};
//导出坐席数据列表
export const downloadStatus = param => {
	return request.post('/api/user/downloadStatus', qs.stringify(param), {
		responseType: 'blob'
	})
};



//列表展示系统字段列表
export const getwebListCustomize = param => {
	return request.post('/api/customize/webListCustomize', qs.stringify(param))
};
// 修改系统字段
export const updateCustomize = param => {
	return request.post('/api/customize/updateCustomize', param, {
		headers: {
			'Content-Type': 'application/json'
		}
	})
};
//属性启用
export const updateCustomerCustomize = param => {
	return request.post('/api/customize/updateCustomerCustomize', qs.stringify(param))
};
// 添加客户系统字段
export const addCustomize = param => {
	return request.post('/api/customize/addCustomize', param, {
		headers: {
			'Content-Type': 'application/json'
		}
	})
};
//查询详情
export const findCustomizeByUid = param => {
	return request.post('/api/customize/findCustomizeByUid', qs.stringify(param))
};
//删除字段
export const deleteCustomize = param => {
	return request.post('/api/customize/deleteCustomize', qs.stringify(param))
};
//删除客户来源
export const deleteCustomerSource = param => {
	return request.post('/api/customize/deleteCustomerSource', qs.stringify(param))
};
// 添加客户来源
export const addCustomerSource = param => {
	return request.post('/api/customize/addCustomerSource', param, {
		headers: {
			'Content-Type': 'application/json'
		}
	})
};
//获取自定义来源列表
export const getCustomerSource = companyId => {
	return request.get('/api/customize/getCustomerSource?companyId=' + companyId)
};
// 添加意向客户
export const addIntentionCustomer = param => {
	return request.post('/api/intentionCustomer/addIntentionCustomer', param, {
		headers: {
			'Content-Type': 'application/json'
		}
	})
};
//意向客户详情
export const findDetails = id => {
	return request.get('/api/intentionCustomer/findDetails?id=' + id)
};
//获取自定义进度列表
export const getCustomerSchedule = companyId => {
	return request.get('/api/customize/getCustomerSchedule?companyId=' + companyId)
};
//添加进度
export const addCustomerSchedule = param => {
	return request.post('/api/customize/addCustomerSchedule', qs.stringify(param))
};
//删除进度
export const deleteCustomerSchedule = param => {
	return request.post('/api/customize/deleteCustomerSchedule', qs.stringify(param))
};
//编辑进度
export const updateCustomerSchedule = param => {
	return request.post('/api/customize/updateCustomerSchedule', qs.stringify(param))
};
//释放原因列表
export const getCustomerRelease = companyId => {
	return request.get('/api/customize/getCustomerRelease?companyId=' + companyId)
};
//添加释放原因
export const addCustomerRelease = param => {
	return request.post('/api/customize/addCustomerRelease', qs.stringify(param))
};
//删除释放原因
export const deleteCustomerRelease = param => {
	return request.post('/api/customize/deleteCustomerRelease', qs.stringify(param))
};

//蜗牛充值支付
export const woniuPay = param => {
	return request.post('/api/topUp/pay', param, {
		headers: {
			'Content-Type': 'application/json'
		}
	})
};
//支付后查询结果
export const queryOrder = param => {
	return request.post('/api/topUp/queryOrder', qs.stringify(param))
};

//蜗牛充值数据列表 
export const woniuDataList = param => {
	return request.post('/api/topUp/list', qs.stringify(param))
};

//蜗牛充值批量处理订单 
export const finish = param => {
	return request.post('/api/topUp/finish', qs.stringify(param))
};
//蜗牛充值是否开启
export const getenabled = param => {
	return request.post('/api/topUp/enabled', qs.stringify(param))
};

//蜗牛充值关闭
export const getStop = param => {
	return request.post('/api/topUp/stop', qs.stringify(param))
};

//导出
export const download = param => {
	return request.post('/api/topUp/download', qs.stringify(param), {
		responseType: 'blob'
	})
};
//查询是否开启app蜗牛充值
export const dxtEnabled = param => {
	return request.post('/api/topUp/enabled', qs.stringify(param))
};
//列表展示系统字段列表
export const getListCustomize = param => {
	return request.post('/api/customize/webListCustomize', qs.stringify(param))
};
//修改表头设置
export const updateColumnSetting = param => {
	return request.post('/api/intentionCustomer/updateColumnSetting', qs.stringify(param))
};
//修改是否crm启用字段
export const crmCustomizeEnable = param => {
	return request.post('/api/customize/crmCustomizeEnable', qs.stringify(param))
};
//编辑crm自定义字段的文本或者时间
export const updateCustomizeName = param => {
	return request.post('/api/customize/updateCustomizeName', qs.stringify(param))
};
//编辑crm修改单选多选选择框
export const updateCustomizeContent = param => {
	return request.post('/api/customize/updateCustomizeContent', param, {
		headers: {
			'Content-Type': 'application/json'
		}
	})
};
//标记查询  获取排队信息  
export const getQueueUpInfo = param => {
	return request.get('/api/markers/getQueueUpInfo')
};