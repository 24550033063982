<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-input size="small" v-model="query.phone" clearable maxlength="12" placeholder="号码"
					style="width: 180px;" class="handle-input mr10"></el-input>

				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
					@click="handleSearch">搜索</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="showAdd">添加</el-button>
			</div>
			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				:row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }"
				:header-cell-style="{ 'text-align': 'center' }" :row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange">
				<el-table-column prop="phone" label="号码" align="center"></el-table-column>
				<el-table-column prop="createdAt" label="添加时间" align="center"></el-table-column>
				<el-table-column label="操作" width="190" align="center">
					<template #default="scope">
						<el-button size="small" type="text" icon="el-icon-delete" style="color: #f56c6c;"
							@click="handleDelete(scope.$index, scope.row.id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, prev, pager, next" :current-page="query.pageIndex"
					:page-size="query.pageSize" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
			</div>

			<el-dialog v-model="addVisible" width="650px" @close="dialogClose">
				<el-form :model="form" ref="form" label-width="100px">
					<el-form-item label="黑名单号码" prop="phones">
						<el-input size="small" type="textarea" :rows="5" placeholder="一行一个黑名单号码,一次最多添加100个号码"
							v-model="form.phones"></el-input>
					</el-form-item>
					<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
						<el-button size="small" @click="dialogClose">取消</el-button>
						<el-button type="primary" :class="[theme]" size="small" @click="getRiskCall">确认</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import { riskCallList, riskCall, riskCallDelete } from '../api/setMeal';
	export default {
		name: 'blackNameList',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					userId: localStorage.getItem('user'),
					phone: '',
					pageIndex: 1,
					pageSize: 30
				},
				tableData: [],
				pageTotal: 0,
				form: {
					phones: '',
					userId: localStorage.getItem('user'),
				},
				addVisible: false,
			};
		},

		created() {
			this.getData();
		},
		methods: {
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			getData() {
				this.query.userId = localStorage.getItem('user');
				riskCallList(this.query).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.getData();
			},
			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getData();
			},
			dialogClose() {
				this.addVisible = false;
			},

			showAdd() {
				this.addVisible = true;
				this.form.phones = '';
			},
			getRiskCall() {
				riskCall(this.form).then(res => {
					if (res.code == 200) {
						this.$message.success(res.message);
						this.addVisible = false;
						this.form.phones = '';
						this.getData();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			// 删除操作
			handleDelete(index, id) {
				// 二次确认删除
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						let data = {
							id: id,
							userId: localStorage.getItem('user')
						};
						riskCallDelete(data).then(res => {
							if (res.code == 200) {
								this.$message.success(res.message);
								this.getData();
							} else {
								this.$message.error(res.message);
								this.loading = false;
							}
						});
					})
					.catch(() => {});
			},

		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	/* a:hover {
	background: #66b1ff;
} */
	.handle-select {
		width: 240px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #f56c6c;
	}

	.mr10 {
		margin-right: 10px;
	}

	.mr100 {
		position: absolute;
		right: 100px;
	}

	.myRight {
		position: absolute;
		right: 10px;
		bottom: 10px;
	}

	.handle-input {
		width: 200px;
		display: inline-block;
	}

	.recharge-box .el-radio__input {
		display: none;
	}

	.recharge-box .el-radio.is-bordered {
		padding: 8px 14px 0 5px;
		border-radius: 4px;
		border: 1px solid #dcdfe6;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		height: 30px;
	}
</style>