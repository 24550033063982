<template>
	<div>
		<el-row :gutter="20" type="flex" class="row-bg" justify="space-between">
			<el-col :span="24">
				<div class="dashboard-user-box">
					<div class="left">
						<el-upload class="avatar-uploader" :action="action" :show-file-list="false"
							accept="image/png,image/jpg,image/jpeg" :on-success="handleAvatarSuccess" :headers="headers"
							:data="uploadData">
							<img v-if="headerUrl" :src="headerUrl" class="avatar" />
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
						<div class="info">
							<div class="m_t_10 text_color_000 font_bold" style="font-size: 16px;">
								你好,{{ userInfo.account }}-{{ userInfo.name }},努力工作,快乐生活!</div>
							<div class="m_t_10">
								{{ userInfo.companyName }}
								<span v-if="userInfo.department">-</span>
								{{ userInfo.department }}
							</div>
						</div>
					</div>
					<div class="right">
						<div class="enterprise-title m_t_10" style="font-size: 16px;">总坐席/总企业</div>
						<div class="enterprise-num m_t_10 text_color_000 font_bold">
							{{ userInfo.userSize }}/{{ userInfo.companySize }}
						</div>
					</div>
				</div>
			</el-col>
		</el-row>
		<!-- <el-row :gutter="20" type="flex" class="row-bg" justify="space-between">
			<el-col :span="24">
				<div class="statistics">
					<div class="title">
						<div class="tit" style="font-weight: 600;font-size: 14px;">销售简报</div>
						<div class="tit-btn">
							<el-button type="primary" size="mini" plain :class="[statisticsShow == 4 ? 'active' : '', statisticsShow == 4 ? theme : '']" @click="getTime2('今天')">
								今天
							</el-button>
							<el-button
								type="primary"
								size="mini"
								plain
								:class="[statisticsShow == 5 ? 'active' : '', statisticsShow == 5 ? theme : '']"
								@click="getTime2('前三天')"
							>
								前三天
							</el-button>
							<el-button
								type="primary"
								size="mini"
								plain
								:class="[statisticsShow == 6 ? 'active' : '', statisticsShow == 6 ? theme : '']"
								@click="getTime2('前七天')"
							>
								前七天
							</el-button>
						</div>
					</div>
					<div class="ui-box">
						<ul>
							<li>
								<div class="lat">新增跟进数</div>
								<div>18</div>
							</li>
							<li>
								<div>跟进线索数</div>
								<div>18</div>
							</li>
							<li>
								<div>新增客户数</div>
								<div>18</div>
							</li>
							<li>
								<div>跟进客户数</div>
								<div>18</div>
							</li>
							<li>
								<div>新增回访客户</div>
								<div>18</div>
							</li>
							<li>
								<div>回访客户</div>
								<div>18</div>
							</li>
						</ul>
					</div>
				</div>
			</el-col>
		</el-row> -->
		<el-row :gutter="20">
			<el-col>
				<div class="statistics">
					<div class="title">
						<div class="tit" style="font-weight: 600;font-size: 14px;">仪表盘</div>
						<div class="tit-btn">
							<el-button type="primary" size="mini" plain
								:class="[statisticsShow == 4 ? 'active' : '', statisticsShow == 4 ? theme : '']"
								@click="getTime2('今天')">
								今天
							</el-button>
							<el-button type="primary" size="mini" plain
								:class="[statisticsShow == 5 ? 'active' : '', statisticsShow == 5 ? theme : '']"
								@click="getTime2('前三天')">
								前三天
							</el-button>
							<el-button type="primary" size="mini" plain
								:class="[statisticsShow == 6 ? 'active' : '', statisticsShow == 6 ? theme : '']"
								@click="getTime2('前七天')">
								前七天
							</el-button>
						</div>
					</div>
					<div class="ui-box">
						<ul>
							<li>
								<span class="label-text">拨打量</span>
								<span class="label-num" :class="[theme3]">{{ form.totalCall }}</span>
								<span class="fl_r huanbi" style="color: #FF0000;" v-if="form.totalCallComparison < 0">
									环比 {{ form.totalCallComparison }}%
									<i class=" el-icon-caret-bottom" style="color: #FF0000;"></i>
								</span>
								<span class="fl_r huanbi" style="color: #4ce823;" v-else>
									环比{{ form.totalCallComparison }}%
									<i class="el-icon-caret-top" style="color: #4ce823;"></i>
								</span>
							</li>
							<li>
								<span class="label-text">接通量</span>
								<span class="label-num" :class="[theme3]">{{ form.pickUp }}</span>
								<span class="fl_r huanbi" style="color: #FF0000;" v-if="form.pickUpComparison < 0">
									环比 {{ form.pickUpComparison }}%
									<i class=" el-icon-caret-bottom" style="color: #FF0000;"></i>
								</span>
								<span class="fl_r huanbi" style="color: #4ce823;" v-else>
									环比{{ form.pickUpComparison }}%
									<i class="el-icon-caret-top" style="color: #4ce823;"></i>
								</span>
							</li>

							<li>
								<span class="label-text">意向客户</span>
								<span class="label-num" :class="[theme3]">{{ form.totalIntention }}</span>
								<span class="fl_r huanbi" style="color: #FF0000;"
									v-if="form.totalIntentionComparison < 0">
									环比 {{ form.totalIntentionComparison }}%
									<i class="el-icon-caret-bottom" style="color: #FF0000;"></i>
								</span>
								<span class="fl_r huanbi" style="color: #4ce823;" v-else>
									环比{{ form.totalIntentionComparison }}%
									<i class=" el-icon-caret-top" style="color: #4ce823;"></i>
								</span>
							</li>
							<li>
								<span class="label-text">通话分钟数</span>
								<span class="label-num"
									:class="[theme3]">{{ (form.totalDuration / 60).toFixed(2) }}</span>
								<span class="fl_r huanbi" style="color: #FF0000;"
									v-if="form.totalDurationComparison < 0">
									环比 {{ form.totalDurationComparison }}%
									<i class="el-icon-caret-bottom" style="color: #FF0000;"></i>
								</span>
								<span class="fl_r huanbi" style="color: #4ce823;" v-else>
									环比{{ form.totalDurationComparison }}%
									<i class="el-icon-caret-top" style="color: #4ce823;"></i>
								</span>
							</li>
						</ul>
					</div>
				</div>
			</el-col>
		</el-row>
		<el-row :gutter="20" v-if="admin || agent || company">
			<el-col>
				<div class="statistics">
					<div class="title">
						<div class="tit" style="font-weight: 600;font-size: 14px;">排行榜</div>
						<div class="tit-btn">
							<el-select size="small" v-model="rankTime" filterable clearable placeholder="请选择时间"
								style="margin-right:10px" @change="rankTimeChange">
								<el-option key="2" label="今天" value="today"></el-option>
								<el-option key="1" label="昨天" value="yesterday"></el-option>
								<el-option key="3" label="本周" value="week"></el-option>
								<el-option key="4" label="本月" value="month"></el-option>
							</el-select>
							<el-select size="small" v-model="rankType" filterable clearable placeholder="请选择条件"
								@change="rankTypeChange">
								<el-option key="1" label="拨打数量" value="totalCall"></el-option>
								<el-option key="2" label="接通数量" value="pickUp"></el-option>
								<el-option key="3" label="意向数量" value="totalIntention"></el-option>
								<el-option key="4" label="通话时长" value="totalDuration"></el-option>
							</el-select>
						</div>
					</div>
					<div class="ui-box">
						<ul>
							<li v-for="(item, index) in leaderboardList.slice(0, 3)" :key="index">
								<div class="list" style="position: relative;width: 100%;height: 100%;">
									<div style="width: 25%;position: absolute">
										<img v-if="index == 0" src="../assets/img/loyo-medal-1.png"
											style="position: absolute;margin-top: 10px;" alt="" />
										<img v-if="index == 1" src="../assets/img/loyo-medal-2.png"
											style="position: absolute;margin-top: 10px;" alt="" />
										<img v-if="index == 2" src="../assets/img/loyo-medal-3.png"
											style="position: absolute;margin-top: 10px;" alt="" />
									</div>
									<div style="width: 75%;position: absolute;left: 23%;">
										<div v-if="admin || agent"
											style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;line-height: 20px;">
											<span style="font-weight: 600;">公司：</span>
											{{ item.name }}
										</div>
										<div v-else style="line-height: 20px;">
											<span style="font-weight: 600;">坐席：</span>
											{{ item.name }}
										</div>
										<div style="line-height: 20px;">
											<span style="font-weight: 600;">拨打次数：</span>
											{{ item.totalCall }}
										</div>
										<div style="line-height: 20px;">
											<span style="font-weight: 600;">接通次数：</span>
											{{ item.pickUp }}
										</div>
										<div style="line-height: 20px;">
											<span style="font-weight: 600;">意向次数：</span>
											{{ item.totalIntention || 0 }}
										</div>
										<div style="line-height: 20px;">
											<span style="font-weight: 600;">通话时长：</span>
											{{ (item.totalDuration / 60).toFixed(2) }}分钟
										</div>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</el-col>
		</el-row>
		<div class="clear"></div>
		<el-row :gutter="20">
			<el-col :span="24">
				<el-card shadow="hover">
					<div id="columnMain" ref="columnMain" style="height: 450px;width: 100%;"></div>
				</el-card>
			</el-col>
		</el-row>
		<!-- 无使用记录坐席号码提醒 和到期提醒-->
		<el-row :gutter="20" type="flex" class="row-bg dashboard_box" justify="space-between"
			v-if="admin || agent || company">
			<el-col :span="12">
				<el-card class="box-card" shadow="always" style="width: 100%" id="noRecordBox">
					<div class="clearfix m_b_20" style="position: relative;">
						<span class="text_color_000 fl_l m_r_10"
							style="font-weight: 600;font-size: 14px;">无使用记录号码</span>
						<div
							style="position: absolute;width: 100%;display: flex;flex-direction: row;justify-content: end;">
							<div class="m_r_10">
								<el-select @change="agentChange" v-model="dlName" filterable clearable placeholder="代理商"
									class="handle-select mr10" size="mini" style="width:140px">
									<el-option v-for="item in agents" :key="item.id" :label="item.name"
										:value="item.id"></el-option>
								</el-select>
							</div>
							<div class="fl_r">
								<el-button type="primary" :class="[theme]" icon="el-icon-upload2" size="mini"
									class="active" plain @click="downLoadWarningPhones()">导出</el-button>
								<el-button type="primary" size="mini" plain
									:class="[show == 1 ? 'active' : '', show == 1 ? theme : '']"
									@click="getTime('今天')">今天</el-button>
								<el-button type="primary" size="mini" plain
									:class="[show == 2 ? 'active' : '', show == 2 ? theme : '']"
									@click="getTime('昨天')">昨天</el-button>
								<el-button type="primary" size="mini" plain
									:class="[show == 3 ? 'active' : '', show == 3 ? theme : '']"
									@click="getTime('最近三天')">
									最近三天
								</el-button>
							</div>
						</div>
					</div>

					<el-table ref="tableDataData" max-height="450" :data="tableData" style="width: 100%;"
						v-loading="loading" v-infinite-scroll="load" :infinite-scroll-immediate="false"
						@row-click="showChildren" :row-style="{ height: '20px' }" :cell-style="{ padding: '4px' }"
						:header-cell-style="{ 'text-align': 'center' }" :row-class-name="tableRowClassName">
						<el-table-column property="agentName" label="代理商(销售)" width="190"
							align="center"></el-table-column>
						<el-table-column property="companyName" label="公司名称" align="center"></el-table-column>
						<el-table-column property="childrenSiz" label="号码数量" width="90"
							align="center"></el-table-column>
						<el-table-column property="children" label="" width="120" align="center">
							<template v-slot="scope">
								<el-collapse accordion v-model="activeName">
									<el-collapse-item :name="scope.row.id">
										<div v-for="(item, index) in childrenData" :key="index">{{ item }}</div>
									</el-collapse-item>
								</el-collapse>
							</template>
						</el-table-column>
					</el-table>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="card-l " shadow="always" style="width: 100%" id="beOverdueData">
					<div class="clearfix m_b_20">
						<span class="text_color_000 m_t_10" style="font-weight: 600;font-size: 14px;">到期坐席提醒</span>
						<div class="fl_r m_l_10" style="position: absolute;right: 30px;">
							<el-button style="margin-right: 10px;color:#fff" icon="el-icon-upload2" type="primary"
								:class="[theme]" plain @click="downloadInvalidDatePhones()" size="mini">
								导出
							</el-button>
							<el-select @change="agentChanges" v-model="dlNames" filterable clearable placeholder="代理商"
								class="handle-select mr10" size="mini">
								<el-option v-for="item in agentss" :key="item.id" :label="item.name"
									:value="item.id"></el-option>
							</el-select>
						</div>
					</div>
					<el-table ref="beOverdueData" max-height="450" :data="beOverdue" style="width: 100%;"
						v-loading="loading2" v-infinite-scroll="load2" :infinite-scroll-immediate="false"
						@row-click="showGuoqiChildrens" :row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }"
						:header-cell-style="{ 'text-align': 'center' }" :row-class-name="tableRowClassName">
						<el-table-column prop="companyName" label="公司名称" align="center"></el-table-column>
						<el-table-column prop="phone.jjxs3.length" width="120" label="3天内到期"
							align="center"></el-table-column>
						<el-table-column prop="phone.jjxs7.length" width="120" label="7天内到期"
							align="center"></el-table-column>
						<el-table-column prop="phone.xs3.length" width="120" label="已过期3天"
							align="center"></el-table-column>
						<el-table-column prop="phone.xs7.length" width="120" label="已过期7天"
							align="center"></el-table-column>
					</el-table>
				</el-card>
			</el-col>
		</el-row>
		<el-row :gutter="20" class="row-bg dashboard_box">
			<el-col :span="8">
				<el-card class="box-card card-l row-bg" style="width: 100%" type="flex" justify="space-between">
					<div class="clearfix m_b_20">
						<div class="tit">
							<i class="el-icon-pie-chart" style="margin-right: 6px;line-height: 28px;"
								:class="[theme3]"></i>
							<span style="font-weight: 600;line-height: 28px;font-size: 14px;">意向统计图</span>
						</div>
						<div class="fl_r">
							<el-date-picker style="width: 270px;" v-model="timeArr" type="daterange" unlink-panels
								range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="mini"
								@change="getIntentionTimes"></el-date-picker>
						</div>
					</div>
					<div id="main" ref="main" style="height: 250px;width: 100%;"></div>
				</el-card>
			</el-col>
			<el-col :span="10" v-if="agent || company || normal">
				<el-card class="box-card card-l" style="width: 100%;" type="flex" justify="space-between">
					<div style="width: 100%;max-height: 298px;height: 298px;">
						<div class="clearfix m_b_20 ">
							<i class="el-icon-document" style="line-height: 28px;" :class="[theme3]"></i>
							<span class="m_l_10" style="font-weight: 600;line-height: 28px;font-size: 14px;">系统公告</span>
						</div>
						<div v-if="notices.length > 0">
							<div v-for="(notice, index) in notices.slice(0, 6)" :key="index" class="text"
								@click="showNotice(notice)">
								<span v-if="index == 0"
									class="m_l_10 m_r_10 b_r_50 w_20 color_FFF bg_EF4738">{{ index + 1 }}</span>
								<span v-if="index == 1"
									class="m_l_10 m_r_10 b_r_50 w_20 color_FFF bg_fac21d">{{ index + 1 }}</span>
								<span v-if="index == 2"
									class="m_l_10 m_r_10 b_r_50 w_20 color_FFF bg_4eb053">{{ index + 1 }}</span>
								<span v-if="index == 3"
									class="m_l_10 m_r_10 b_r_50 w_20 color_FFF bg_3f92f1">{{ index + 1 }}</span>
								<span v-if="index > 3"
									class="m_l_10 m_r_10 b_r_50 w_20 color_FFF bg_999">{{ index + 1 }}</span>
								<span>{{ notice.title }}</span>
								<span class="fl_r m_r_10">{{ notice.createdAt }}</span>
							</div>
						</div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="16" v-else>
				<el-card class="box-card card-l" style="width: 100%;" type="flex" justify="space-between">
					<div style="width: 100%;max-height: 298px;height: 298px;">
						<div class="clearfix m_b_20 ">
							<i class="el-icon-document" style="line-height: 28px;" :class="[theme3]"></i>
							<span class="m_l_10" style="font-weight: 600;line-height: 28px;font-size: 14px;">系统公告</span>
						</div>
						<div v-if="notices.length > 0">
							<div v-for="(notice, index) in notices.slice(0, 6)" :key="index" class="text"
								@click="showNotice(notice)">
								<span v-if="index == 0"
									class="m_l_10 m_r_10 b_r_50 w_20 color_FFF bg_EF4738">{{ index + 1 }}</span>
								<span v-if="index == 1"
									class="m_l_10 m_r_10 b_r_50 w_20 color_FFF bg_fac21d">{{ index + 1 }}</span>
								<span v-if="index == 2"
									class="m_l_10 m_r_10 b_r_50 w_20 color_FFF bg_4eb053">{{ index + 1 }}</span>
								<span v-if="index == 3"
									class="m_l_10 m_r_10 b_r_50 w_20 color_FFF bg_3f92f1">{{ index + 1 }}</span>
								<span v-if="index > 3"
									class="m_l_10 m_r_10 b_r_50 w_20 color_FFF bg_999">{{ index + 1 }}</span>
								<span>{{ notice.title }}</span>
								<span class="fl_r m_r_10">{{ notice.createdAt }}</span>
							</div>
						</div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="6" v-if="normal || agent || company">
				<el-card class="box-card" style="width: 100%" type="flex" justify="space-between">
					<div class="clearfix m_b_20">
						<i class="el-icon-user" style="line-height: 28px;" :class="[theme3]"></i>
						<span class="m_l_10" style="font-weight: 600;line-height: 28px;font-size: 14px;">售后服务</span>
					</div>
					<div class="after-sale">
						<ul>
							<li class="list1">
								<div class="tit" v-if="tableData3.serviceWechat">您的客服</div>
								<div class="tit" v-if="tableData3.saleWechat">您的销售</div>
							</li>
							<li>
								<div v-if="tableData3.serviceName">{{ tableData3.serviceName }}</div>
								<div v-if="tableData3.saleName">{{ tableData3.saleName }}</div>
							</li>
							<li>
								<div v-if="tableData3.servicePhone">{{ tableData3.servicePhone }}</div>
								<div v-if="tableData3.salePhone">{{ tableData3.salePhone }}</div>
							</li>
							<li>
								<div v-if="tableData3.serviceWechat"><img :src="http + tableData3.serviceWechat"
										alt="" /></div>
								<div v-if="tableData3.saleWechat"><img :src="http + tableData3.saleWechat" alt="" />
								</div>
							</li>
						</ul>
					</div>
				</el-card>
			</el-col>
		</el-row>
		<el-dialog title="公告" v-model="dialogShow" width="58%" :before-close="handleClose">
			<div style="width: 100%;height: 100%;overflow: hidden;">
				<h2 style="text-align: center;margin-bottom: 10px;font-size: 22px;">{{ noticetitle }}</h2>
				<div style="text-align: center;font-size: 18px;margin: 10px 0 20px 0;">
					发布人：管理员&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;发布时间：{{ noticeTime }}</div>
				<div class="notice-box" style="line-height: 30px;" v-html="noticeContent"></div>
				<img style="width: 98%;margin-top: 20px;" :src="noticeUrl" alt="" />
			</div>
		</el-dialog>
		<el-dialog :title="showGuoqiChildrenTitle" append-to-body v-model="showGuoqiChildren" width="500px"
			:before-close="handleClose">
			<el-form label-width="120px">
				<el-form-item label="公司名称:" style="margin: 0;">{{ companyName }}</el-form-item>
				<el-form-item :label="showGuoqiChildrenTitle + '号码:'"><el-input size="small" type="textarea" :rows="5"
						v-model="children2Data"></el-input></el-form-item>
			</el-form>
		</el-dialog>
		<div class="clear"></div>
		<div class="footer">{{ webInfo.information }}</div>
	</div>
</template>

<script>
	import {
		getHomePageInfoWithUser,
		getMonthInfo,
		getFindAgent,
		getWarNingCompany,
		getWarNingPhone,
		downLoadWarningPhone,
		downloadInvalidDatePhone,
		getYiXiangData,
		webGetHeadPortrait,
		getUserInfo,
		getLeaderboard
	} from '../api/sumIndex.js';
	import { findAfterSaleByRole } from '../api/agentIndex';
	import { fetchCompanyByAgentId } from '../api/companyIndex.js';
	import { notice } from '../api/notice.js';
	import { getOemInfo } from '../api/index.js';
	import { querySubAgent } from '../api/serialIndex.js';
	export default {
		data() {
			return {
				theme: localStorage.getItem('theme'),
				theme2: localStorage.getItem('theme2'),
				theme3: localStorage.getItem('theme3'),
				theme4: localStorage.getItem('theme4'),
				dialogShow: false,
				childrenData: [],
				statisticsShow: 4,
				statisticsShow2: 1,
				time2: '今天',
				action: '/api/user/webUploadPicture', //上传头像地址
				noticeContent: '',
				name: localStorage.getItem('ms_username'),
				query: {
					companyId: '',
					pageIndex: 1,
					pageSize: 50
				},
				query2: {
					companyId: '',
					pageIndex: 1,
					pageSize: 20
				},
				form: {
					totalCall: 0,
					pickUp: 0,
					totalDuration: 0,
					totalIntention: 0
				},
				normal: false,
				company: false,
				admin: false,
				agent: false,
				department: false,
				myChart: null,
				myChart2: null,
				notices: [],
				http: 'http://',
				tableData3: '',
				tableData: [],
				beOverdue: [],
				loading: false,
				loading2: false,

				time: '最近三天',
				show: 3,
				dlName: '',
				dlNames: '', //代理名字
				startTime: new Date(),
				endTime: new Date(),
				headerUrl: '', //头像
				userInfo: '',
				headers: {
					Authorization: localStorage.getItem('token'),
					UserRole: localStorage.getItem('ms_role')
				},
				activeName: '',
				children2Data: [],
				children3Data: [],
				children4Data: [],
				children5Data: [],
				showGuoqiChildren: false,
				showGuoqiChildrenTitle: '',
				companyName: '',
				top1: '',
				top2: '',
				top3: '',
				agentss: [],
				agents: [],
				timeArr: [],
				echartsData: localStorage.getItem('echartsData'),
				leaderboardList: [],
				rankTime: 'yesterday',
				rankType: 'totalCall',
				uploadData: {
					currentUserId: localStorage.getItem('user')
				},
				noticeUrl: '',
				noticetitle: '',
				noticeTime: '',
				webInfo: '',
				subAgent: false,
				isSubAgent: false
			};
		},

		computed: {
			role() {
				return this.name === 'admin' ? '超级管理员' : '普通用户';
			}
		},

		beforeUnmount() {
			if (!this.myChart) {
				return;
			} else {
				this.myChart.dispose();
				this.myChart = null;
			}
			if (!this.myChart2) {
				return;
			} else {
				this.myChart2.dispose();
				this.myChart2 = null;
			}
		},
		created() {
			this.winWidth = (document.body.clientWidth / 1000) * 50 + '%';
			let role = localStorage.getItem('ms_role');
			this.subAgent = localStorage.getItem('subAgent');
			console.log(role, 'role111');
			if (role === 'normal') {
				//员工
				this.normal = true;
				let data = {
					currentUserId: localStorage.getItem('user')
				};
				findAfterSaleByRole(data).then(res => {
					this.tableData3 = res.data[0];
				});
			}
			if (role === 'subAgent') {
				this.agent = true;
			}
			if (role === 'agent') {
				this.agent = true;
				let data = {
					currentUserId: localStorage.getItem('user')
				};
				findAfterSaleByRole(data).then(res => {
					this.tableData3 = res.data[0];
				});
				this.getLeaderboards();
				if (this.subAgent == 'true') {
					this.isSubAgent = true;
					this.getquerySubAgent();
				} else {
					this.isSubAgent = false;
				}
			}

			if (role === 'company') {
				this.company = true;
				let data = {
					currentUserId: localStorage.getItem('user')
				};
				findAfterSaleByRole(data).then(res => {
					this.tableData3 = res.data[0];
				});
				this.getLeaderboards();
			}
			if (role === 'admin') {
				this.admin = true;
				this.getLeaderboards();
			}
			if (role === 'department') {
				this.company = true;
				let data = {
					currentUserId: localStorage.getItem('user')
				};
				findAfterSaleByRole(data).then(res => {
					this.tableData3 = res.data[0];
				});
				this.getLeaderboards();
				this.department = true;
			}
			this.getHomePageInfoWithUsers();
			this.init();
			this.getCanvas();
			this.getOEMagentOem();
			this.getIntentionTime();
		},

		methods: {
			getquerySubAgent() {
				let data = {
					agentId: this.query.agentId,
					userId: localStorage.getItem('user')
				};
				querySubAgent(data).then(res => {
					if (res.code == 200) {
						this.subAgentList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getOEMagentOem() {
				// let url = 'crm.jilinlingshang.com';
				let url = window.location.host;
				getOemInfo(url)
					.then(res => {
						if (res.code == 200) {
							this.webInfo = res.data;
							let linkicon = document.querySelector("link[rel*='icon']") || document.createElement(
								'link');
							linkicon.href = res.data.icon;
							localStorage.setItem('webName', res.data.webName);
						} else {
							this.$message.error('查询失败，请稍后再试！');
						}
					})
					.catch(err => {
						this.$message.error('查询失败，请稍后再试！');
					});
			},
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			init() {
				let data = {
					currentUserId: localStorage.getItem('user')
				};
				getUserInfo(data).then(res => {
					console.log(res.data, '三生三世');
					this.userInfo = res.data;
				});

				webGetHeadPortrait(data).then(res => {
					this.headerUrl = res.data.headPortrait;
				});

				notice(localStorage.getItem('user')).then(res => {
					this.notices = res.data;
				});

				if (this.normal == false) {
					if (this.department == false) {
						let data = {
							currentUserId: localStorage.getItem('user')
						};
						getFindAgent(data).then(res => {
							/* 代理商 */
							if (res.data.length > 0) {
								this.agents = res.data;
								this.agentss = res.data;
								console.log(res.data, '少时诵诗书');
								this.query.companyId = this.agents[0].id;
								this.query2.companyId = this.agents[0].id;
								this.dlName = this.agents[0].name;
								this.dlNames = this.agentss[0].name;
								this.gettableData();
								this.getWarNingPhones();
							}
						});
					}
				}
			},
			getLeaderboards() {
				let data = {
					time: this.rankTime,
					type: this.rankType,
					userId: localStorage.getItem('user')
				};
				getLeaderboard(data).then(res => {
					this.leaderboardList = res.data;
				});
			},
			showChildren(row) {
				this.childrenData = [];
				this.childrenData = row.children;
			},
			showGuoqiChildrens(row, column) {
				this.showGuoqiChildrenTitle = column.label;
				this.companyName = row.companyName;
				this.children2Data = [];
				if (column.label == '3天内到期') {
					if (row.phone.jjxs3.length > 0) {
						this.showGuoqiChildren = true;
						let resultData = row.phone.jjxs3;
						for (let int = 0; int < resultData.length; int++) {
							this.children2Data += resultData[int] + '\r\n';
						}
					}
				} else if (column.label == '7天内到期') {
					if (row.phone.jjxs7.length > 0) {
						this.showGuoqiChildren = true;
						let resultData = row.phone.jjxs7;
						for (let int = 0; int < resultData.length; int++) {
							this.children2Data += resultData[int] + '\r\n';
						}
					}
				} else if (column.label == '已过期3天') {
					if (row.phone.xs3.length > 0) {
						this.showGuoqiChildren = true;
						let resultData = row.phone.xs3;
						for (let int = 0; int < resultData.length; int++) {
							this.children2Data += resultData[int] + '\r\n';
						}
					}
				} else if (column.label == '已过期7天') {
					if (row.phone.xs7.length > 0) {
						this.showGuoqiChildren = true;
						let resultData = row.phone.xs7;
						for (var int = 0; int < resultData.length; int++) {
							this.children2Data += resultData[int] + '\r\n';
						}
					}
				}
			},

			getCanvas() {
				let echarts = require('echarts');
				let data = {
					currentUserId: localStorage.getItem('user')
				};
				getMonthInfo(data).then(res => {
					localStorage.setItem('echartsData', JSON.stringify(res.data));
					let data = res.data;
					let option = {
						legend: {
							tooltip: {
								show: true,
								color: 'red'
							},
							data: [{
									name: '拨打次数',
									icon: 'circle'
								},
								{
									name: '接通次数',
									icon: 'circle'
								},
								{
									name: '通话分钟数',
									icon: 'circle'
								}
							]
						},
						tooltip: {
							padding: 5,
							textStyle: {
								color: '#000' //---提示框内容样式
							}
						},
						grid: {
							top: 50, //---相对位置，top\bottom\left\right
							bottom: 20,
							left: 20,
							right: 20,
							containLabel: true //---grid 区域是否包含坐标轴的刻度标签
						},
						xAxis: {
							data: data.date //内容
						},
						yAxis: {
							axisLine: {
								show: true //---是否显示
							}
						},
						series: [{
								name: data.datasets[0].label,
								type: 'bar', //---类型
								legendHoverLink: true, //---是否启用图例 hover 时的联动高亮

								itemStyle: {
									//---图形形状
									color: '#5ea4ec',
									borderRadius: [10, 10, 0, 0]
								},
								barWidth: '10', //---柱形宽度
								barCategoryGap: '10',
								barGap: this.winWidth, //---柱形间距
								data: data.datasets[0].data
							},
							{
								name: data.datasets[1].label,
								type: 'bar',
								legendHoverLink: true,

								itemStyle: {
									color: '#22d422',
									borderRadius: [10, 10, 0, 0]
								},
								barWidth: '10',
								barCategoryGap: '10',
								barGap: this.winWidth,
								data: data.datasets[1].data
							},
							{
								name: data.datasets[2].label,
								type: 'bar',
								legendHoverLink: true,
								itemStyle: {
									color: '#E6A23C',
									borderRadius: [10, 10, 0, 0]
								},
								barWidth: '10',
								barCategoryGap: '10',
								barGap: this.winWidth,
								data: data.datasets[2].data
							}
						]
					};
					// this.myChart2 = echarts.init(document.getElementById('columnMain'));
					this.myChart2 = echarts.init(this.$refs.columnMain);
					this.myChart2.setOption(option);
				});
			},

			getTime(time) {
				this.time = time;
				this.tableData = [];
				this.query.pageIndex = 1;
				if (this.query.companyId) {
					this.gettableData();
				}
				if (time == '今天') {
					this.show = 1;
				} else if (time == '昨天') {
					this.show = 2;
				} else {
					this.show = 3;
				}
			},
			getIntentionTime() {
				let times = new Date().getTime() - 24 * 60 * 60 * 1000;
				this.startTime = this.toDateTimeStr(times);
				this.endTime = this.toDateTimeStr(times);
				this.timeArr.push(this.startTime);
				this.timeArr.push(this.endTime);
				this.getYiXiangDatas();
			},
			getIntentionTimes(times) {
				this.timeArr = [];
				let a = new Date(times[0]);
				let b = new Date(times[1]);

				let c = a.getFullYear() + '-' + this.getZero(a.getMonth() + 1) + '-' + this.getZero(a.getDate());
				this.timeArr.push(c);
				let d = b.getFullYear() + '-' + this.getZero(b.getMonth() + 1) + '-' + this.getZero(b.getDate());
				this.timeArr.push(d);
				this.startTime = c;
				this.endTime = d;
				this.getYiXiangDatas();
			},
			getZero(num) {
				// 单数前面加0
				if (parseInt(num) < 10) {
					num = '0' + num;
				}
				return num;
			},
			toDateTimeStr(nS) {
				let date = new Date(parseInt(nS));
				let YY = date.getFullYear() + '-';
				let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
				let DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
				let hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
				let mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
				let ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
				return YY + MM + DD;
			},

			dealTreedata(data) {
				let result = [];
				if (!Array.isArray(data)) {
					return result;
				}
				let map = {};
				data.forEach(item => {
					map[item.deptCode] = item;
				});
				data.forEach(item => {
					let parent = map[item.parentId];
					if (parent) {
						(parent.children || (parent.children = [])).push(item);
					} else {
						result.push(item);
					}
				});
				return result;
			},

			showNotice(notice) {
				this.dialogShow = true;
				this.noticeContent = notice.content;
				this.noticeUrl = notice.url;
				this.noticeTime = notice.createdAt;
				this.noticetitle = notice.title;
			},
			agentChange(agentId) {
				this.query.departmentId = '';
				this.query.userId = '';
				this.form.agentId = agentId;
				this.form.companyId = '';
				this.form.departmentId = '';
				this.form.userId = '';
				this.query.companyId = agentId;
				this.tableData = [];
				this.gettableData();
			},
			agentChanges(agentId) {
				console.log(agentId, '水水22222222水水水');
				this.query2.departmentId = '';
				this.query2.userId = '';
				this.query2.companyId = agentId;
				this.beOverdue = [];
				this.getWarNingPhones();
			},
			downLoadWarningPhones() {
				this.loading = true;
				let data = {
					agentId: this.query.companyId,
					time: this.time,
					currentUserId: localStorage.getItem('user')
				};
				downLoadWarningPhone(data).then(res => {
					this.loading = false;
					const blob = new Blob([res], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
					});
					let downloadElement = document.createElement('a');
					let href = window.URL.createObjectURL(blob);
					downloadElement.href = href;
					let date = new Date();
					let y = date.getFullYear(); //获取完整的年份(4位)
					let m = date.getMonth() + 1; //获取当前月份(0-11,0代表1月)
					let r = date.getDate(); //获取当前日(1-31)
					let fileName = this.time + '无使用记录坐席号码' + y + '-' + m + '-' + r + '.xlsx';
					downloadElement.download = fileName;
					document.body.appendChild(downloadElement);
					downloadElement.click();
					document.body.removeChild(downloadElement);
					window.URL.revokeObjectURL(href);
				});
			},
			downloadInvalidDatePhones() {
				this.loading2 = true;
				let data = {
					agentId: this.query2.companyId,
					currentUserId: localStorage.getItem('user')
				};
				downloadInvalidDatePhone(data).then(res => {
					this.loading2 = false;
					const blob = new Blob([res], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
					});
					let downloadElement = document.createElement('a');
					let href = window.URL.createObjectURL(blob);

					downloadElement.href = href;
					let date = new Date();
					let y = date.getFullYear(); //获取完整的年份(4位)
					let m = date.getMonth() + 1; //获取当前月份(0-11,0代表1月)
					let r = date.getDate(); //获取当前日(1-31)
					let fileName = '过期提醒' + y + '-' + m + '-' + r + '.xlsx';
					downloadElement.download = fileName;
					document.body.appendChild(downloadElement);
					downloadElement.click();
					document.body.removeChild(downloadElement);
					window.URL.revokeObjectURL(href);
				});
			},

			getYiXiangDatas() {
				let data = {
					beginDate: this.startTime,
					endDate: this.endTime,
					currentUserId: localStorage.getItem('user')
				};
				getYiXiangData(data).then(res => {
					console.log(res.data, 'res.data')
					let echarts = require('echarts');
					let oper_y = Object.values(res.data);
					let oper_x = ['意向强', '意向一般', '无意向', '需再跟进', '无需跟进'];
					let chartdata = [];
					oper_x.forEach((one, index) => {
						let item = {};
						item.name = one;
						item.value = oper_y[index];
						chartdata.push(item);
					});
					// this.myChart = echarts.init(document.getElementById('main'));
					this.myChart = echarts.init(this.$refs.main);
					let option = {
						legend: {
							orient: 'horizontal',
							x: 'center', //可设定图例在左、右、居中
							y: 'bottom',
							data: ['意向强', '意向一般', '无意向', '需再跟进', '无需跟进']
						},
						tooltip: {
							trigger: 'item',
							show: true,
							formatter: '{c}%'
						},
						series: [{
							type: 'pie',
							center: ['50%', '40%'],
							radius: ['35%', '55%'], //环形
							data: chartdata,
							minAngle: 10
						}]
					};
					this.myChart.setOption(option);
				});
			},
			handleAvatarSuccess(res, file) {
				this.headerUrl = URL.createObjectURL(file.raw);
			},

			getTime2(time) {
				this.time2 = time;
				if (time == '今天') {
					this.statisticsShow = 4;
				} else if (time == '前三天') {
					this.statisticsShow = 5;
				} else {
					this.statisticsShow = 6;
				}
				this.getHomePageInfoWithUsers();
			},
			rankTimeChange(e) {
				this.rankTime = e;
				this.getLeaderboards();
			},
			rankTypeChange(e) {
				this.rankType = e;
				this.getLeaderboards();
			},
			getHomePageInfoWithUsers() {
				let data = {
					time: this.time2,
					currentUserId: localStorage.getItem('user')
				};
				getHomePageInfoWithUser(data).then(res => {
					this.form = res.data;
				});
			},
			load() {
				let _this = this;
				let dom = document.querySelector('#noRecordBox .el-table__body-wrapper');
				dom.addEventListener('scroll', function() {
					let scrollD = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
					if (scrollD <= 0) {
						_this.query.pageIndex++;
						_this.gettableData();
					}
				});
			},

			load2() {
				let _this = this;
				setTimeout(function() {
					let dom = document.querySelector('#beOverdueData .el-table__body-wrapper');
					dom.addEventListener('scroll', function() {
						let scrollD = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
						if (scrollD <= 0) {
							_this.query2.pageIndex++;
							_this.getWarNingPhones();
						}
					});
				}, 500);
			},
			getWarNingPhones() {
				this.loading2 = true;
				let data = {
					agentId: this.query2.companyId,
					pageIndex: this.query2.pageIndex,
					pageSize: this.query2.pageSize,
					currentUserId: localStorage.getItem('user')
				};
				getWarNingPhone(data).then(res => {
					this.getCanvas();
					this.loading2 = false;
					if (this.beOverdue.length > 0) {
						let result = this.beOverdue.concat(res.data);
						this.beOverdue = result;
					} else {
						this.beOverdue = res.data;
					}
				});
			},
			gettableData() {
				let data = {
					agentId: this.query.companyId,
					pageIndex: this.query.pageIndex,
					time: this.time,
					pageSize: this.query.pageSize,
					currentUserId: localStorage.getItem('user')
				};
				this.loading = true;
				getWarNingCompany(data).then(res => {
					this.loading = false;
					if (this.tableData.length > 0) {
						const result = this.tableData.concat(res.data);
						this.tableData = result;
					} else {
						this.tableData = res.data;
					}
				});
			}
		}
	};
</script>

<style scoped>
	.el-row {
		margin-bottom: 15px;
		flex-wrap: nowrap;
		margin-top: 10px;
	}

	.clear {
		clear: both;
	}

	.statistics .tit-btn .el-button {
		background: #fff;
		border-color: #ccc;
		color: #000;
	}

	.box-card .fl_r .el-button {
		background: #fff;
		border-color: #ccc;
		color: #000;
	}

	.statistics .tit-btn .active {
		background: #4f7afd;
		border-color: #4f7afd;
		color: #fff;
	}

	.dashboard_box .fl_r .active {
		background: #4f7afd;
		border-color: #4f7afd;
		color: #fff;
	}

	.grid-content {
		display: flex;
		align-items: center;
		height: 100px;
	}

	/deep/ .el-col-16 {
		flex: 0 0 59.9%;
	}

	.grid-cont-right {
		flex: 1;
		text-align: center;
		font-size: 20px;
		font-weight: bold;
		color: #999;
	}

	.text {
		font-size: 12px;
		line-height: 45px;
		cursor: pointer;
	}

	/* .item {
	margin-bottom: 18px;
	border-bottom: dotted 1px #f1f1f1;
} */
	.clearfix {
		display: flex;
		flex-direction: row;
	}

	.clearfix .tit {
		display: flex;
		flex-direction: row;
		width: 400px;
	}

	.clearfix .el-date-editor {
		width: 230px;
	}

	.clearfix:before,
	.clearfix:after {
		display: table;
		content: '';
	}

	.clearfix:after {
		clear: both;
	}

	.box-card {
		float: left;
	}

	.card-l {
		width: 32%;
		margin-right: 1%;
	}

	.card-r {
		width: 34%;
		float: right;
	}

	.grid-num {
		font-size: 30px;
		font-weight: bold;
	}

	.grid-con-icon {
		font-size: 50px;
		width: 100px;
		height: 100px;
		text-align: center;
		line-height: 100px;
		color: #fff;
	}

	.grid-con-1 .grid-con-icon {
		background: linear-gradient(#ffb199, #ff0844);
	}

	.new {
		color: #b5b4b4;
		font-size: 12px;
	}

	.grid-con-1 .grid-num {
		color: #4f7afd;
	}

	.grid-con-2 .grid-con-icon {
		background: linear-gradient(#48c6ef, #6f86d6);
	}

	.grid-con-2 .grid-num {
		color: #4f7afd;
	}

	.grid-con-3 .grid-con-icon {
		background: linear-gradient(#e5b2ca, #7028e4);
	}

	.grid-con-3 .grid-num {
		color: #4f7afd;
	}

	.grid-con-4 .grid-con-icon {
		background: linear-gradient(#00c6fb, #005bea);
	}

	.grid-con-4 .grid-num {
		color: #4f7afd;
	}

	.grid-con-5 .grid-con-icon {
		background: linear-gradient(#89f7fe, #66a6ff);
	}

	.grid-con-5 .grid-num {
		color: #4f7afd;
	}

	.user-info {
		display: flex;
		align-items: center;
		padding-bottom: 20px;
		border-bottom: 2px solid #ccc;
		margin-bottom: 20px;
	}

	.footer {
		font-size: 12px;
		color: #aba9a9;
		padding: 5px 0 5px 5px;
		border-radius: 4px;
		border: 1px solid #dad8d8;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.user-info-cont {
		padding-left: 50px;
		flex: 1;
		font-size: 12px;
		color: #999;
	}

	.user-info-cont div:first-child {
		font-size: 30px;
		color: #222;
	}

	.user-info-list {
		font-size: 12px;
		color: #999;
		line-height: 25px;
	}

	.user-info-list span {
		margin-left: 70px;
	}

	.mgb20 {
		margin-bottom: 20px;
	}

	.todo-item {
		font-size: 12px;
	}

	.todo-item-del {
		text-decoration: line-through;
		color: #999;
	}

	.schart {
		width: 100%;
		height: 400px;
	}

	.schart1 {
		width: 100%;
		height: 275px;
	}

	.el-carousel__item h3 {
		color: #475669;
		font-size: 12px;
		opacity: 0.75;
		line-height: 200px;
		margin: 0;
	}

	.el-carousel__item:nth-child(2n) {
		background-color: #99a9bf;
	}

	.el-carousel__item:nth-child(2n + 1) {
		background-color: #d3dce6;
	}

	.statistics {
		background-color: #fff;
		border-radius: 4px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 16px 20px;
	}

	.statistics .title {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		border-bottom: 1px solid #f2f2f2;
		padding-bottom: 16px;
	}

	.statistics .ui-box ul {
		display: flex;
		flex-direction: row;
	}

	.statistics .ui-box ul li {
		list-style: none;
		width: 33%;
		height: 90px;
		border-right: 1px solid #f2f2f2;
		display: flex;
		flex-direction: column;
		padding: 20px 25px 25px 25px;
	}

	.statistics .ui-box ul li:last-child {
		/* border: none; */
	}

	.statistics .ui-box .label-text {
		font-size: 16px;
		font-weight: 600;
	}

	.statistics .ui-box .label-num {
		font-size: 32px;
		font-weight: 600;
		color: #6c6cf9;
		margin: 8px 0;
		font-weight: mFont;
	}

	.statistics .ui-box .huanbi {
		font-size: 12px;
		font-weight: 600;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		margin-right: 10px;
	}

	.after-sale {
		width: 100%;
		height: 258px;
	}

	.after-sale ul,
	li {
		list-style: none;
	}

	.after-sale li {
		display: flex;
		flex-direction: row;
		justify-content: center;
		font-size: 12px;
	}

	.after-sale .list1 {
		background-color: #f2f2f2;
	}

	.after-sale li div {
		width: 100%;
		text-align: center;
		padding: 10px 0;
	}

	.after-sale li div:first-child {
		border-right: 1px solid #e4e4e4;
	}

	.after-sale li div img {
		width: 100px;
		height: 100px;
	}

	.el-collapse {
		border: 0;
	}

	::v-deep .el-collapse-item__header {
		background: transparent;
	}

	::v-deep .el-collapse-item__header.is-active {
		background: transparent;
	}

	::v-deep .el-table td div {
		background-color: transparent;
	}

	.dashboard_box *::-webkit-scrollbar {
		width: 7px;
		height: 10px;
		background-color: transparent;
	}

	/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
	.dashboard_box *::-webkit-scrollbar-track {
		background-color: #f0f6ff;
	}

	/*定义滚动条轨道 内阴影+圆角*/
	.dashboard_box *::-webkit-scrollbar-thumb {
		background-color: #99b1f9;
		border-radius: 6px;
		opacity: 0.6;
	}

	/*定义滑块 内阴影+圆角*/
	.dashboard_box .scrollbarHide::-webkit-scrollbar {
		display: none;
	}

	.dashboard_box .scrollbarShow::-webkit-scrollbar {
		display: block;
	}

	.notice-box {
		width: 100%;
		margin: 0 auto;
	}

	.notice-box p {
		text-align: left;
		line-height: 40px !important;
	}

	.notice-box img {
		margin: 20px;
	}
</style>
<style type="text/css">
	.dashboard-user-box {
		background-color: #fff;
		height: 110px;
		border-radius: 4px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.dashboard-user-box .left {
		display: flex;
		flex-direction: row;
		font-size: 16px;
		color: #9a9a9a;
	}

	.dashboard-user-box .left .avatar-uploader .el-upload {
		width: 66px !important;
		height: 66px !important;
		border-radius: 50% !important;
		border: 1px solid #f5f5f5 !important;
		cursor: pointer !important;
		position: relative !important;
		overflow: hidden !important;
		margin: 0 30px !important;
	}

	.dashboard-user-box .left .avatar-uploader .el-upload:hover {
		border-color: red;
	}

	.dashboard-user-box .avatar {
		width: 66px;
		height: 66px;
		display: block;
	}

	.dashboard-user-box .sale-info {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
	}

	.dashboard-user-box .sale-info .list {
		margin-right: 30px;
	}

	.dashboard-user-box .right {
		margin-right: 30px;
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 16px;
		color: #9a9a9a;
	}
</style>