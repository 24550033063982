import request from '../utils/request';
import qs from "qs";

export const fetchAgent = currentUserId => {
	return request.get('/api/agent/fetchAgent?currentUserId=' + currentUserId)
};

export const findAfterSale = data => {
	return request.get('/api/afterSale/findAfterSale?currentUserId=' + data.currentUserId)
};
export const getAfterSaleById = id => {
	return request.get('/api/afterSale/getAfterSaleById?id=' + id)
};
export const findAfterSaleByRole = data => {
	return request.get('/api/afterSale/findAfterSaleByRole?currentUserId=' + data.currentUserId)
};
export const updateAfterSale = param => {
	return request.post('/api/afterSale/updateAfterSale', qs.stringify(param))
};
export const addAfterSale = param => {
	return request.post('/api/afterSale/addAfterSale', qs.stringify(param))
};
export const deleteAfterSale = param => {
	return request.post('/api/afterSale/deleteAfterSale', qs.stringify(param))
};

export const list = param => {
	return request.post('/api/agent/list', qs.stringify(param))
};

export const addAgent = param => {
	return request.post('/api/agent/add', qs.stringify(param))
};


//获取代理账号列表
export const agentAccount = id => {
	return request.get('/api/agent/account?id=' + id)
};
//修改代理账号状态
export const stopAccount = param => {
	return request.post('/api/agent/stopAccount', qs.stringify(param))
};
//编辑 代理账号
export const updateAccount = param => {
	return request.post('/api/agent/updateAccount', qs.stringify(param))
};
//添加 代理账号
export const addAccount = param => {
	return request.post('/api/agent/addAccount', qs.stringify(param))
};
//删除 代理账号
export const deleteAccount = param => {
	return request.post('/api/agent/deleteAccount', qs.stringify(param))
};

export const updateAgent = param => {
	return request.post('/api/agent/update', qs.stringify(param))
};


export const delAgent = id => {
	return request.get('/api/agent/delete?id=' + id)
};

export const fetchAgentById = agentId => {
	return request.get('/api/agent/fetchAgentById?agentId=' + agentId)
};

// eslint-disable-next-line no-unused-vars
export const fetchInitAgent = param => {
	return request.post('/api/agent/fetchInitAgent', qs.stringify(param))
};

//添加黑名单库
export const addCompany = param => {
	return request.post('/api/blackList/addCompany', qs.stringify(param))
};
//查询黑名单库列表
export const getCompany = param => {
	return request.get('/api/blackList/getCompany?companyName=' + param.companyName + '&&userId=' + param.userId +
		'&&pageIndex=' + param.pageIndex + '&&pageSize=' + param.pageSize, )
};
//删除和批量删除
export const deleteCompany = param => {
	return request.post('/api/blackList/deleteCompany', qs.stringify(param))
};

//获取代理使用记录
export const useRecord = param => {
	return request.post('/api/agent/useRecord', qs.stringify(param))
};

//获取代理购买记录
export const buyRecord = param => {
	return request.post('/api/agent/buyRecord', qs.stringify(param))
};

//购买月数
export const agentbuyMonths = param => {
	return request.post('/api/agent/buyMonths', qs.stringify(param))
};

//admin添加月数
export const addValidCallTime = param => {
	return request.post('/api/agent/addValidCallTime', qs.stringify(param))
};

//自动回收修改
export const AutomaticRecovery = param => {
	return request.post('/api/intentionCustomer/AutomaticRecovery', qs.stringify(param))
};
//回收列表
export const RecoveryRecord = param => {
	return request.post('/api/intentionCustomer/RecoveryRecord', qs.stringify(param))
};
//查询自动回收
export const getAutomaticRecovery = companyId => {
	return request.get('/api/intentionCustomer/getAutomaticRecovery?companyId=' + companyId)
};

//获取表头
export const getColumn = param => {
	return request.get('/api/intentionCustomer/getColumn?companyId=' + param.companyId + '&&userId=' + param.userId)
};
//下载客户磨板
export const downloadIntentionTemplate = param => {
	return request.post('/api/intentionCustomer/downloadIntentionTemplate', qs.stringify(param), {
		responseType: 'blob'
	})
};